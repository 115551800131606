/*###############################################

@Title: Navigation
@Description:
This file contains the navigation styles

###############################################*/

$_opc-navigation-padding-level-1: 20px 0;
$_opc-navigation-padding-level-2: 0.5rem 1rem !default;
$_opc-navigation-padding-level-3: 0.5rem 1rem !default;
$_opc-navigation-level-2-top: 110% !default;
$_opc-navigation-bg: #000 !default;
$_opc-navigation-bg-hover: rgba(#000, 0.4) !default;
$_opc-navigation-bg-active: $brand-color-1 !default;
$_opc-navigation-color: #fff !default;
$_opc-navigation-color-hover: #fff !default;
$_opc-navigation-color-active: #fff !default;
$_opc-navigation-submenu-bg : #333 !default;
$_opc-navigation-submenu-bg-hover: rgba(#000, 0.2) !default;
$_opc-navigation-submenu-bg-active: rgba(#000, 0.2) !default;
$_opc-navigation-megamenu-bg : #333 !default;
$_opc-navigation-megamenu-headline-color: rgba(#fff, 1) !default;


.opc-navigation {
    @include clearfix();

    /* ---------------------------------------------------- */
    // global nav reset styles
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        @include clearfix();

        li {
            a {
                display: block;
                text-decoration: none;
                color: #000;

                &:hover {
                    color: lighten(#000, 40%);
                }
            }
        }
    }

    /* ---------------------------------------------------- */
    // global nav level 1
    .navigation-level-1 {
        >li {
            >a {}

            &:hover {

                // style a tag of hovered element
                >a {}

                // show nav level 2 on hover if available
                >.navigation-level-2 {}
            }
        }
    }

    /* ---------------------------------------------------- */
    // global nav level 2
    .navigation-level-2 {
        >li {
            >a {
                white-space: nowrap;
            }

            &:hover {
                >a {}
            }
        }
    }

    .navigation-level-3 {}

    /*
 
     horizontal menu (default)
     ---------------------------------------------------- */
    &[data-op-navtype="default-click"],
    &[data-op-navtype="default-hover"] {

        // nav level 1
        .navigation-level-1 {
            >li {
                float: left;
                position: relative;

                >a {
                    padding: $_opc-navigation-padding-level-1;

                    &:hover {
                        color: #fff;
                        background-color: $_opc-navigation-submenu-bg-hover;
                    }
                }

                &.is-active,
                &.is-active-parent {
                    >a {
                        color: $_opc-navigation-color-active;
                        background: $_opc-navigation-bg-active;

                        &:hover {
                            color: $_opc-navigation-color-active;
                            background: darken($_opc-navigation-bg-active, 10%);
                        }
                    }
                }

                &.is-open {

                    // style a tag of hovered element
                    >a {
                        background: $_opc-navigation-bg-hover;
                    }

                    // show nav level 2 on hover if available
                    >.navigation-level-2 {
                        display: block;
                        visibility: visible;
                        opacity: 1;
                        transform: translate(0, 0.5rem) scale(1);
                        transition-delay: 0.1s;
                    }
                }
            }
        }

        // nav level 2
        .navigation-level-2 {
            position: absolute;
            left: 0;
            top: 100%;
            background: $_opc-navigation-submenu-bg;
            visibility: hidden;
            opacity: 0;
            transform: translate(0, 0) scale(0.95);
            transition: all 0.2s ease-in-out;

            &::before {
                content: '';
                position: absolute;
                top: -0.5rem;
                height: 0.5rem;
                width: 100%;
                background: transparent;
            }

            >li {
                >a {
                    min-width: 16rem;
                    padding: 1rem 2rem;
                    white-space: nowrap;
                }

                &:hover {
                    >a {
                        background: rgba(#000, 0.1);
                        color: #fff;
                    }
                }

                &.is-active,
                &.is-active-parent {
                    >a {
                        color: $brand-color-1;
                    }
                }
            }
        }

        .navigation-level-3 {
            display: none;
        }
    }

    &[data-op-navtype="default-hover"] {

        // nav level 1
        .navigation-level-1 {
            >li {
                &:hover {

                    // style a tag of hovered element
                    >a {
                        background: $_opc-navigation-bg-hover;

                    }

                    // show nav level 2 on hover if available
                    >.navigation-level-2 {
                        display: block;
                        visibility: visible;
                        opacity: 1;
                        transform: translate(0, 0.5rem) scale(1);
                        transition-delay: 0.1s;
                    }
                }
            }
        }
    }

    /*
 
     Mega Menü
     ---------------------------------------------------- */
    &[data-op-navtype="megamenu-click"],
    &[data-op-navtype="megamenu-hover"] {

        // nav level 1
        .navigation-level-1 {
            >li {
                float: left;

                >a {
                    padding: $_opc-navigation-padding-level-1;

                    &:hover {
                        color: #fff;
                        background-color: $_opc-navigation-submenu-bg-hover;
                    }
                }

                &.is-active,
                &.is-active-parent {
                    >a {
                        color: $_opc-navigation-color-active;
                        background: $_opc-navigation-bg-active !important;

                        &:hover {
                            color: $_opc-navigation-color-active;
                            background: darken($_opc-navigation-bg-active, 10%) !important;
                        }
                    }
                }

                &.is-open {

                    // style a tag of hovered element
                    >a {
                        background: $_opc-navigation-bg-hover;
                    }

                    // show nav level 2 on hover if available
                    >.navigation-level-2 {
                        //display: flex;
                        display: block;
                        visibility: visible;
                        opacity: 1;
                        transform: translate(0, 0.5rem) scale(1);
                        transition-delay: 0.1s;
                    }
                }
            }
        }

        // nav level 2
        .navigation-level-2 {
            position: absolute;
            left: 0;
            right: 0;
            z-index: 1000;
            top: 100%;
            background: #333;
            background: $_opc-navigation-megamenu-bg;
            visibility: hidden;
            opacity: 0;
            transform: translate(0, 0) scale(0.95);
            transition: all 0.2s ease-in-out;
            column-count: 4;
            padding-top: 2rem;
            padding-bottom: 2rem;

            >li {
                display: inline-block;
                page-break-inside: avoid;
                break-inside: avoid-column;
                margin-bottom: 2rem;

                >a {
                    min-width: 16rem;
                    padding: 1rem 2rem;
                    white-space: nowrap;
                    color: $_opc-navigation-megamenu-headline-color;
                    font-weight: bold;
                }

                &:hover {
                    >a {
                        background: rgba(#000, 0.1);
                        background: transparent;
                    }
                }
            }
        }

        .navigation-level-3 {
            display: block;

            >li {
                >a {
                    padding: 0.5rem 2rem;
                    opacity: 0.8;
                    color: $_opc-navigation-megamenu-headline-color;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &[data-op-navtype="megamenu-hover"] {

        // nav level 1
        .navigation-level-1 {
            >li {
                &:hover {

                    // style a tag of hovered element
                    >a {
                        background: $_opc-navigation-bg-hover;
                    }

                    // show nav level 2 on hover if available
                    >.navigation-level-2 {
                        //display: flex;
                        display: block;
                        visibility: visible;
                        opacity: 1;
                        transform: translate(0, 0.5rem) scale(1);
                        transition-delay: 0.1s;
                    }
                }
            }
        }
    }

    /*
 
     aside menu (default)
     ---------------------------------------------------- */
    &[data-op-navtype="aside-click"] {
        .navigation-level-1 {
            >li {
                margin-bottom: 0.5rem;

                >a {
                    padding: 1rem;
                }

                &.is-open {
                    >a {
                        font-weight: bold;
                        color: $brand-color-1;
                    }

                    >.navigation-level-2 {
                        display: block;
                    }
                }
            }
        }

        .navigation-level-2 {
            display: none;

            >li {
                border-bottom: 1px solid rgba(#fff, 0.2);

                >a {
                    padding: 0.5rem 1rem 0.5rem 2rem;
                }

                &.is-open {
                    >a {
                        font-weight: bold;
                        color: $brand-color-1;
                    }

                    >.navigation-level-3 {
                        display: block;
                    }
                }
            }
        }

        .navigation-level-3 {
            display: none;

            >li {
                >a {
                    padding: 0.5rem 1rem;
                }

                &.is-open {
                    >a {
                        font-weight: bold;
                        color: $brand-color-1;
                    }

                    >.navigation-level-4 {
                        display: block;
                    }
                }
            }
        }

        .navigation-level-4 {
            display: none;

            >li {
                >a {
                    padding: 0.5rem 1rem;
                }

                &.is-open {
                    >a {
                        font-weight: bold;
                        color: $brand-color-1;
                    }

                    >.navigation-level-5 {
                        display: block;
                    }
                }
            }
        }
    }

    /*
 
     Simple Menü
     ---------------------------------------------------- */
    &[data-op-navtype="simple"] {
        a {
            display: inline-block;

            &::before {
                display: none;
            }

            &:hover {
                &::before {
                    opacity: 1;
                }
            }
        }

        // nav level 1
        .navigation-level-1 {
            >li {
                >a {
                    padding: .5rem;
                    // margin-left: -.5rem;
                }

                &:hover {

                    // style a tag of hovered element
                    >a {}

                    // show nav level 2 on hover if available
                    >.navigation-level-2 {}
                }
            }
        }

        // nav level 2
        .navigation-level-2 {
            >li {
                >a {
                    white-space: wrap;
                    padding: .5rem;
                    margin-left: -.5rem;
                }

                &:hover {
                    >a {
                        background: rgba(#000, 0.1);
                    }
                }
            }
        }

        .navigation-level-3 {}
    }

    /*
 
     new default menu (default)
     ---------------------------------------------------- */
    &[data-op-navtype="default"] {


        &[data-op-layout="arrow"] {

            .navigation-toggler {
                width: 2.6rem;
                height: 2.6rem;

                position: absolute;
                right: 0.5rem;
                top: 50%;
                margin-top: -1.3rem;
                display: none;
                background-image: inline-svg($op-icon-angle-down-svg, '#fff');
                background-position: center;
                background-size: 14px;
                background-repeat: no-repeat;
                cursor: pointer;

                &:hover {
                    background-color: rgba(#000, 0.2);
                }

                &::before {
                    content: '';
                    display: block;
                    width: 1px;
                    background: rgba(#fff, 0.4);
                    position: absolute;
                    left: -0.4rem;
                    top: 50%;
                    height: 1.6rem;
                    margin-top: -0.8rem;

                }


            }

            .navigation-label {
                display: none;
            }

            .navigation-parent-label {
                display: none;
            }


            // nav level 1
            .navigation-level-1 {
                display: flex;
                justify-content: flex-start;

                >li {

                    position: relative;

                    >a {
                        padding: $_opc-navigation-padding-level-1;
                        color: $_opc-navigation-color;
                        white-space: nowrap;

                        &:hover {
                            color: #fff;
                            background-color: $_opc-navigation-submenu-bg-hover;
                        }
                    }

                    &.has-children {
                        >a {
                            padding-right: 4rem;
                        }

                        .navigation-toggler {
                            display: block;

                            &::before {
                                display: none;
                            }
                        }
                    }

                    &.is-active,
                    &.is-active-parent {

                        >a,
                        >.navigation-label {
                            color: $_opc-navigation-color-active;
                            background: $_opc-navigation-bg-active;

                            &:hover {
                                color: $_opc-navigation-color-active;
                                background: darken($_opc-navigation-bg-active, 10%);
                            }
                        }
                    }

                    &.is-open {

                        // style a tag of hovered element
                        >a {
                            background: $_opc-navigation-bg-hover;
                        }

                        // show nav level 2 on hover if available
                        >.navigation-level-2 {
                            display: block;
                            visibility: visible;
                            opacity: 1;
                            transform: translate(0, 0.5rem) scale(1);
                            transition-delay: 0.1s;
                        }
                    }
                }
            }

            // nav level 2
            .navigation-level-2 {
                position: absolute;
                left: 0;
                top: 100%;
                background: $_opc-navigation-submenu-bg;
                visibility: hidden;
                opacity: 0;
                transform: translate(0, 0) scale(0.95);
                transition: all 0.2s ease-in-out;

                &::before {
                    content: '';
                    position: absolute;
                    top: -0.5rem;
                    height: 0.5rem;
                    width: 100%;
                    background: transparent;
                }

                .navigation-toggler {
                    background-image: inline-svg($op-icon-angle-right-svg, '#fff');
                }

                li {
                    >a {
                        min-width: 16rem;
                        padding: 1rem 2rem;
                        white-space: nowrap;
                    }

                    &:hover {
                        >a {
                            background: rgba(#000, 0.1);
                            color: #fff;
                        }
                    }

                    &.is-active,
                    &.is-active-parent {
                        >a {
                            color: $brand-color-1;
                        }
                    }

                    &.has-children {
                        position: relative;

                        >a {
                            padding-right: 5rem;
                        }
                    }

                    &.is-open {
                        // style a tag of hovered element
                        position: relative;

                        >a {
                            background: $_opc-navigation-bg-hover;
                        }

                        // show other nav levels on the right side of the parent, when available
                        >.navigation-level-3,
                        >.navigation-level-4,
                        >.navigation-level-5 {
                            display: block;
                            visibility: visible;
                            opacity: 1;
                            position: absolute;
                            left: 100%;
                            top: 0;
                        }
                    }
                }
            }

            .navigation-level-3,
            .navigation-level-4,
            .navigation-level-5 {
                display: none;
                background: $_opc-navigation-submenu-bg;

            }

        }

        &[data-op-layout="click"] {

            .navigation-toggler {
                display: none;
            }

            .navigation-label {
                display: none;
            }

            .has-children {
                >a {
                    display: none;
                }

                >.navigation-label {
                    display: block;
                    cursor: pointer;
                }
            }

            .navigation-parent-label {
                display: block;
                font-size: 0.6em;
                text-transform: uppercase;
                opacity: 0.6;
            }


            // nav level 1
            .navigation-level-1 {
                display: flex;
                justify-content: flex-start;

                >li {

                    position: relative;

                    >a,
                    >.navigation-label {
                        padding: $_opc-navigation-padding-level-1;
                        color: $_opc-navigation-color;
                        white-space: nowrap;

                        &:hover {
                            color: #fff;
                            background-color: $_opc-navigation-submenu-bg-hover;
                        }
                    }

                    &.has-children {
                        >a {
                            padding-right: 4rem;
                        }
                    }

                    &.is-active,
                    &.is-active-parent {

                        >a,
                        >.navigation-label {
                            color: $_opc-navigation-color-active;
                            background: $_opc-navigation-bg-active;

                            &:hover {
                                color: $_opc-navigation-color-active;
                                background: darken($_opc-navigation-bg-active, 10%);
                            }
                        }
                    }

                    &.is-open {

                        // style a tag of hovered element
                        >a,
                        >.navigation-label {
                            background: $_opc-navigation-bg-hover;
                        }

                        // show nav level 2 on hover if available
                        >.navigation-level-2 {
                            display: block;
                            visibility: visible;
                            opacity: 1;
                            transform: translate(0, 0.5rem) scale(1);
                            transition-delay: 0.1s;
                        }
                    }
                }
            }

            // nav level 2
            .navigation-level-2 {
                position: absolute;
                left: 0;
                top: 100%;
                background: $_opc-navigation-submenu-bg;
                visibility: hidden;
                opacity: 0;
                transform: translate(0, 0) scale(0.95);
                transition: all 0.2s ease-in-out;

                &::before {
                    content: '';
                    position: absolute;
                    top: -0.5rem;
                    height: 0.5rem;
                    width: 100%;
                    background: transparent;
                }

                li {

                    >a,
                    >.navigation-label {
                        min-width: 16rem;
                        padding: 1rem 2rem;
                        white-space: nowrap;
                        color: $_opc-navigation-color;
                    }

                    &:hover {

                        >a,
                        >.navigation-label {
                            background: rgba(#000, 0.1);
                            color: #fff;
                        }
                    }

                    &.is-active,
                    &.is-active-parent {

                        >a,
                        >.navigation-label {
                            color: $brand-color-1;
                        }
                    }

                    &.has-children {
                        position: relative;
                    }

                    &.is-open {
                        // style a tag of hovered element
                        position: relative;

                        >a,
                        >.navigation-label {
                            background: $_opc-navigation-bg-hover;
                        }

                        // show other nav levels on the right side of the parent, when available
                        >.navigation-level-3,
                        >.navigation-level-4,
                        >.navigation-level-5 {
                            display: block;
                            visibility: visible;
                            opacity: 1;
                            position: absolute;
                            left: 100%;
                            top: 0;
                        }
                    }
                }
            }

            .navigation-level-3,
            .navigation-level-4,
            .navigation-level-5 {
                display: none;
                background: $_opc-navigation-submenu-bg;

            }

        }

    }

}

.mainnav-wrapper {
    .mainnav-inner {
        position: relative;

        .opc-navigation[data-op-navtype="simple"] {
            .navigation-label {
                display: none;
            }

            .navigation-parent-label {
                display: none;
            }

            .navigation-level-1 {
                display: flex;
                justify-content: flex-start;

                >li {
                    position: relative;

                    >a {
                        padding: $_opc-navigation-padding-level-1;
                        color: $_opc-navigation-color;
                        white-space: nowrap;
                        font-size: 1.6rem;
                        border-top: 1px solid transparent;
                        border-bottom: 1px solid transparent;
                        text-decoration: none;
                        &:hover{
                            border-bottom-color: $brand-color-1;
                        }
                    }

                    &:first-child {
                        >a {
                            padding-left: 0;
                        }
                    }

                    &.has-children {
                        >a {
                            // padding-right: 4rem;
                        }
                    }

                    &.is-active,
                    &.is-active-parent {
                        >a,
                        >.navigation-label {
                            // border-bottom-color: $brand-color-1;
                        }
                    }
                    &.is-open {
                        >a {
                            border-bottom-color: $white;
                        }
                        >.navigation-level-2 {
                            display: block;
                            visibility: visible;
                            opacity: 1;
                            transform: translate(0, 0.5rem) scale(1);
                            transition-delay: 0.1s;
                        }
                    }
                }
            }
        }

        .opc-navigation[data-op-navtype="simple"] {
            .navigation-level-1 {
                >li {

                    &.has-children {
                        position: relative;

                        >a {
                            display: block;
                            max-width: 125px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            @include mq($min-width: map-get($breakpoints, lplus)) {
                                max-width: none;
                            }
                        }
                    }

                    &.is-open {
                        .navigation-level-2 {
                            display: none;
                        }
                    }

                    >.navigation-level-2 {
                        li {
                            display: none;

                            &.is-open {
                                .navigation-level-3 {
                                    display: block;
                                }
                            }

                            >.navigation-level-3 {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    &.mainnav-wrapper-white {
        .mainnav-inner {
            .opc-navigation[data-op-navtype="simple"] {
                .navigation-level-1 {
                    >li {
                        >a {
                            color: $brand-color-2;
                        }
                        &.is-open {
                            >a,
                            >.navigation-label {
                                border-bottom-color: $brand-color-2;
                                &:hover {
                                    border-bottom-color: $brand-color-2;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.mainnav-wrapper-nav{
        .mainnav-inner {
            .opc-navigation[data-op-navtype="simple"] {
                width: 100%;
                &::after{
                    display: none;
                }
                .navigation-level-1 {
                    width: 100%;
                    justify-content: space-between;
                    &::after{
                        display: none;
                    }
                    >li {
                        >a {
                        }
                    }
                }
            }
        }
    }
}

.subnav-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    opacity: 0;
    background-color: #4B5F77;
    // border-radius: .4rem;
    // box-shadow: 0 0 1rem rgba(0,0,0,.25);
    transition: opacity .3s ease-in;
    pointer-events: none;
    max-height: calc(100vh - 8rem);
    overflow: visible;

    &.service-menu{
        background-color: #C3D5DF;
        top: calc(100% - 65px);
        .subnav-wrapper-main {
            .subnav-col-mega {
                .navigation-level-2 {
                    flex-wrap: nowrap;
                    >li {
                        flex-basis: auto;
                        max-width: none;
                        flex-grow: 1;
                        z-index: 1;
                        margin-bottom: 0;
                        line-height: 16px;
                        .navigation-level-3 {
                            >li {
                                margin-left: -4px;
                                margin-right: -4px;
                                >a {
                                    color: $brand-color-2;
                                    line-height: 1.4;
                                    padding-left: 4px;
                                    padding-right: 4px;
                                    &:hover {
                                        color: $white;
                                        background-color: $brand-color-2;
                                    }
                                }
                                &.is-active,
                                &.is-active-parent {
                                    >a {
                                        color: $brand-color-2;
                                        &:hover {
                                            color: $white;
                                            background-color: $brand-color-2;
                                        }
                                    }
                                }
                            }
                        }
                        >a {
                            color: $brand-color-2;
                            line-height: 24px;
                            &:hover {
                                color: $white;
                                background-color: $brand-color-2;
                            }
                        }
                        &.is-active,
                        &.is-active-parent {
                            >a {
                                color: $brand-color-2;
                                &:hover {
                                    color: $white;
                                    background-color: $brand-color-2;
                                }
                            }
                        }
                    }
                }
            }
        }
        &::before {
            background-color: #C3D5DF;
        }
    }

    &::before {
        content: " ";
        background-color: #4B5F77;
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        right: -10000px;
        width: 20000px;
        z-index: -1;
    }

    &.is-open {
        pointer-events: all;
        opacity: 1;
    }

    .subnav-wrapper-header {
        position: relative;
        button {
            display: none;
            background-color: transparent;
            // float: right;
            position: absolute;
            right: 0;
            top: 0;
            width: 2.2rem;
            height: 2.2rem;
            padding: 0;
            top: 3rem;

            &::after {
                content: "";
                background-image: inline-svg($op-icon-cross-svg, $white);
                background-repeat: no-repeat;
                background-position: center center;
                display: block;
                width: 2.2rem;
                height: 2.2rem;
                position: absolute;
                left: 50%;
                transform: translate(-50%, -50%);
                top: 50%;
            }
        }
    }
    
    &[data-mh-columns="1-3"]{
        .subnav-col-mega{
            .navigation-level-2 {
                &:nth-child(1){
                    flex-basis: 25%;
                    >li{
                        flex-basis: 100%;
                        max-width: 100%;
                    }
                }
                &:nth-child(2){
                    flex-basis: 75%;
                    >li{
                        flex-basis: calc(100% / 3);
                        max-width: calc(100% / 3);
                        overflow: hidden;
                    }
                }
            }
        }
    }
    &[data-mh-columns="2-2"]{
        .subnav-col-mega{
            .navigation-level-2 {
                &:nth-child(1){
                    flex-basis: 50%;
                    >li{
                        flex-basis: 50%;
                        max-width: 50%;
                    }
                }
                &:nth-child(2){
                    flex-basis: 50%;
                    >li{
                        flex-basis: 50%;
                        max-width: 50%;
                    }
                }
            }
        }
    }

    .subnav-wrapper-main {
        display: flex;
        flex-wrap: wrap;
        padding: 16px 0;

        a.show-all {
            display: none;
        }

        .subnav-col-mega {
            flex-basis: 100%;
            max-width: 100%;
            display: flex;
            align-items: flex-start;

            @include mq($min-width: map-get($breakpoints, xl)) {}

            h2 {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                padding-right: 3rem;
                align-items: center;

                margin-bottom: 0;
                padding-bottom: 1rem;

                a {
                    font-size: 2.4rem;
                    color: $white;
                }

                a.show-all {
                    text-decoration: underline;
                    font-size: 1.4rem;
                    font-weight: 400;
                }
            }

            .navigation-level-2 {
                margin: 0;
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                flex-grow: 1;
                height: 100%;
                align-content: flex-start;

                &.bright {
                    &:empty {
                        display: none;
                    }

                    background-color: #617387;
                    position: relative;

                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        top: -15px;
                        left: -10px;
                        bottom: -15px;
                        width: 9999px;
                        z-index: 1;
                        background-color: #617387;
                    }
                }

                >li {
                    list-style-type: none;
                    margin: 0;
                    padding: 0.4rem 0;
                    flex-basis: calc(100% / 4);
                    max-width: calc(100% / 4);
                    flex-grow: 0;
                    z-index: 1;
                    margin-bottom: 30px;

                    .navigation-level-3 {
                        // display: none;
                        margin: 0;
                        padding: 0 30px 0 0;
                        
                        >li {
                            list-style-type: none;
                            margin: 0;
                            padding: 0.4rem 0;
                            margin-left: -4px;
                            margin-right: -4px;

                            >a {
                                display: inline-block;
                                // padding: 0 .6rem;
                                // margin-left: -.6rem;
                                padding-left: 4px;
                                padding-right: 4px;
                                white-space: nowrap;
                                color: $white;
                                font-size: 1.6rem;
                                font-weight: 400;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                max-width: 100%;
                                white-space: nowrap;

                                &:hover {
                                    color: $brand-color-2;
                                    background-color: $white;
                                }
                            }

                            &.is-active,
                            &.is-active-parent {
                                >a {
                                    color: $white;
                                    font-weight: 700;

                                    &:hover {
                                        color: $brand-color-2;
                                        background-color: $white;
                                    }
                                }
                            }
                        }
                    }

                    &.has-children {
                        position: relative;
                    }

                    &.is-open {
                        a {
                            &::after {
                                content: "";
                                right: .5rem;
                            }
                        }
                    }

                    >a {
                        // display: block;
                        display: inline-block;
                        // min-width: 16rem;
                        padding: 0 .6rem;
                        margin-left: -.6rem;
                        white-space: nowrap;
                        color: $white;
                        font-size: 1.6rem;
                        font-weight: 700;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 100%;
                        white-space: nowrap;

                        &:hover {
                            color: $brand-color-2;
                            background-color: $white;
                        }
                    }

                    &.is-active,
                    &.is-active-parent {
                        >a {
                            color: $white;
                            font-weight: 700;

                            &:hover {
                                color: $brand-color-2;
                                background-color: $white;
                            }
                        }
                    }
                }
            }

        }

        .subnav-col1 {
            flex-basis: calc(100% / 3 - 3rem);
            max-width: 28%;

            @include mq($min-width: map-get($breakpoints, xl)) {}

            h2 {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                padding-right: 3rem;
                align-items: center;

                margin-bottom: 0;
                padding-bottom: 1rem;

                a {
                    font-size: 2.4rem;
                    color: $white;
                }

                a.show-all {
                    text-decoration: underline;
                    font-size: 1.4rem;
                    font-weight: 400;
                }
            }

            .navigation-level-2 {
                margin: 0;
                padding: 0;


                li {
                    list-style-type: none;
                    margin: 0;
                    padding: 0.4rem 0;

                    .navigation-level-3 {
                        display: none;
                    }

                    &.has-children {
                        position: relative;

                        a {
                            // &::after{
                            //     content: "";
                            //     // background-image: inline-svg($op-icon-angle-right-svg, $white);
                            //     // background-size: 2.4rem;
                            //     // background-repeat: no-repeat;
                            //     display: block;
                            //     width: 3rem;
                            //     height: 3rem;
                            //     position: absolute;
                            //     right: 1.5rem;
                            //     top: .7rem;
                            //     transition: all .1s ease-in-out;
                            // }
                        }
                    }

                    &.is-open {
                        a {
                            &::after {
                                content: "";
                                right: .5rem;
                            }
                        }
                    }

                    >a {
                        // display: block;
                        display: inline-block;
                        // min-width: 16rem;
                        padding: 0 .6rem;
                        margin-left: -.6rem;
                        white-space: nowrap;
                        color: $white;
                        font-size: 1.6rem;
                        font-weight: 400;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 100%;
                        white-space: nowrap;

                        &:hover {
                            color: $brand-color-2;
                            background-color: $white;
                        }
                    }

                    &.is-active,
                    &.is-active-parent {
                        >a {
                            color: $white;
                            font-weight: 700;

                            &:hover {
                                color: $brand-color-2;
                                background-color: $white;
                            }
                        }
                    }
                }
            }
        }

        .subnav-col2 {
            // transition: opacity .2s ease-in;
            // opacity: 0;
            // &.ease{
            //     opacity: 1;
            // }
            padding-left: 3rem;
            flex-basis: calc(100% / 3);

            @include mq($min-width: map-get($breakpoints, xl)) {}

            &.cel-open {
                h2 {
                    display: flex;
                }
            }

            h2 {
                display: none;
                justify-content: space-between;
                flex-wrap: wrap;
                padding-right: 3rem;
                align-items: center;

                margin-bottom: 0;
                padding-bottom: 1rem;

                a {
                    font-size: 2.4rem;
                    color: $white;
                }

                a.show-all {
                    text-decoration: underline;
                    font-size: 1.4rem;
                    font-weight: 400;
                }
            }

            .navigation-level-3 {
                margin: 0;
                padding: 0;


                li {
                    list-style-type: none;
                    margin: 0;
                    padding: 0.4rem 0;

                    .navigation-level-4 {
                        display: none;
                    }

                    &.has-children {
                        position: relative;

                        a {
                            // &::after{
                            //     content: "";
                            //     background-image: inline-svg($op-icon-angle-right-svg, $white);
                            //     // background-image: inline-svg($op-icon-arrow-right-svg, $white);
                            //     background-size: 2.4rem;
                            //     background-repeat: no-repeat;
                            //     display: block;
                            //     width: 3rem;
                            //     height: 3rem;
                            //     position: absolute;
                            //     right: 1.5rem;
                            //     top: .7rem;
                            //     transition: all .1s ease-in-out;
                            // }
                        }
                    }

                    &.is-open {
                        a {
                            &::after {
                                content: "";
                                right: .5rem;
                            }
                        }
                    }

                    >a {
                        display: inline-block;
                        padding: 0 .6rem;
                        margin-left: -.6rem;
                        white-space: nowrap;
                        color: $white;
                        font-size: 1.6rem;
                        font-weight: 400;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 100%;
                        white-space: nowrap;

                        &:hover {
                            color: $brand-color-2;
                            background-color: $white;
                        }
                    }

                    &.is-active,
                    &.is-active-parent {
                        >a {
                            color: $white;
                            font-weight: 700;

                            &:hover {
                                color: $brand-color-1;
                            }
                        }
                    }
                }
            }
        }

        .subnav-col3 {
            padding-left: 3rem;
            flex-basis: calc(100% / 3);

            @include mq($min-width: map-get($breakpoints, xl)) {}

            &.cel-open {
                h2 {
                    display: flex;
                }
            }

            h2 {
                display: none;
                justify-content: space-between;
                flex-wrap: wrap;
                padding-right: 3rem;
                align-items: center;

                margin-bottom: 0;
                padding-bottom: 1rem;

                a {
                    font-size: 2.4rem;
                    color: $white;
                }

                a.show-all {
                    text-decoration: underline;
                    font-size: 1.4rem;
                    font-weight: 400;
                }
            }

            .navigation-level-4 {
                margin: 0;
                padding: 0;


                li {
                    list-style-type: none;
                    margin: 0;
                    padding: 0.4rem 0;

                    .navigation-level-5 {
                        display: none;
                    }

                    &.has-children {
                        position: relative;

                        a {
                            // &::after{
                            //     content: "";
                            //     background-image: inline-svg($op-icon-angle-right-svg, $white);
                            //     // background-image: inline-svg($op-icon-arrow-right-svg, $white);
                            //     background-size: 2.4rem;
                            //     background-repeat: no-repeat;
                            //     display: block;
                            //     width: 3rem;
                            //     height: 3rem;
                            //     position: absolute;
                            //     right: 1.5rem;
                            //     top: .7rem;
                            //     transition: all .1s ease-in-out;
                            // }
                        }
                    }

                    &.is-open {
                        a {
                            &::after {
                                content: "";
                                right: .5rem;
                            }
                        }
                    }

                    >a {
                        display: inline-block;
                        padding: 0 .6rem;
                        margin-left: -.6rem;
                        white-space: nowrap;
                        color: $white;
                        font-size: 1.6rem;
                        font-weight: 400;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 100%;
                        white-space: nowrap;

                        &:hover {
                            color: $brand-color-2;
                            background-color: $white;
                        }
                    }

                    &.is-active,
                    &.is-active-parent {
                        >a {
                            color: $white;
                            font-weight: 700;

                            &:hover {
                                color: $brand-color-1;
                            }
                        }
                    }
                }
            }
        }

        .subnav-col4 {
            display: none;
            flex-basis: 50%;

            @include mq($min-width: map-get($breakpoints, xl)) {
                flex-basis: calc(100% / 5);
            }

            &.cel-open {
                h2 {
                    display: flex;
                }
            }

            padding-left: 3rem;

            h2 {
                display: none;
                justify-content: space-between;
                flex-wrap: wrap;
                padding-right: 3rem;
                align-items: center;

                margin-bottom: 0;
                padding-bottom: 1rem;

                a {
                    font-size: 2.4rem;
                    color: $white;
                }

                a.show-all {
                    text-decoration: underline;
                    font-size: 1.4rem;
                    font-weight: 400;
                }
            }

            .navigation-level-5 {
                margin: 0;
                padding: 0;


                li {
                    margin: 0;
                    padding: 0.4rem 0;
                    list-style-type: none;

                    >a {
                        display: inline-block;
                        padding: 0 .6rem;
                        margin-left: -.6rem;
                        white-space: nowrap;
                        color: $white;
                        font-size: 1.6rem;
                        font-weight: 400;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 100%;
                        white-space: nowrap;

                        &:hover {
                            color: $brand-color-2;
                            background-color: $white;
                        }
                    }

                    &.is-active,
                    &.is-active-parent {
                        >a {
                            color: $white;
                            font-weight: 700;

                            &:hover {
                                color: $brand-color-1;
                            }
                        }
                    }
                }
            }
        }

        .subnav-col1,
        .subnav-col2,
        .subnav-col3,
        .subnav-col4 {
            display: none;
        }
    }
}