/*###############################################

@Title: Page Details
@Description:


###############################################*/

select, option {
    font-family: geogrotesque, Arial, sans-serif;
}

.page-details {
    >section{
        padding-left: 2rem;
        padding-right: 2rem;
        @include mq($min-width: map-get($breakpoints, m)) {
          padding-left: 4rem;
          padding-right: 4rem;
        }
        @include mq($min-width: map-get($breakpoints, l)) {
          padding-left: 4rem;
          padding-right: 4rem;
        }
        &:last-of-type{
            padding-bottom: 0;
        }
    }
    .opc-article-list{
        display: block;
    }
    .article-head {
        display: flex;
        position: relative;
        align-items: flex-start;
        margin-bottom: 12px;
        margin-top: 0;
        justify-content: space-between;
        @include mq($max-width: map-get($breakpoints, m)) {
            flex-wrap: wrap;
        }
        @include mq($min-width: map-get($breakpoints, l)) {
            margin-top: 5.5rem;
        }
        .article-flags {
            // padding: 0 $_opc-grid-gutter-width;
            padding: 0;
            position: static;
            // left: 0;
            // top: 0;
            // z-index: 9;
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
        .article-image {
            //padding: $_opc-grid-gutter-width;
            box-sizing: content-box;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            max-width: 100%;
            overflow: hidden;
            padding-bottom: 2rem;
            margin-bottom: 2rem;
            @media print{    
                flex-shrink: 1;
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                // min-height: 59.4rem;
                min-height: 45rem;
            }
            // @include mq($min-width: map-get($breakpoints, l)) {
            //     min-height: 70rem;
            // }
            &.no-img{
                min-height: 30rem;
            }
            position: relative;
            .op-mediaslider-outer{
                // padding-left: 4rem!important;
                // padding-right: 6rem!important;
                .op-mediaslider-inner{
                    overflow: hidden;
                }
            }
            .opc-asset {
                margin-bottom: 2rem;
                max-height: 30vh;
                flex-shrink: 0;
            }
            .opc-asset[data-op-type="gallery"] {
                max-height: auto;
                img {}
            }
            img {
                max-width: 100%;
                // max-height: 50rem;
                height: inherit;
            }
            .article-list-item {
                .article-list-item-image {
                    img {
                        height:auto;
                        cursor: default;
                    }
                }
            } 
            @include mq($min-width: map-get($breakpoints, m)) {
                max-height: 100%;
                width: 50%;
                padding: 0 12px 0 0;
                img {
                    max-width: 100%;
                    height: inherit;
                    // cursor: zoom-in;
                    display: block;
                    margin: 0 auto;
                }
            }
            &[data-op-imagezoom] {
                img {
                    cursor: default;
                    pointer-events: none;
                }
                @include mq($min-width: map-get($breakpoints, m)) {
                    img {
                        pointer-events: all;
                        cursor: zoom-in;
                    }
                }
            }
            .article-image-gallery{
                padding: 0;
                display: flex;
                align-items: center;
                li{
                    text-align: center;
                    list-style-type: none;
                    padding: 0 1px;
                }
            }
            .customize-tools{
                width: 100%;
                padding: 2rem;
                // border-radius: 5px;
                #customize-thumbnails{
                    padding: 0;
                    display: flex;
                    // flex-wrap: wrap;
                    // justify-content: center;
                    margin: 0;
                    overflow-x:auto;
                    li{
                        flex-basis: 8rem;
                        width: 8rem;
                        height: 8rem;
                        padding: .5rem;
                        flex-shrink: 0;
                        flex-grow: 0;
                        margin: 1rem;
                        //padding: .3rem;
                        list-style-type: none;
                        border-radius: 0;
                        background-color: $white;
                        border: 2px solid transparent;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        &.op-mediaslider-nav-active,
                        &.tns-nav-active{
                            border-color: $brand-color-1;
                            // background:  $brand-color-1;
                        }
                        &.videothumb {
                            &::before {
                                align-items: center;
                                background-color: rgb(226, 1, 26);
                                background-image: inline-svg($op-icon-playbutton-svg, rgb(226, 1, 26));
                                background-position: center;
                                background-size: 22px;
                                background-repeat: no-repeat;
                                color: rgb(255, 255, 255);
                                content: "";
                                cursor: pointer;
                                display: flex;
                                height: 50%;
                                justify-content: center;
                                left: 50%;
                                position: absolute;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                width: 50%;
                                z-index: 1;
                                border-radius: 25%;
                            }
                        }
                        img{
                            cursor: pointer;
                            object-fit: contain;
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                }
            }
            .tns-nav,
            .tns-liveregion,
            .tns-controls{
                display: none;
            }
            #customize-controls{
                padding: 0;
                width: 100%;
                // position: absolute;
                // bottom: -.5rem;
                // left: 0;
                // right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 9;
                // height: 0;
                outline: none;
                margin: 0;
                li{
                    // width: 2rem;
                    // height: 2rem;
                    outline: none;
                    cursor: pointer;
                    &.prev{
                        display: none;
                        background-image: inline-svg($slider-arrow-left, $text-color);
                        &:hover{
                            background-image: inline-svg($slider-arrow-left, $brand-color-1);
                        }
                        // background-size: 100%;
                        background-size: contain;
                        background-repeat: no-repeat;
                        // background-position: -2rem center;
                    }
                    &.next{
                        display: none;
                        order: 3;
                        background-image: inline-svg($slider-arrow-right, $text-color);
                        &:hover{
                            background-image: inline-svg($slider-arrow-right, $brand-color-1);
                        }
                        // background-size: 100%;
                        background-size: contain;
                        background-repeat: no-repeat;
                        // background-position: -2rem center;
                        margin-right: 2rem;
                    }
                    &.dots-workaround{
                        width: 100%;
                        max-width: 78rem;
                        .customize-tools{
                            // background-color: rgba($brand-color-1,.4);
                            background: transparent;
                            padding: 0;
                        }
                    }
                    list-style-type: none;
                    img{
                        max-width: 4rem;
                    }
                }
            }
        
            .op-mediaslider-outer {
                z-index:2;
            }

            .image-detail-footer {
                width: 100%;
                @media print{    
                    display: none;
                }
            }
      
            .article-fittedart-wrapper {
                margin-top: 4rem;
                width: 100%;
                display: none;
                @include mq($min-width: map-get($breakpoints, m)) {
                  display: block;
                }
            }
            .image-detail-body {
                max-width: 100%;
            }
        }
        .article-infos {
            width: 100%; //background: red;
            padding: $_opc-grid-gutter-width;
            @include mq($min-width: map-get($breakpoints, xl)) {
                max-width: 47.5rem;
            }
            h1{
                font-size: 2.4rem;
                font-weight: bold;
                margin-bottom: 2rem;
            }

            #chooseprodart {
                margin:4rem 0;
                .opc-form-field {
                    .form-field-label {
                        display: block !important;
                        font-size: 18px;
                        font-weight: bold;
                    }
                    .opc-select {
                        background-color: #fff;
                        border: 1px solid $brand-color-1;
                        border-left: none;
                        border-right: none;
                        padding: 0;
                        border-radius: 0;
                        &:focus {
                            outline: none;
                            }
                            option {
                                &:hover {
                                    background-color:$gray;
                                }
                            }
                    }
                }
            }
            
            .article-rebatetab {
                margin:1rem 0;
              
                .opc-item-list {
                        .item-list-head {
                        border-bottom: none;
                        font-weight: bold;
                        font-size: 1.8rem;
                        .list-item-column {
                            min-width: 7rem;
                            max-width: 12rem;
                            flex-grow: 1;
                            width: auto;
                            justify-content: flex-end;
                            &:first-child {
                                justify-content: flex-start;
                                
                            }
                        }
                    }
                    .list-item {
                        border:none;
                        &:hover {
                            padding:0;
                            background:transparent;
                        }
                        .list-item-column {
                            min-width: 7rem;
                            max-width: 12rem;
                            width: auto;
                            flex-grow: 1;
                            padding: 0.5rem 0;
                            justify-content: flex-end;
                            &:last-child {
                                text-decoration: line-through;
                            }
                            &:first-child {
                                justify-content: flex-start;
                            }
                        }
                    }
                }

            }

            .opc-price .price-basis, 
            .opc-price .price-service, 
            .opc-price .price-promotion{
                font-size: 2.4rem;
                margin-bottom: 2rem;
            }
            // .opc-price .price-service,
            // .opc-price .price-promotion{
            //    color: $brand-color-1;
            // }
            .set-occ-price {
                .opc-price  {
                    &.is-promo {
                        .price-service {
                            -ms-flex-order: 1;
                            order: 1;
                            display: flex;
                        }
                        .price-basis {
                            order: 2;
                            display: flex;
                            text-decoration: line-through;
                            font-size: 1.8rem !important;
                            margin-bottom: 2rem;
                            [data-content-lang="1"] & {
                                &::before{
                                    content: "Statt \0020";
                                    font-weight: normal;
                                    white-space: pre;
                                }
                            }
                            .price {
                                font-weight: normal;
                            }
                        }
                    }
                }
                
            }
            .article-art-brand,
            .article-art-nr {
                font-size: 1.6rem;
                color: #A5AFBB;
                b{
                    font-weight: 400;
                    color: $brand-color-2;
                }
            }
            .stock {
                margin-top: 2rem;
                font-size: 1.6rem;
                font-weight: normal;
                display: flex;
                align-items: center;
                margin-bottom: 2.5rem;
                .icon {
                    width: 1.2rem;
                    height: 1.2rem;
                    display: block;
                    border-radius: 5rem;
                    margin-right: 1rem;
                    flex-shrink: 0;
                    &.lot {
                        background: $stock-on-stock;
                    }
                    &.less {
                        background: $stock-less-stock;
                    }
                    &.none {
                        background: $stock-out-stock;
                    }
                }
                .value {
                    font-weight: 400;
                    line-height: 1;
                    &.lot {
                        color: $stock-on-stock;
                    }
                    &.less {
                        color: $stock-less-stock;
                    }
                    &.none {
                        color: $stock-out-stock;
                    }
                }
            }
            .article-actions {
                @media print{    
                    display: none;
                   }
                display: flex;
                align-items: flex-start;
                flex-wrap: nowrap;
                align-items: flex-end;
                justify-content: space-between;
                background-color: $brand-color-1;
                position: fixed;
                bottom: 10px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 9;
                @include mq($min-width: map-get($breakpoints, s)) {
                    //flex-wrap: nowrap;
                }
                @include mq($min-width: map-get($breakpoints, l)) {
                    //flex-wrap: wrap;
                    position: static;
                    transform: none;
                    margin-bottom: 40px;
                }
                .opc-basket-button,
                .opc-favorite-button,
                .opc-compare-button {
                    margin: 0;
                    margin-right: 0;
                    flex-grow: 1;
                    @include mq($min-width: map-get($breakpoints, s)) {
                        flex-grow: 0;
                    }
                }
                .opc-basket-button {
                    display: flex;
                    flex-grow: 1;
                    flex-wrap: nowrap;
                    width: auto;
                    min-width: 288px;
                    @include mq($min-width: map-get($breakpoints, s)) {
                    }
                    @include mq($min-width: map-get($breakpoints, m)) {
                    }
                    @include mq($min-width: map-get($breakpoints, xl)) {
                    }
                    .opc-button {
                        //margin-left: 1rem;
                        //flex-grow: 1;
                        //width: 100%;
                        flex-grow: 1;
                        width: auto;
                        @include mq($min-width: map-get($breakpoints, s)) {
                            width: auto;
                        }
                        @include mq($min-width: map-get($breakpoints, s)) {
                            //flex-grow: 0;
                        }
                    }
                    &[data-op-layout="touch"]{
                        width: auto;
                        .basket-button-dim-1 {
                            // margin-bottom: 1rem;
                            align-items: center;
                            width: 100%;
                            max-width: 160px;
                            flex-shrink: 0;
                            @include mq($min-width: map-get($breakpoints, xl)) {
                                margin: 0 auto;
                                margin: 0;
                                width: 18rem;
                            }
                            label{
                                display: none;
                            }
                            .cust-qtychange-btn{
                                background-color: transparent;
                                width: 3.4rem;
                                height: 100%;
                                flex-grow: 0;
                                font-size: 3.4rem;
                                font-weight: 400;
                                border: none;
                                color: $white;
                                flex-grow: 0;
                                &[data-op-qtychange="dec"]{
                                    padding-left: 10px;
                                    padding-right: 0;
                                }
                                &[data-op-qtychange="inc"]{
                                    border-right: 1px solid $white;
                                    padding-left: 8px;
                                    padding-right: 0;
                                }
                            }
                            .opc-form-field{
                                border: 1px solid $white;
                                border-top: none;
                                border-bottom: none;
                                padding: .6rem 1.5rem .6rem 0;
                                margin: 0!important;
                                height: 100%;
                                .form-field-label{
                                    display: inline!important;
                                    color: $white;
                                    margin-left: 3px;
                                    font-weight: 400;
                                }
                                .form-field-element{
                                    display: inline;
                                    input.basket-button-quantity{
                                        border: none;
                                        width: 3rem;
                                        color: $white;
                                        background-color: transparent;
                                        text-align: right;
                                        font-weight: 400;
                                        &:focus{
                                            outline: none;
                                        }
                                    }
                                }
                            }
                        }
                        .opc-button{
                            // @include mq($min-width: map-get($breakpoints, s)) {
                                //     width: 18rem;
                                // }
                            @include mq($min-width: map-get($breakpoints, l)) {
                                width: 22rem;
                            }
                            @include mq($min-width: map-get($breakpoints, xl)) {
                                    width: 29rem;
                            }
                            display: flex;
                            justify-content: space-between;
                            padding-left: 1rem;
                            padding-right: 1rem;
                            svg{
                                order: 2;
                                font-size: 2.8rem;
                                display: block;
                                margin:0 auto;
                                @include mq($min-width: map-get($breakpoints, l)) {
                                    display: none;
                                }
                            }
                            span{
                                order: 1;
                                flex-grow: 1;
                                display: none;
                                @include mq($min-width: map-get($breakpoints, l)) {
                                    display: inline;
                                }
                            }
                        }
                        >.opc-button{
                            flex-shrink: 1;
                            margin-right: 7px;
                            &:focus{
                                background-color: transparent;
                            }
                            &:focus,
                            &:hover {
                                background: transparent !important;
                                // transform: scale(1.1);
                            }
                            &.is-progress {
                                background: transparent !important;
                                cursor: progress;
                            }
                            &.is-success{
                                background:$success-color !important;
                                margin-left: -1.5rem;
                                margin-right: 0rem;
                            }
                            @include mq($min-width: map-get($breakpoints, l)) {
                                padding-left: .5rem;
                                padding-right: 1.5rem;
                            }
                        }
                    }
                }
                .opc-favorite-button {
                    padding-left: 1rem;
                    padding-right: 1rem;
                    flex-grow: 0;
                    background-color: transparent;
                    border-left: 1px solid $white;
                    .op-icon-fav{
                        color: $white;
                        margin-right: 0;
                    }
                    span{
                        display: none;
                    }
                    &:hover, &:focus {
                        color: $_opc-button-loud-color-hover;
                        background: $_opc-button-loud-bg-hover;
                        &:disabled {
                            background: $_opc-button-loud-bg;
                            color: $_opc-button-loud-color;
                        }
                    }
                }
                @include mq($min-width: map-get($breakpoints, m)) {}
            }
            .social-links{
                display: flex;
                justify-content: flex-start;
                margin-bottom: 2rem;
                @media print{    
                    display: none;
                   }
                @include mq($min-width: map-get($breakpoints, m)) {
                    margin-top: 2rem;
                    margin-bottom: 0;
                }
                @include mq($min-width: map-get($breakpoints, xl)) {
                    justify-content: flex-end;
                }
                a{
                    display: block;
                    color: $gray;
                    font-weight: bold;
                    font-size: 1.6rem;
                    display: flex;
                    align-items: center;
                    width: 3.6rem;
                    height: 3.6rem;
                    margin-right: 1.5rem;
                    svg{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .pdf-create {
                display: flex;
                justify-content: flex-start;
                margin-top: 6rem;
                margin-bottom: 4rem;
                @media print{    
                    display: none;
                   }

                   .social-link {
                    width: 3.6rem;
                    height: 3.6rem; 
                    // MH-418                    
                    // margin-left: 4rem;
                        > svg {
                            font-size: 2.6em;
                        }
                   }
                }
                .pdf-footer-contact {
                    display: none;
                    @media print{    
                        display: block;
                        margin-top: 3rem;
                        }
                    .wrapper {
                        display: flex;
                        > span {
                            width: 50%;
                        }
                    }
            }
            .sheetmusic-info {
                margin-bottom: 2.4rem;
                 label {
                    @extend .article-art-nr;
                }
                b {
                        font-weight: 400;
                        color: $brand-color-2;
                }
            }
        }
    }
    .section-details-light {
        background: #eee;
        max-width: 100%;
        overflow: hidden;
        .opc-tabs {
            &[data-op-type="accordion"] {
                margin-top: 2rem;
            }
            &[data-op-type="tabs"] {
                .tab-list {
                    border-bottom: none;
                    background: #fff;
                    &::before,
                    &::after {
                        border-bottom: none;
                        background: #fff;
                    }
                    li {
                        border: none;
                        color: #000;
                        &.is-active {
                            color: #000;
                            background: #eee;
                            border-bottom: none;
                            &::after {
                                display: none;
                            }
                        }
                        a {
                            font-size: 1.6rem;
                        }
                    }
                }
                .tab-content-wrapper {
                    margin-bottom: 4rem;
                }
            }
        }
    }
    .section-details-default {
        background: #fff;
        max-width: 100%;
        overflow: hidden;
        .opc-tabs {
            &[data-op-type="tabs"] {
                .tab-list {
                    border-bottom: none;
                    background: #eee;
                    &::before,
                    &::after {
                        border-bottom: none;
                        background: inherit;
                        background: #eee;
                    }
                    li {
                        border: none;
                        color: #000;
                        &.is-active {
                            color: #000;
                            background: #fff;
                            border-bottom: none;
                            &::after {
                                display: none;
                            }
                        }
                        a {
                            font-size: 1.6rem;
                        }
                    }
                }
                .tab-content-wrapper {
                    margin-bottom: 4rem;
                }
            }
        }
    }

    .article-desc,
    .article-spec,
    .article-download{
        display: flex;
        flex-wrap: wrap;
        max-width: $mw;
        margin: 0 auto 3.5rem;
        padding: 0rem;
        @media print{  
            padding: 0 4rem;
        }
        iframe {
            max-width: 100%;
            @media print{  
                display: none;
            }
        }
        @include mq($min-width: map-get($breakpoints, m)) {
            padding: 0;
        }
        >*{
            flex-basis: 100%;
            line-height: 1.1;
            font-size: 1.6rem;
            @include mq($min-width: map-get($breakpoints, m)) {
                // flex-basis: 75%;
            }
        }
        >h4{
            margin-bottom: 0 !important;
            + div,
            + ul{
                display: none;
                // @include mq($min-width: map-get($breakpoints, l)) {
                //     display: block;
                // }
            }
            @include mq($min-width: map-get($breakpoints, m)) {
            }
            font-size: 1.6rem!important;
            font-weight: 400!important;
        }
        &.is-open{
            >h4{
                + div,
                + ul{
                    display: block;
                    // overflow: hidden;
                }
                + div.stock-container{
                    display: flex;
                }
                +div:not(.stock-container) {
                    padding-bottom: 2rem;
                }
            }
        }
        h1,h2,h3,h4,h5,h6{
            line-height: 1.4;
            font-weight: bold;
            margin-bottom: .5rem;
        }
        h2{
            font-size: 3.2rem;
            font-weight: 400;
        }
        ul{
            margin: 0;
            padding: 0;
        }
        ul.artSpec {
            padding-left:2rem;
            &[data-artspectypeno="80"] {
                li[data-artspecattrgroupno="2"] {
                    > ul {
                        > li {
                            display: inline;
                            white-space: nowrap;
                            &:nth-child(odd) {
                                position: relative;
                                &::before {
                                    content: "";
                                    height: 0.5rem;
                                    width: 0.5rem;
                                    background: black;
                                    border-radius: 50%;
                                    display: block;
                                    position: absolute;
                                    left: -16px;
                                    top: 9px;
                                }
                            }
                            &:nth-child(2n) {
                                &::before {
                                    content: "|";
                                }
                                &::after {
                                    content: "\a";
                                    white-space: pre;
                                }
                            }
                        }
                    }
                }
            }
        }
        .opc-static-content {
            ul {
                margin-left:2rem;
                @media print{    
                    display: flex;
                    flex-wrap: wrap
                   }
                li {
                    margin-bottom:.5rem;
                    line-height: 1.5;
                    @media print{    
                        flex-grow: 1;
                        width: 50%;
                    }
                }
            }
        }
        li{
            line-height: 1.5;
            list-style-position: outside;
            list-style-type:  disc;
            // list-style-type: none;
            // &::before{
                //     content: "-";
                //     display: inline-block;
                //     margin: 0 .6rem;
                // }
                &.artSpecGroup{ 
                    list-style-type: none;
                    > label {
                        font-weight: bold;
                        margin-left:-2rem;
                    }

                &::before{
                    content: "";
                    display: none;
                }
                .artSpecAttr{
                    label{
                        &::after{
                            content: ": \0020";
                            word-wrap: pre;
                        }
                    }
                }
                > ul {
                    @media print{    
                        display: flex;
                        flex-wrap: wrap
                       }
                       >li {
                        @media print{    
                            flex-grow: 1;
                            width: 50%;
                           }
                       }
                }
            }
        }
        &.track-list {
            ul {
                margin-bottom: 2rem;
                > span {
                    font-size: 1.6rem;
                    color: #444747;
                    margin-bottom: .5rem;
                    display: block;
                }
                li {
                    display: flex;
                    > span {
                        width: auto;
                        padding:0 1rem;
                        color: #444747;
                        &:first-child {
                            width: 2rem;
                            text-align: right;
                            margin-right: 4rem;
                        }
                        &:last-child {
                            margin-left: auto;
                        }
                    }
                }
            }
        }
    }
    .toggle-area{
        margin-bottom: 0;
        >h4{
            position: relative;
            border-bottom: 1px solid $brand-color-1;
            padding: 10px 0;
            color: $brand-color-2;
            cursor: pointer;
            &::after{
                font-size: 2.4rem;
                content: "+";
                position: absolute;
                right: 0;
                color: $brand-color-1;
                line-height: 20px;
                margin-right: 15px;
            }
        }
        &.is-open{
            border-bottom: 1px solid $brand-color-1;
            >h4{
                border-bottom: none;
                &::after{
                    content: "-";
                }
            }
            li {
                &.artSpecGroup,.artSpecAttr {
                    list-style-type: none;
                }
                .audiosample {
                    svg {
                        height: 1.2rem;
                        width: 1.2rem;
                        fill: $brand-color-1;
                    }
                    &:hover {
                        svg {
                            fill:  $_opc-link-color-hover;
                        }
                    }
                }
            }
        }
        
        @include mq($min-width: map-get($breakpoints, l)) {
            // margin-bottom: 4rem;
            // >h4{
            //     border: none!important;
            //     &::after{
            //         display: none!important;
            //     }
                
            // }
        }

        .stock-container{
            display: none;
            flex-wrap: wrap;
            margin-left: -5px;
            margin-right: -5px;
            .stock{
                flex-basis: calc(100% / 2);
                @include mq($min-width: map-get($breakpoints, l)) {
                    flex-basis: calc(100% / 3);
                }
                padding: 0 5px;
                display: flex;
                flex-wrap: wrap;
                font-size: 1.6rem!important;
                margin-top: 0;
                margin-bottom: 10px;
                .label, .icon, .value{
                    font-size: inherit;
                    margin-bottom: 10px;
                }
                .label{
                    flex-basis: 100%;
                    font-weight: 400;
                }
                .icon{
                }
                .value{
                    font-weight: 400;
                }
            }
        }
    }
    .article-actions{
        + .toggle-area{
            >h4{
                border-top: 1px solid $brand-color-1;
            }
        }
    }
    #tabsPart1{
        display: flex;
        flex-wrap: wrap;
        max-width: $mw;
        margin: 0 auto 3.5rem;
        padding: 0rem;
        @media print{  
            padding: 0 4rem;
        }
        .article-fittedart-wrapper-inverted {
            display: block;
            h4{
                color: $brand-color-2;
                @include mq($min-width: map-get($breakpoints, m)) {
                    flex-basis: 25%;
                }
                font-size: 2.4rem!important;
                font-weight: bold;
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                display: none;
            }
        }
        iframe {
            @media print{  
                display: none;
            }
        }
        @include mq($min-width: map-get($breakpoints, m)) {
            padding: 0;
        }
        >*{
            width:100%;            
            @include mq($min-width: map-get($breakpoints, m)) {
                flex-basis: 75%;
            }
            line-height: 1.1;
        }
        >h4{
            color: $brand-color-2;
            @include mq($min-width: map-get($breakpoints, m)) {
                flex-basis: 25%;
            }
            font-size: 2.4rem!important;
            font-weight: bold;
        }
        h1,h2,h3,h4,h5,h6{
            line-height: 1.4;
            font-weight: bold;
            margin-bottom: .5rem;
        }
        h2{
            font-size: 2.4rem;
        }
        ul{
            margin: 0;
            padding: 0;
        }
        ul.artSpec {
            padding-left:2rem;
        }
        .opc-static-content {
            ul {
                margin-left:2rem;
                @media print{    
                    display: flex;
                    flex-wrap: wrap
                   }
                li {
                    margin-bottom:.5rem;
                    line-height: 1.5;
                    @media print{    
                        flex-grow: 1;
                        width: 50%;
                    }
                }
            }
        }
        li{
            line-height: 1.5;
            list-style-position: outside;
            list-style-type:  disc;
            // list-style-type: none;
            // &::before{
                //     content: "-";
                //     display: inline-block;
                //     margin: 0 .6rem;
                // }
                &.artSpecGroup{ 
                    list-style-type: none;

                &::before{
                    content: "";
                    display: none;
                }
                .artSpecAttr{
                    label{
                        &::after{
                            content: ": \0020";
                            word-wrap: pre;
                        }
                    }
                }
                > ul {
                    @media print{    
                        display: flex;
                        flex-wrap: wrap
                       }
                       >li {
                        @media print{    
                            flex-grow: 1;
                            width: 50%;
                           }
                       }
                }
            }
        }
        @media print{    
         display: none;
        }
        >*,
        >h4{
            flex-basis: 100%;
            width: 100%;
        }
        >h4{
            margin-bottom: 1.5rem;
        }
        .opc-article-list{
            display: flex;
            margin-bottom: 4rem;
            &:last-child{
                margin-bottom: 0;
            }
        }
        li{
            &::before{
                content: "";
                display: none;
            }
        }
        .opc-article-list-wrapper{
            &:first-of-type{
                margin-bottom: 4rem;
            }
            ul.opc-article-list{
                margin-left: -.5rem;
                margin-right: -.5rem;

            }
        }
        .opc-article-list {
            flex-wrap: nowrap;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            @include mq($min-width: map-get($breakpoints, l)) {
                flex-wrap: wrap;
                overflow-x: none;
            }
            &[data-op-tile-col="4"] {
                >li {
                    flex-basis: 45%;
                    max-width: 45%;
                    flex-shrink: 0;
                    list-style-type: none;
                    // @include mq($min-width: map-get($breakpoints, s)) {
                    //     flex-basis: span(7);
                    //     max-width: span(7);
                    // }
                    @include mq($min-width: map-get($breakpoints, m)) {
                        flex-basis: 30%;
                        max-width: 30%;
                    }
                    @include mq($min-width: map-get($breakpoints, l)) {
                        flex-basis: span(3);
                        max-width: span(3);
                    }
                    @include mq($min-width: map-get($breakpoints, xl)) {}
                }
            }
            .article-list-item-description {
                display: none;
                @include mq($min-width: map-get($breakpoints, m)) {
                    display: block;
                }
            }
        }
    }
    #tabsPart2 {
        >h4{
            color: $brand-color-2;
            @include mq($min-width: map-get($breakpoints, m)) {
                flex-basis: 25%;
            }
            font-size: 2.4rem!important;
            font-weight: bold;
        }
    }
    &.occasionen.opc-article-list {
        display:inherit;
        margin:inherit;
        padding:inherit;
        list-style:inherit;
    }
    .article-stock-detail {
        .stock {
            margin-top: 2rem;
            font-size: 1.8rem;
            font-weight: normal;
            display: flex;
            align-items: center;
            margin-bottom: 2.5rem;
            .icon {
                width: 1.2rem;
                height: 1.2rem;
                display: block;
                border-radius: 5rem;
                margin-right: 1rem;
                flex-shrink: 0;
                &.lot {
                    background: $stock-on-stock;
                }
                &.medium,
                &.none {
                    background: $stock-out-stock;
                }
            }
            .value {
                font-weight: bold;
                line-height: 1;
                &.lot {
                    color: $stock-on-stock;
                }
                &.medium,
                &.none {
                    color: $stock-out-stock;
                }
            }
        }
    }
}

// .spec-wrapper {
//     padding-top: 2rem;
//     [data-op-type="accordion"] & {
//         padding-top: 0;
//     }
//     .opc-art-spec {
// 		display: flex;
// 		margin-left: -$_opc-grid-gutter-width;
// 		margin-right: -$_opc-grid-gutter-width;
// 		width: calc(100% + ($_opc-grid-gutter-width * 2));
//         flex-wrap: wrap;
        
//         .art-spec-group {
// 			padding: $_opc-grid-gutter-width;
// 			width: 100%;
			

// 			@include mq($min-width: map-get($breakpoints, s)) {
// 				width: 50%;
// 			}
// 			@include mq($min-width: map-get($breakpoints, m)) {
// 				width: 33.3%;
// 			}
//         }
//     }
// }

.section-article-list{
    h1{
        font-size: 3.8rem;
        word-break: break-word;
        text-align: center;
        font-weight: 400;
        @include mq($min-width: map-get($breakpoints, m)) {
            font-size: 4.6rem;
        }
    }
}

.image-zoom{
    position: relative;
    padding:2.4rem;
    .image-zoom-header{
        height: 12.5rem;
        h1{
            font-size: 2.4rem;
            padding-top: 7rem;
        }
        .close-zoom-bar{
            text-align: center;
            margin-top: .6rem;
            position: fixed;
            left: 50%;
            transform: translateX(-50%);
            a{
                background-color: $brand-color-1;
                display: flex;
                align-items: center;
                max-width: 30rem;
                margin: auto;
                color: $white;
                cursor: pointer;
                label{
                    cursor: pointer;
                    margin-bottom: 0;
                    margin-right: 1rem;
                }
            }
        }
    }
    .article-image {
        box-sizing: content-box;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        min-height: 40rem;
        overflow: hidden;
        position: static;
        @include mq($min-width: map-get($breakpoints, m)) {
            min-height: 60rem;
        }
        height: calc(100vh - 30rem);
        max-height: none;
        // @include mq($min-width: map-get($breakpoints, l)) {
        //     min-height: 70rem;
        // }
        &.no-img{
            min-height: 30rem;
        }
        .opc-asset {
            margin-bottom: 2rem;
            max-height: 30vh;
            flex-shrink: 0;
        }
        .opc-asset[data-op-type="gallery"] {
            max-height: auto;
            img {}
        }
        img {
            max-width: 100%;
            max-height: inherit;
            height: inherit;
        }
        @include mq($min-width: map-get($breakpoints, m)) {
            img {
                height: auto;
                max-width: 100%;
                cursor: default;
            }
        }
        .article-image-gallery{
            padding: 0;
            display: flex;
            align-items: center;
            li{
                text-align: center;
                list-style-type: none;
                img{
                    max-height: calc(100vh - 30rem);
                }
            }
        }
        .customize-tools{
            width: 100%;
            padding: 2rem;
            position: fixed;
            bottom: 0;
            left:0;
            z-index: 2;
            background-color: $brand-color-2;
            #customize-thumbnails{
                padding: 0;
                display: flex;
                // flex-wrap: wrap;
                justify-content: center;
                margin: 0;
                overflow-x:auto;
                li{
                    flex-basis: 8rem;
                    width: 8rem;
                    height: 8rem;
                    padding: .5rem;
                    flex-shrink: 0;
                    flex-grow: 0;
                    margin: 1rem;
                    //padding: .3rem;
                    list-style-type: none;
                    border-radius: 0;
                    background-color: $white;
                    border: 2px solid transparent;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    &.op-mediaslider-nav-active,
                    &.tns-nav-active{
                        border-color: $brand-color-1;
                        // background:  $brand-color-1;
                    }
                    img{
                        max-width: 100%;
                        max-height: 100%;
                    }
                    &.videothumb {
                        &::before {
                            align-items: center;
                            background-color: rgb(226, 1, 26);
                            background-image: inline-svg($op-icon-playbutton-svg, rgb(226, 1, 26));
                            background-position: center;
                            background-size: 22px;
                            background-repeat: no-repeat;
                            color: rgb(255, 255, 255);
                            content: "";
                            cursor: pointer;
                            display: flex;
                            height: 50%;
                            justify-content: center;
                            left: 50%;
                            position: absolute;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            width: 50%;
                            z-index: 1;
                            border-radius: 25%;
                        }
                    }
                }
            }
        }
        .tns-nav,
        .tns-liveregion,
        .tns-controls{
            display: none;
        }
        #customize-controls{
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            justify-content: center;
            position: absolute;
            top: 40%;
            left: 0;
            right: 0;
            display: flex;
            justify-content: space-between;
            z-index: 9;
            height: 0;
            outline: none;
            li{
                width: 6rem;
                height: 6rem;
                outline: none;
                cursor: pointer;
                // border-radius: 5rem;
                // background-color: rgba($black,.5);
                &.prev{
                    background-image: inline-svg($slider-arrow-left, $text-color);
                    &:hover{
                        background-image: inline-svg($slider-arrow-left, $brand-color-1);
                    }
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: -2rem center;
                    margin-left: 2rem;
                }
                &.next{
                    background-image: inline-svg($slider-arrow-right, $text-color);
                    &:hover{
                        background-image: inline-svg($slider-arrow-right, $brand-color-1);
                    }
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: 2rem center;
                    margin-right: 2rem;
                }
                list-style-type: none;
                img{
                    max-width: 4rem;
                }
            }
        }
    
        .op-mediaslider-outer {
            z-index:2;
        }
  
    }


}


.musify-wrapper {
    border:1px solid $brand-color-1;
    padding:1rem;
    margin-bottom: 2rem;
    ._form._inline-form {
        padding:0 !important;
        width: 100%;
        display: block !important;
        float: none !important;
        font-family: 'Geogrotesque';
        margin-top: 2rem !important;
    
        ._form-content {
            // display: flex;
            // justify-content: space-between;
            // flex-wrap: wrap;
            display: grid;
            grid-column-gap: 1rem;
            grid-row-gap: 1rem;
            // @include mq($min-width: map-get($breakpoints, m)) {
            //     grid-template-columns: repeat(3, 1fr);
            //     // max-width: 15rem !important;
            // }
            label {
                display: none !important;
            }
            ._form_element {
                margin-left: 0 !important;
                margin-bottom:0 !important;
                input {
                    font-family: "Geogrotesque";
                    font-size: 1.6rem;
                    border: none !important;
                    background: #F0F0F0;
                    border-radius: 0px !important;
                    font-size: 1.6rem;
                    height: auto !important;
                    line-height: auto;
                    width: 100% !important;
                    padding: 0 1.25rem;
                }
    
                textarea {
                    display: none !important;
                }
                &:last-child {
                    display: none!important;
                }
    
            }
            ._button-wrapper {
                margin: 0 !important;
                button {
                    padding-left: 2rem !important;
                    padding-right: 2rem !important;
                    font-family: "Geogrotesque";
                    width: 100%;
                }
            }
        }
    }
    .musify {
        margin-bottom: 2rem;
    }
}

[data-simplebar] {
    
    &::-webkit-scrollbar {
      width: 8px;
      height: 11px;
    }
    
    /* Track (der Hintergrund der Scrollleiste) */
    &::-webkit-scrollbar-track {
      background: #F8F8F8; /* Farbe des Hintergrunds */
    }
    
    /* Thumb (der bewegliche Teil der Scrollleiste) */
    &::-webkit-scrollbar-thumb {
      background: rgba($brand-color-2,.3); /* Farbe des beweglichen Teils */
    }
    
    /* Aendere das Aussehen des Thumbs bei Hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgba($brand-color-2,.5); /* Farbe des beweglichen Teils bei Hover */
    }
    
    &:hover::-webkit-scrollbar-thumb {
      background: rgba($brand-color-2,.5); /* Farbe des beweglichen Teils bei Hover */
    }
  
}