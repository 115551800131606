/*###############################################

@Title: Offcanvas Nav
@Description:


###############################################*/


/*
 Types:
 - full: for a fullscreen menu
 - side: for a side menu that moves the content to the right 
*/
$offcanvas-type: 'side'; // side or full
$offcanvas-width: 100%; // width of side menu
$offcanvas-color: #fff;

.offcanvas-menu-wrapper {
	// border: 6px solid $white;
	border-top: none;
	top: 65px;
	position: fixed;
	left: 0;
	bottom: 0;
	background: #4B5F77;
	overflow-x: hidden;
	overflow-y: auto;
	transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
	@include mq($min-width: map-get($breakpoints, s)) {
		border: none;
		// border-left: 6px solid $white;
	}
	@include mq($min-width: map-get($breakpoints, m)) {
		top: 136px;
		// border-left: 1.2rem solid $white;
	}
	
	&.usp-bar-active {
		top:107px;
		@include mq($min-width: map-get($breakpoints, m)) {
			top: 179px;
		}
	}

	@if $offcanvas-type=='full' {

		@extend %mw;
		right: 0;
		// hide when not active
		visibility: hidden;
		z-index: -10;
		opacity: 0;

		// show when offcanvas-menu-open class is set to body
		.offcanvas-menu-open & {
			visibility: visible;
			z-index: 1000;
			opacity: 1;
		}


	}

	@else if $offcanvas-type=='side' {

		width: $offcanvas-width;
		left: -$offcanvas-width;
		
		@include mq($min-width: map-get($breakpoints, s)) {
			width: 50%;
			left: -50%;
		}

		.offcanvas-menu-open & {
			transform: translateX(100%);
			z-index: 1000;
		}
	}

	.offcanvas-menu-inner {
		@extend %mw-inner;

		.opc-mini-label {
			padding: 1rem 1.5rem 0;
			margin-bottom: 0.5rem;
			color: #fff;
			margin-top: 2rem;
			font-size: 2.4rem;
			font-weight: bold;
			opacity: 1;

			&:first-child {
				margin-top: 0;
			}
		}

		display: flex;
		height: 100%;
		flex-direction: column;
	}
	.offcanvas-menu-body{
		@include mq($min-width: map-get($breakpoints, m)) {
			padding-left: 25px;
			padding-right: 25px;
		}
	}
	.offcanvas-menu-head {
		@include mq($min-width: map-get($breakpoints, m)) {
			padding-left: 25px;
			padding-right: 25px;
		}
		@include mq($min-width: map-get($breakpoints, l)) {
			display: none;
		}
		display: block;
		background-color: $white;
		padding: 0;
		.offcanvas-action{
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px 20px;
			>div{
				flex-basis: 50%;
			}
			.account-link{
				svg{
					font-size: 2rem;
					color: $brand-color-2;
					margin-right: 10px;
				}
				.account-link-login-indicator{
					width: 10px;
					height: 10px;
					display: block;
					background-color: $success-color;
					border-radius: 999px;
					position: absolute;
					left: 13px;
					bottom: 10px;
				}
				>.opc-button{
					padding: 15px 0;
					.text{
						font-size: 1.6rem;
						margin: 0;
						color: $brand-color-2;
						max-width: 120px;
						text-overflow: ellipsis;
						overflow: hidden;
						@include mq($min-width: map-get($breakpoints, xs)) {
							max-width: none;
						}
						@include mq($min-width: map-get($breakpoints, s)) {
							max-width: 120px;
						}
					}
				}
			}
			.opc-favorite-info{
				>.opc-button{
					padding: 15px 0;
					overflow: visible;
					svg{
						font-size: 2rem;
						color: $brand-color-2;
						margin-right: 10px;
					}
					.favorite-text{
						margin: 0;
						font-size: 1.6rem;
						display: inline;
						color: $brand-color-2;
					}
					.favorite-count{
						right: -16px;
						top: 0;
					}
				}
			}
		}
		.opc-navigation {
			li a {
				margin-right: 0;
				color: $brand-color-2;
			}
			&[data-op-navtype="aside-click"] {
				.has-children {
					position: relative;
					.navigation-toggler {
						background: $white;
						&::after {
							background-image: inline-svg($op-icon-angle-down-svg, $brand-color-2);
						}
					}
				}
				.navigation-level-1 {
					>li {
						border-bottom: 1px solid $brand-color-2;
						>a {
						}
						&.is-open {
							>a {
								color: $brand-color-2;
							}
							>.navigation-level-2 {
							}
						}
						&.is-active {
							>a {
								color: $brand-color-2;
							}
						}
						.navigation-level-2 {
							>li{
								&.is-open {
									>a {
										font-weight: 400;
										color: $brand-color-2;
										text-decoration: none;
										text-underline-offset: 0;
										padding: 6px 0;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.offcanvas-group {
		margin-bottom: 2rem;
		color: #fff;
		.offcanvas-favorites,
		.offcanvas-compare {
			a {
				display: block;
				color: rgba($offcanvas-color, 1);
				padding: 1.5rem 0;
				&:hover {
					color: rgba($offcanvas-color, 1);
				}
			}
			.opc-compare-count,
			.favorite-count {
				display: block;
				background: $brand-color-1;
				&::before,
				&::after {
					display: none;
				}
				position: absolute;
				right: 2rem;
				top: 50%;
				transform: translateY(-50%);
				width: 2.4rem;
				height: 2.4rem;
				font-size: 1.2rem;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;

				&:empty {
					display: none;
				}
			}

		}

		.offcanvas-lang {
			padding: 1.5rem;
			display: inline-block;

			.opc-language-selection {
				.language-selection-label {
					margin-bottom: 0.5rem;

					.current-selection {
						display: none;
					}
				}

				ul {
					position: static;
					display: flex;

					li {
						border: none !important;

						a {
							padding: 0.5rem 0;
							margin-right: 1rem;
						}

						&.is-active {

							a {
								opacity: 1;
								color: #fff;
								font-weight: bold;
							}
						}
					}

				}
			}
		}


	}

	.offcanvas-item {
		a {
			display: block;
			color: rgba($offcanvas-color, 1);
			padding: 1.5rem 0;
			&:hover {
				color: rgba($offcanvas-color, 1);
			}
		}
	}
	.service-menu {
		.opc-navigation {
			&:last-child {
				> ul {
					> li {
						border-bottom: 0 !important;
					}
				}
			}
		}
	}

	.opc-navigation {
		color: #fff;
		padding: 0 20px;
		a {
			color: rgba($offcanvas-color, 1);
			&:hover {
				color: rgba($offcanvas-color, 1);
				// background: rgba(#000, 0.1);
			}
			&.is-active {
				background: $brand-color-1;
			}
		}

		&[data-op-navtype="aside-click"] {
			.has-children {
				position: relative;
				.navigation-toggler {
					z-index: 2;
					top: 1rem;
					right: 0;
					width: 3rem;
					height: 3rem;
					position: absolute;
					display: block;
					background: #4B5F77;
					border-radius: 0.5rem;
					cursor: pointer;
					transition: all 0.2s ease-in-out;
					// background:  red;
					
					&::before {
						content: '';
						background: transparent;
						position: absolute;
						right: -2rem;
						top: -1.5rem;
						bottom: -1.5rem;
						left: -1.5rem;
					}

					&::after {
						content: '';
						position: absolute;
						right: 0.5rem;
						top: 0.5rem;
						width: 2rem;
						height: 2rem;
						display: block;
						background-image: inline-svg($op-icon-angle-down-svg, $white);
						background-position: center;
						background-size: 100%;
						background-repeat: no-repeat;
					}
				}
				&.is-open {
					>.navigation-toggler {
						transform: rotate(180deg);
					}
				}
				&.is-active {
					>.navigation-toggler {}
				}
			}

			.navigation-level-1 {
				>li {
					margin-bottom: 0;
					border-bottom: 1px solid $white;
					>a {
						padding: 1.5rem 0;
						margin-right: 12rem;
						font-weight: 400;
					}
					&.is-open {
						// background: rgba(#000, 0.1);
						>a {
							font-weight: 600;
							display: inline-block;
							text-decoration: underline;
							text-underline-offset: 15px;
							color: $white;
							padding-bottom: 2.5rem;
						}
						>.navigation-level-2 {
							display: block;
						}
					}
					&.is-active {
						>a {
							// color: $brand-color-1;
						}
					}
					&:last-child{
						// border-bottom: 0;
					}
				}
			}

			.navigation-level-2 {
				display: none;
				margin-left: 0;
				.navigation-toggler {
					top: 0;
				}
				>li {
					border-bottom: none;
					>a {
						padding: 12px 0;
					}
					&:last-child{
						>a{
							padding: 6px 0 12px;
						}
					}

					&.is-open {
						>a {
							font-weight: 600;
							display: inline-block;
							text-decoration: underline;
							text-underline-offset: 15px;
							color: $white;
							padding: 6px 0 15px 0;
							padding-bottom: 2.5rem;
						}

						>.navigation-level-3 {
							display: block;
						}
					}

					&.is-active {
						>a {
							color: $brand-color-1;
						}
					}
				}
			}

			.navigation-level-3 {
				display: none;
				margin-left: 0;

				>li {
					>a {
						padding: 12px 0;
					}
					&.is-open {
						>a {
							font-weight: 600;
							display: inline-block;
							text-decoration: underline;
							text-underline-offset: 15px;
							color: $white;
							padding-bottom: 2.5rem;
						}

						>.navigation-level-4 {
							display: block;
						}
					}

					&.is-active {
						>a {
							font-weight: bold;
							color: $brand-color-1;
						}
					}
				}
			}

			.navigation-level-4 {
				display: none;

				>li {
					>a {
						padding: 1.5rem 0;
					}

					&.is-open {
						>a {
							font-weight: bold;
							color: $offcanvas-color;
							padding: 1.5rem 0;
							padding-bottom: 2.5rem;
						}

						>.navigation-level-5 {
							display: block;
						}
					}

					&.is-active {
						>a {
							color: $brand-color-1;
						}
					}
				}
			}
		}
	}
}

.offcanvas-menu-wrapper .offcanvas-menu-head {
	.user-menu{
		display: none;
		position: relative;
		.offcanvas-group{
			position: relative;
			z-index: 2;
		}
		&::after{
			position: absolute;
			background-color: rgba($brand-color-1,.5);
			width: 5000px;
			left: -100%;
			top: 0;
			bottom: 0;
			z-index: 0;
			content: "";
			display: block;
		}
		.opc-navigation[data-op-navtype="aside-click"] .has-children .navigation-toggler {
			background: transparent;
		}
		.opc-navigation[data-op-navtype="aside-click"] .navigation-level-1>li {
			border-bottom: none;
		}
		.offcanvas-group{
			&:nth-of-type(1){
				margin-bottom: 0;
			}
			.logout-btn{
				padding-left: 2rem;
				a{
					color: $brand-color-2;
				}
				svg{
					color: $brand-color-2;
				}
			}
		}
		&.logged-in{
			&.is-open{
				display: block;
			}
		}
	}
	.offcanvas-action .opc-favorite-info>.opc-button .favorite-count{
		left: 14px;
		top: 7px;
		right: auto;
		width: 1.6rem;
    height: 1.6rem;
		line-height: 16px;
	}
}

.offcanvas-group.customizing{
	.offcanvas-item{
		padding: 0 2rem;
		&:nth-of-type(1){
			a{
				border-bottom: 1px solid $white;
			}
		}
	}
}
.viewport {

	@if $offcanvas-type=='side' {
		//transform: translateX(0);
		transition: all 0.2s ease-in-out;

		.offcanvas-menu-open & {
			// transform: translateX($offcanvas-width);
		}
	}

}