/*###############################################

@Title: Page Checkout
@Description:


###############################################*/

.page-checkout {
    .content-box {
        .content-box-head {
            padding: 2rem;
            position: relative; // title
            h2 {
                margin: 0;
                padding: 0;
                margin-bottom: 1rem;
                span {
                    font-size: 3rem;
                    font-size: inherit;
                    width: 3rem;
                    margin-right: 0;
                    display: inline-block;
                }
            } // toggler
            .content-box-signalisation {
                position: absolute;
                right: 2rem;
                top: 2rem; //width: 2rem;
                //height: 2rem;
                text-align: center;
                font-size: 2rem;
                line-height: 2rem;
                display: flex;
                align-items: center;
                svg {
                    margin-left: 2rem;
                    color: $success-color;
                }
                .opc-button {
                    opacity: 1;
                    visibility: visible;
                }
                svg {
                    opacity: 1;
                    transition: all 0.4s ease-in-out;
                }
            }
            .content-box-head-subline {
                padding: 0 0 0 3rem;
                opacity: 1; //transition: all 0.2s ease-in-out;
                span {
                    display: inline-block;
                    margin-right: 2rem;
                    margin-bottom: 0.5rem;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
        &.is-open {
            .content-box-head {
                padding-bottom: 0;
                @include mq($min-width: map-get($breakpoints, m)) {}
                .content-box-toggler {
                    transform: translateY(-50%) rotate(-180deg);
                }
                .content-box-head-subline {
                    opacity: 0;
                    visibility: hidden;
                    height: 0;
                }
                .content-box-signalisation {
                    .opc-button {
                        opacity: 0;
                        visibility: hidden;
                    }
                    svg {
                        opacity: 0;
                    }
                }
            }
        }
        &.is-open {
            .content-box-body {
                display: block;
            }
        }
        .content-box-body {
            display: none;
        }
        &[data-op-isdone="true"] {
            .content-box-signalisation {
                visibility: visible;
                opacity: 1;
            }
        }
        &[data-op-isdone="false"] {
            .content-box-signalisation {
                .opc-button {
                    visibility: hidden;
                    opacity: 0;
                }
                .op-icon-check {
                    color: #ddd;
                }
            }
            &.is-open {
                .content-box-signalisation {
                    visibility: hidden;
                    opacity: 0;
                }
            }
        }
        &[data-op-type="summary"] {
            .opc-button {
                &[data-op-isok="false"] {
                    pointer-events: none;
                    opacity: 0.25;
                }
            }
        }
        .content-box-body {
            .guest-wrapper {
                display: flex;
                margin-left: -2rem;
                margin-right: -2rem;
                flex-wrap: wrap;

                @include mq($min-width: map-get($breakpoints, m)) {
                    flex-wrap: nowrap;
                }

                .guest-login-data, .guest-login-advantages {
                    flex-grow: 1;
                    width: 100%;
                    flex-basis: 100%;
                    padding: 2rem;

                    @include mq($min-width: map-get($breakpoints, m)) {
                        width: 50%;
                        flex-basis: 50%;
                    }
                    .opc-col {
                        margin-bottom: 0;
                    }
                }
                .opc-radio {
                    label {
                        background: $lightgray;
                        color: rgba(0,0,0,0.8);
                    }
                    input:checked+label {
                        background:$brand-color-1;
                        color: rgba(255,255,255,0.8);
                    }
                }
            }
        }
    }
    .checkout-addresses {
        @extend %opc-row;
        display: flex;
        flex-wrap: wrap;
        .shipping-address {
            padding: $_opc-grid-gutter-width;
            width: 100%;
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 50%;
            }
        }
        .billing-address {
            padding: $_opc-grid-gutter-width;
            width: 100%;
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 50%;
            }
        }
        #address-form {
            display: none;
            padding: 1rem;
            border: 1px solid #eee;
            .opc-col {
                margin-bottom: 0;
            }
        }
        #change-guest-address {
            margin-bottom: 1rem;
        }
        #checkout-form-guest-wrapper {
            display: none;

            #checkout-form-guest {
                flex-wrap: wrap;
                display: flex;

                @include mq($min-width: map-get($breakpoints, 's')) {
                    width: calc(100% + 2rem);
                    margin-left: -1rem;
                    margin-right: -1rem;
                }

                .opc-form-field {
                    width: 100%;

                    @include mq($min-width: map-get($breakpoints, 's')) {
                        width: calc(50% - 2rem);
                        margin-right: 1rem;
                        margin-left: 1rem;
                    }

                    @include mq($min-width: map-get($breakpoints, 'm')) {
                        width: calc(100% + 2rem);
                    }
                    @include mq($min-width: map-get($breakpoints, 'l')) {
                        width: calc(50% - 2rem);
                        margin-right: 1rem;
                        margin-left: 1rem;
                    }
                }
                h3 {
                    width: 100%;
                    @include mq($min-width: map-get($breakpoints, 's')) { padding-left: 1rem; }
                }
                .opc-button {
                    @include mq($min-width: map-get($breakpoints, 's')) {  margin-left: 1rem; }
                }

                .form-footer .opc-form-field {
                    width: 100%;
                }
                button[type="submit"] {
                    margin-bottom: 1rem;
                }
            }
        }
    }
    .delivery-selection {
        .opc-radio {
            b {}
            span {
                display: inline-block;
                display: block;
            }
            a {
                display: block;
                margin-top: 0.5rem;
                color: blue;
                text-decoration: underline;
            }
            .shipping-price {
                //position: absolute;
                //right: 2rem;
                //top: 1.5rem;
                display: inline-block;
                margin-left: 1rem;
                color: orange;
                font-weight: bold; //float: right;
                position: absolute;
                right: 1rem;
                top: 1rem;
                span {
                    display: inline-block;
                    margin-top: 0.5rem;
                }
            }
        }
    }
    .paymenticons {
        display: flex;
        align-items: flex-start;
        max-width: 80rem;
        img {
            width: 13%;
            @include mq($min-width: map-get($breakpoints, m)) {
                width: 6%;
            }
        }
    }
    .payment-selection {
        .payment-text {
            font-weight: bold;
        }
        .payment-info {
            display: block;
        }
        position: relative;
        .bonicheck-info{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: rgba($brand-color-1, .9);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            color: $white;
            font-size: 1.8rem;
            .dots {
                width: 5.8px;
                height: 5.8px;
                background: $white;
                color: $white;
                border-radius: 50%;
                box-shadow: 9.6px 0,-9.6px 0;
                animation: dots-animation 1.4s infinite linear alternate;
             }
        }
    }
    .checkout-voucher {
        display: flex;
        flex-wrap: wrap;
        .opc-form-field {
            margin-bottom: 0;
            width: 100%;
            @include mq($min-width: map-get($breakpoints, m)) {
                width: auto;
            }
            .opc-input {
                width: 100%;
            }
        }
                button {
                    align-self: flex-end;
                }
        }
}



 
 @keyframes dots-animation {
    0% {
       box-shadow: 9.6px 0,-9.6px 0;
    }
 
    33% {
       box-shadow: 9.6px 0,-9.6px 0 rgba(71,75,255,0.13);
       background: rgba(71,75,255,0.13);
    }
 
    66% {
       box-shadow: 9.6px 0 rgba(71,75,255,0.13),-9.6px 0;
       background: rgba(71,75,255,0.13);
    }
 }