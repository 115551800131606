/*###############################################

@Title: Price
@Description:
This file contains the price styles

###############################################*/

$_opc-price-color: $text-color;
$_opc-price-color-line-through: #979899;
$_opc-price-color-red: #BE3A24;
$_opc-price-currency-position : "before"; // set position of currency. after or before. default is after
.opc-price {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -2px;
    .price-label {
        margin-right: 0.5rem;
        display: none;
    }
    .price-basis,
    .price-service,
    .price-promotion {
        display: flex;
        font-size: 1.8rem;
        color: $_opc-price-color;
        font-weight: bold;
        margin-right: 1rem;
        align-items: flex-end; // set alignment of elements (in this case all items are aligned to the bottom)
        .prod-pre-info {
            margin-right: 0.4rem;
        }
        .price-currency {
            text-transform: uppercase;
            order: 1;
            margin-right: 0.4rem;
            font-weight: normal;
            @if $_opc-price-currency-position=="after" {
                order: 2;
            }
        }
        .price {
            order: 2;
            margin-right: 0.4rem;
            @if $_opc-price-currency-position=="after" {
                order: 1;
            }
        }
    }
    .price-info {
        order: 5;
        display: none;
    } 
    
    // when promo price is active
    &.is-promotion {
        .price-basis,
        .price-service {
            font-size: 1.4rem;
            text-decoration: line-through;
            color: $_opc-price-color-line-through;
            order: 2;
            font-size: 1.4rem;
        }
        .price-service {
            display: none;
        }
        .price-promotion {
            order: 1;
        }
    } 
    
    // when service price is active
    &.is-service {
        .price-basis,
        .price-promotion {
            text-decoration: line-through;
            color: $_opc-price-color-line-through;
            order: 1;
            font-size: 1.4rem;
            font-weight: normal;
            display: flex;
        }
        .price-promotion {
            display: none;
        }
        .price-service {
            order: 2;
        }
    }
    &.pricediff {
        .price-service {
            color:$_opc-price-color-red;
        }
    }
    &.is-service.is-promotion {
        .price-basis {
            display: none;
        }
        .price-service {
            text-decoration: line-through;
            color: $_opc-price-color-line-through;
            order: 2;
            font-size: 1.4rem;
            font-weight: normal;
        }
        .price-promotion {
            order: 1;
        }
    } 
    
    // when basis price is active
    &.is-basis {
        .price-service,
        .price-promotion {
            display: none;
        }
    }
    &.is-basis.is-promo {
        .price-promotion {
            color:$_opc-price-color-red;
            order:2 !important;
        }
    }
}

.stock-price-group{
    position: relative;
    .opc-price{
        // Workaround fuer "Statt"-Preis
        // when promo price is active
        &.is-promotion {
            .price-basis,
            .price-service {
                text-decoration: line-through;
                color: $_opc-price-color-line-through;
                // position: absolute;
                top: -1.4rem;
                left: 0;
                // [data-content-lang="1"] & {
                //     &::before{
                //         content: "Statt \0020";
                //         font-weight: normal;
                //         white-space: pre;
                //     }
                // }
            }
        }
        &.is-service {
            .price-basis,
            .price-promotion {
                text-decoration: line-through;
                color: $_opc-price-color-line-through;
                // position: absolute;
                top: -1.4rem;
                left: 0;
                // [data-content-lang="1"] & {
                //     &::before{
                //         content: "Statt \0020";
                //         font-weight: normal;
                //         white-space: pre;
                //     }
                // }
            }
         }
        &.is-service.is-promotion {
            .price-service {
                text-decoration: line-through;
                color: $_opc-price-color-line-through;
                // position: absolute;
                top: -1.4rem;
                left: 0;
                // [data-content-lang="1"] & {
                //     &::before{
                //         content: "Statt \0020";
                //         font-weight: normal;
                //         white-space: pre;
                //     }
                // }
            }
        }
    }
    .set-occ-price {
        .opc-price { 
            &.is-basis.is-promo {
                .price-basis {
                    text-decoration: line-through;
                    color: $_opc-price-color-line-through;
                    // position: absolute;
                    font-weight: normal;
                    top: -1.4rem;
                    left: 0;
                    // [data-content-lang="1"] & {
                        // &::before{
                        //     content: "Statt \0020";
                        //     font-weight: normal;
                        //     white-space: pre;
                        // }
                    // }
                }
                .price-service {
                    display: flex;
                    // font-size: 1.6rem;
                    color: $_opc-price-color-red;
                    font-weight: bold;
                    margin-right: 1rem;
                    order:2;
                }
            }
        }
        .price-service {
            display: flex;
        }
    }
    [data-op-article-list-type="list"] & {
        .opc-price{
            &.is-promotion {
                .price-basis,
                .price-service {
                    top: -2.2rem;
                    font-size: 1.8rem;
                }
            }
            &.is-service {
                .price-basis,
                .price-promotion {
                    top: -2.2rem;
                    font-size: 1.8rem;
                }
            }
            &.is-service.is-promotion {
                .price-service {
                    top: -2.2rem;
                    font-size: 1.8rem;
                }
            }
        }
        .set-occ-price {
            .opc-price { 
                &.is-promo {
                    .price-basis,
                    .price-service {
                        top: -2.2rem;
                        font-size: 1.8rem;
                    }
                }
                &.is-service {
                    .price-basis,
                    .price-promotion {
                        top: -2.2rem;
                        font-size: 1.8rem;
                    }
                }
                &.is-service.is-promotion {
                    .price-service {
                        top: -2.2rem;
                        font-size: 1.8rem;
                    }
                }
            }
        }    
        
    }
}
.detailpage-price{
    .opc-price{
        // Workaround fuer "Statt"-Preis
        // when promo price is active
        &.is-promotion {
            .price-basis,
            .price-service {
                text-decoration: line-through;
                font-size: 1.8rem!important;
                [data-content-lang="1"] & {
                    &::before{
                        content: "Statt \0020";
                        font-weight: normal;
                        white-space: pre;
                        margin-left: 1rem;
                    }
                }
            }
        }
        &.is-service {
            .price-basis,
            .price-promotion {
                text-decoration: line-through;
                // font-size: 1.8rem!important;
                // [data-content-lang="1"] & {
                //     &::before{
                //         content: "Statt \0020";
                //         font-weight: normal;
                //         white-space: pre;
                //         margin-left: 1rem;
                //     }
                // }
            }
        }
        &.is-service.is-promotion {
            .price-service {
                text-decoration: line-through;
                // font-size: 1.8rem!important;
                // [data-content-lang="1"] & {
                //     &::before{
                //         content: "Statt \0020";
                //         font-weight: normal;
                //         white-space: pre;
                //         margin-left: 1rem;
                //     }
                // }
            }
        }
    }
}

[data-op-article-list-type="tile"]{
    .opc-price {
        .price-basis,
        .price-service,
        .price-promotion {
            font-size: 1.4rem;
            min-width: 100%;
            @include mq($min-width: map-get($breakpoints, l)) { 
                font-size: 1.6rem;
             }
        }
    }
}