/*###############################################

@Title: Theme 
@Description:
This file contains all theme relevant files

###############################################*/

// import theme specific node modules or external stuff
// @import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,600i,700,800";

@font-face {
  font-family: Playfair; /* regular font */
  // src: url("/webportal/config/layout/themes/project/fonts/Geogtq-Rg.otf") format("opentype");
  src: url('/webportal/config/layout/themes/project/fonts/Playfair/PlayfairDisplay-Regular.ttf') format('truetype');
  font-weight: normal; font-style: normal; }
@font-face {
  font-family: Geogrotesque; /* regular font */
  // src: url("/webportal/config/layout/themes/project/fonts/Geogtq-Rg.otf") format("opentype");
  src: url('/webportal/config/layout/themes/project/fonts/Geogrotesque-Rg/font.woff2') format('woff2'), url('/webportal/config/layout/themes/project/fonts/Geogrotesque-Rg/font.woff') format('woff');
  font-weight: normal; font-style: normal; }
@font-face {
  font-family: Geogrotesque; /* bold font */
  // src: url("/webportal/config/layout/themes/project/fonts/Geogtq-Md.otf") format("opentype");
  src: url('/webportal/config/layout/themes/project/fonts/Geogrotesque-SmBd/font.woff2') format('woff2'), url('/webportal/config/layout/themes/project/fonts/Geogrotesque-Md/font.woff') format('woff');
  font-weight: bold; font-style: normal; }
@font-face {
  font-family: Geogrotesque; /* bolder font */
  // src: url("/webportal/config/layout/themes/project/fonts/Geogtq-Bd.otf") format("opentype");
  src: url('/webportal/config/layout/themes/project/fonts/Geogrotesque-Bd/font.woff2') format('woff2'), url('/webportal/config/layout/themes/project/fonts/Geogrotesque-Bd/font.woff') format('woff');
  font-weight: 900; font-style: normal; }

//@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";

// import theme overwrites
@import "atoms/atoms";
@import "organisms/organisms";

@import "other/burger";
@import "other/tiny-slider";

@import "structure/grid";
@import "structure/topbar";
@import "structure/header";
@import "structure/mainnav";
@import "structure/offcanvasnav";
@import "structure/content";
@import "structure/footer";
@import "structure/section";


// page specific styles
@import "pages/checkout";
@import "pages/contact";
@import "pages/details";
@import "pages/register-login";
@import "pages/favorites";
@import "pages/compare";
@import "pages/basket";
@import "pages/account";
@import "pages/error";
@import "pages/offline";
@import "pages/start";
@import "pages/notsupported";
@import "pages/cms";
@import "pages/toolbar";

* {
	box-sizing: border-box;
}

.hidden{
  display: none!important;
}

.opc-favorite-button {
  opacity: 1;
}
body{
  color: $text-color;
}