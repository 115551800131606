/*
@Title: Project File
@Description:
This file defines which sass files are necessary for the project.
There are 4 Import Types: 1. Node Modules, 2. Theme Config File, 3. Core Files, 4. Theme Files.
Please keep in mind, that the order is IMPORTANT and cannot be changed!!!!!!
*/

/*
 Import external resources
 Here you can insert all external resources (node modules, vendor stuff, etc.) for the project which are using sass or css
 ---------------------------------------------------- */
 @import "./webportal/config/layout/themes/default/vendor/normalize.css/normalize";
 @import "./webportal/config/layout/themes/default/lib/notifications/opacc.lib.notifications";
 @import "./webportal/config/layout/themes/default/lib/tooltip/opacc.lib.tooltip";
 @import "./webportal/config/layout/themes/default/lib/daterangepicker/opacc.lib.daterangepicker";
 @import "./webportal/config/layout/themes/default/vendor/susy/sass/susy";
 @import "./webportal/config/layout/themes/default/vendor/sass-mediaqueries/media-queries";
 @import "./webportal/config/layout/themes/default/style/mixins";

 
 /*
  Import Theme Config File
  Here you have to link to the config file of the theme you wanna use.
  ---------------------------------------------------- */
 @import "themes/project/scss/config";
 
 /*
  Import the Core
  Here we import the core. Normally you should not touch this...
  ---------------------------------------------------- */
 @import "./webportal/config/layout/themes/default/style/core";    
 /*
  Import Theme Styles
  Here you have to link to the theme file.
  ---------------------------------------------------- */
 @import "themes/project/scss/theme";

 
 .session-timeout-message-active{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .session-timeout-message{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba($brand-color-1,.8);
    color:#fff;
    span{
      display: block;
      text-align: center;
    }
    .opc-button{
     &::before{
       content: "";
       width: 4rem;
       height: 4rem;
       display: block;
      //  background: url(/webportal/config/layout/themes/project/assets/svg/src/op-icon-refresh.svg) no-repeat center center;
       background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"> <g id="surface1"> <path style=" " d="M 16 4 C 10.886719 4 6.617188 7.160156 4.875 11.625 L 6.71875 12.375 C 8.175781 8.640625 11.710938 6 16 6 C 19.242188 6 22.132813 7.589844 23.9375 10 L 20 10 L 20 12 L 27 12 L 27 5 L 25 5 L 25 8.09375 C 22.808594 5.582031 19.570313 4 16 4 Z M 25.28125 19.625 C 23.824219 23.359375 20.289063 26 16 26 C 12.722656 26 9.84375 24.386719 8.03125 22 L 12 22 L 12 20 L 5 20 L 5 27 L 7 27 L 7 23.90625 C 9.1875 26.386719 12.394531 28 16 28 C 21.113281 28 25.382813 24.839844 27.125 20.375 Z "/> </g> </svg>', #fff);
       background-repeat: no-repeat;
       background-position: 0rem 0.5rem;
     }
    }
  }
}

// Footer �ffnungszeiten-Styling
#staticcontent-2467710-9111 {
  table {
    border: none;
      tbody {
          tr {
              border:none;
              td {
                border:none;
                padding: 0;
              }
            }
          }
        }
        a {
          color:#fff;
          font-weight: bold;
          text-decoration: underline;
        }
}
@import "themes/default/lib/rangeslider/_opacc.lib.rangeslider";
