/*###############################################

@Title: Page Basket
@Description:


###############################################*/

.basket-list {
    //padding: 2rem 0;
    #freeshipwrapper {
        &.opc-message[data-op-type="info"] {
        background: $brand-color-1;
        color: $white;
        display: inline-block;
        border:0;
        margin-top: 0;
            @include mq($min-width: map-get($breakpoints, m)) {
                margin-top:4rem;
            }
        }
    }

    .basket-list-head {
        //padding: 2rem;
        display: none;
        border: 1px solid transparent;
        @include mq($min-width: map-get($breakpoints, m)) {
            display: flex;
        }
        >div {
            border-right: none;
            font-weight: bold;
        }
    }
    .basket-list-body {
        list-style: none;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        .basket-list-item {
            display: flex;
            flex-direction: column;
            border: 1px solid #ccc;
            margin-bottom: 1rem;
            @include mq($min-width: map-get($breakpoints, m)) {
                //display: flex;
                flex-direction: row;
                margin-bottom: -1px;
                margin-bottom: 1rem;
            }
            .opc-button[data-op-btnaction="delete"] {
                padding: 0;
                background: transparent;
                color: crimson;
                padding: 0;
                height: auto;
                margin-bottom: 1rem;
                font-size: 1.2rem;
                svg {
                    display: none;
                }
                &.is-progress {
                    color: #ccc;
                }
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        .basket-list-item-promocode {
            border: 1px solid #ccc;
            padding: 2rem;
            margin-bottom: 1rem;
            h3,
            p,
            .opc-form-field {
                max-width: 50rem;
            }
        }
    }
    .basket-list-footer {
        background: #eee;
        padding: 2rem 1rem;
        display: flex; //justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        margin-bottom: 2rem;
        .basket-list-summary-line {
            margin-bottom: 1rem;
            span {
                display: inline-block;
                width: 14rem;
                text-align: right;
            }
        }
    }
    .basket-bottom-line {
        display: flex;
        justify-content: flex-end;
    }
    .basket-list-item-description,
    .basket-list-item-options,
    .basket-list-item-price,
    .basket-list-item-total {
        padding: 0.5rem 1rem;
        @include mq($min-width: map-get($breakpoints, s)) {
            padding: 1rem;
        }
    }
    .basket-list-item-description {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-bottom: 0;
        @include mq($min-width: map-get($breakpoints, m)) {
            width: 40%;
            flex-grow: 1; //border-right: 1px solid #ccc;    
            flex-wrap: nowrap;
            padding-bottom: 1rem;
        }
        .item-description-image {
            width: 8rem;
            padding: 1rem 2rem 0 0;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: flex-start;
            img {
                width: auto;
                max-width: 6rem;
                max-height: inherit;
                max-height: 8rem;
            }
            @include mq($min-width: map-get($breakpoints, s)) {
                width: 14rem;
                img {
                    align-items: center; //max-width: 100%;
                    width: auto;
                    max-width: 10rem;
                    height: inherit;
                    max-height: 10rem;
                }
            }
        }
        .item-description-data {
            padding: 1rem 0;
            width: calc(100% - 8rem);
            @include mq($min-width: map-get($breakpoints, s)) {
                width: calc(100% - 14rem);
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                padding: 0;
            }
            h3 {
                margin-bottom: 0.4em;
                font-size: 1.4rem;
                font-weight: bold;
                a {
                    color: #000;
                    &:hover {
                        color: lighten(#000, 20%);
                    }
                }
            }
        }
        .item-description-article-nr {
            display: block;
            margin-bottom: 1rem;
        }
        .item-description-actions {
            button {
                //text-decoration: underline;
                padding: 0;
                height: auto;
                opacity: 0.6;
                &:hover {
                    opacity: 1;
                    color: $error-color;
                }
            }
        }
    }
    .basket-list-item-options {
        .opc-form-field-group {
            margin-bottom: 1rem;
        }
        .opc-basket-button {
            margin-bottom: 1rem;
        }
        .opc-stock {
            margin-bottom: 1rem;
        }
        margin-left: 8rem;
        padding-top: 0;
        @include mq($min-width: map-get($breakpoints, s)) {
            margin-left: 14rem;
        }
        @include mq($min-width: map-get($breakpoints, m)) {
            width: 18rem;
            margin-left: 0;
            padding-top: 1rem; //border-right: 1px solid #ccc;
        }
    }
    .basket-list-item-price {
        //width: 100%;
        text-align: left;
        margin-left: 8rem;
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        justify-content: space-between;
        .srebate {
            display: none;
        }
        @include mq($min-width: map-get($breakpoints, s)) {
            margin-left: 14rem;
            padding-top: 0;
            padding-bottom: 0.5rem;
        }
        @include mq($min-width: map-get($breakpoints, m)) {
            width: 12rem;
            text-align: right;
            margin-left: 0; //border-right: 1px solid #ccc;
            padding-top: 1rem;
            padding-bottom: 1rem;
            display: block;
            .srebate {
                display: block;
                padding:0;
            }
        }
    }
    .srebate {
        font-size: 13px;
        display: block;
        text-align: right;
        padding-right: 1rem;
        @include mq($min-width: map-get($breakpoints, m)) {
            display: none;
        }
    }
    .basket-list-item-total {
        //width: 100%;
        text-align: left;
        margin-left: 8rem;
        padding-top: 0;
        padding-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        @include mq($min-width: map-get($breakpoints, s)) {
            margin-left: 14rem;
            padding-top: 0;
            padding-bottom: 1rem;
        }
        @include mq($min-width: map-get($breakpoints, m)) {
            width: 14rem;
            text-align: right; //border-right: 1px solid #ccc;
            font-weight: bold;
            margin-left: 0;
            padding-top: 1rem;
            padding-bottom: 1rem;
            display: block;
        }
    }
    .responsive-label {
        display: inline-block;
        margin-right: 0.4rem;
        font-weight: bold;
        min-width: 40%;
        @include mq($min-width: map-get($breakpoints, m)) {
            width: 14rem;
            display: none; //border-right: 1px solid #ccc;
        }
    }
}