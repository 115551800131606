.opc-breadcrumb {
    // display: none;
    display: flex;
    font-size: 1.2rem;
    margin-top: -2rem;
    @include mq($min-width: map-get($breakpoints, l)) {
        font-size: 1.6rem;
        // display: flex;
    }

    .breadcrumb-pretext{
        display: none;
    }

    .breadcrumb-navigation{
        li{
            margin-right: .5rem;
            a{
                color: $brand-color-2;
            }
            &::after{
                // content: "\00BB";
                color: $brand-color-2;
                content: "|";
                background: none;
                margin-left: .5rem;
                width: unset;
                height: unset;
                vertical-align: baseline;
            }
            &.is-active{
                color: $brand-color-2;
            }
        }
    }
} 
