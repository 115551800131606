.opc-article-list{
    >li {
        flex-basis: calc(100% / 2);
        max-width: calc(100% / 2);
    }
    &[data-op-article-list-type="tile"] {
        >li{
            .image-wrapper{
                overflow: hidden;
                // margin-bottom: 2rem;
                .article-list-item-image{
                    transition: all .3s ease-in;
                    margin-bottom: 0;
                    img{
                    }
                }
            }
            &:hover{
                .image-wrapper{
                    .article-list-item-image{
                        transform: scale(1.05);
                        img{
                        }
                    }
                }
            }
        }
    }
    &[data-op-article-list-type="list"] {
        >li{
            margin-top: 1.2rem;
            margin-bottom: 1.2rem;
            transition: all .3s ease-in;
            border-radius: 4px;
            &:hover{
                box-shadow: 0 0 40px rgba($black, .2);
            }
        }
    }
    &[data-mh-type="noten"][data-op-tile-col="4"] {
        flex-wrap: nowrap;
        overflow-x: auto;
        @include mq($min-width: map-get($breakpoints, l)) {
            display: block;
            flex-wrap: wrap;
            overflow-x: none;
        }
        >li {
            flex-basis:45%;
            max-width: 45%;
            flex-shrink: 0;
            @include mq($min-width: map-get($breakpoints, s)) {
                flex-basis: span(7);
                max-width: span(7);
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                flex-basis: span(4);
                max-width: span(4);
            }
            @include mq($min-width: map-get($breakpoints, l)) {
                flex-basis: span(3);
                max-width: span(3);
            }
            @include mq($min-width: map-get($breakpoints, xl)) {}
        }
    }
}


.article-list-item {
    border: none;
    padding: 0;
    background-color: transparent;
    position: relative;
    .opc-favorite-button {
        display: none;
        box-shadow: none;
        background-color: transparent;
        padding: 0;
        width: 38px;
        height: 34px;
        border: none;
        opacity: .4;
        &:focus,
        &:hover{
            background-color: transparent;
        }
        &.is-active{
            &::after{
                content: "";
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background-image: inline-svg($op-icon-fav-active-svg, $red);
                background-position: center;
                background-size: 22px;
                background-repeat: no-repeat;
            }
        }
        svg{
            margin-right: 0;
            color: $red;
        }
        span{
            display: none;
        }
    }
    .image-wrapper{
        >.opc-favorite-button {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
        }
    }
    .image-wrapper{
        position: relative;
        max-height: 100%;
    }

    .article-list-item-image{
        // background-color: #EBEBEB;
        // Hoehe/Breite proportional. Wird erreicht durch Hoehe "0" und padding-bottom "100%"
        height: 0;
        position: relative;
        width: 100%;
        padding-bottom: 100%;
        margin-bottom: 0;
        img{
            // IMG muss durch Hack Hoehe/Breite als position "absolute" eingebaut werden
            position: absolute;
            left: 0;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
            max-width: 100%;
            max-height: calc(100% - 2rem);
            // mix-blend-mode: multiply;
            // margin-bottom: 2rem;
        }
    }
    .article-list-item-flags{
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
        top: auto;
        z-index: 2;
        &:empty{
            + .article-list-item-image{
                img{
                    max-height: 100%;
                    margin-bottom: 0;
                }
            }
        }
    }
    .article-list-item-variant{
        min-width: 100%;
    }
    .article-list-item-details {
        // min-height: 6.6rem;
        // margin-top: 2rem;
        .opc-price {
            min-height: 2.4rem;
            margin-bottom: 0.5rem;
        }
        .article-list-item-title {
            // @extend .h4;
            font-weight: 400;
            // margin-bottom: 0.5rem;
            min-height: auto;
            overflow: hidden;
            a{
                span.tile-view{
                    display: block;
                    font-size: 1.4rem;
                    margin-bottom: .5rem;
                    // min-height: 6rem;
                    margin-top: 1.2rem;
                    >span{
                        display: block;
                        text-overflow: ellipsis;
                        overflow: hidden; 
                        white-space: nowrap;
                    }
                }
                span.list-view{
                    display: none;
                }
                @include mq($min-width: map-get($breakpoints, l)) {
                    span.tile-view{
                        font-size: 1.6rem;
                    }
                }
                
            }
        }
        .article-list-item-description {
            //height: 3.4rem;
            overflow: hidden;
            p,
            .is-product-msg {
                height: 4.4rem;
                font-size: 1.4rem;
                overflow: hidden;
                margin-bottom: 0;
                padding: 0.5rem 0;
                line-height: 1.3;
                &:empty {
                    display: none;
                }
            }
            .is-product-msg {
                background: #eee;
                display: block;
                padding: 0.5rem 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                border-radius: 1rem;
                font-size: 1.2rem;
                max-width: 60rem;
            }
        }   
    }
    .article-specs{
        display: none;
    }
    // styling action buttons
    .article-list-item-actions {
        overflow: visible;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        color: #000;
        margin-left: auto;
        align-self: flex-end;

        .details-button {
            margin-left: auto;
            margin-bottom: 0.5rem;
        }

        .opc-favorite-button { 
            background-color: #fff;

            &:hover,
            &:focus {
                box-shadow: 1px 3px 3px #ccc;
            }
        }
        .opc-compare-button { 
            background-color: #fff;
            margin-left: 1rem;
            &:hover,
            &:focus {
                box-shadow: 1px 3px 3px #ccc;
            }
        }
        .opc-basket-button {
            width: 3.6rem;
            height: auto;
            border: 0;
            font-size: 1.6rem;
            padding: 0;
            padding-bottom: .2rem;
            margin-left: auto;
            border-radius: 50px;
            background-color: transparent;
            svg {
                margin-right: 0;
                color: $brand-color-2;
                font-size: 1.8rem;
            }
            span {
                display: none;
            }
            @include mq($min-width: map-get($breakpoints, l)) {
                // padding-top: .65rem;
                svg {
                    font-size: 2.4rem;
                }
            }
            &:hover,
            &:focus {
                box-shadow: none;
            }
        }
        .details-button {
            width: auto;
            @include mq($min-width: map-get($breakpoints, m)) { width: auto; }
        }
    }

    .stock {
        font-size: 1.3rem;
        font-weight: normal;
        margin-bottom: 4px;
        .icon {
            width: 12px;
            height: 12px;
            margin-right: 5px;
            padding-bottom: .1rem;
            // @include mq($min-width: map-get($breakpoints, m)) {
            //     width: 12px;
            //     height: 12px;
            //     margin-right: 10px;
            // }
            display: block;
            border-radius: 5rem;
            flex-shrink: 0;
            &.lot {
                background: $stock-on-stock;
            }
            &.medium,
            &.none {
                background: $stock-out-stock;
            }
        }
        .value {
            color: $stock-on-stock;
            display: none;
        }
    }
    .article-list-item-bottom{
        display: flex;
        justify-content: space-between;
        margin-bottom: 4.8rem;
        flex-wrap: wrap;
        .stock-price-group{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
        .stock-price-wrapper {
            display: flex;
            align-items: normal;
            flex-grow: 1;
                &.occ {
                    align-items: flex-end;
                    .stock-price-group {
                        .stock {
                            margin-bottom: .4rem;
                        }
                    }
                }
            > .article-list-item-variant {
                display: none;
            }
        }
    }
    &:hover{
        border: none;
    }

    [data-op-article-list-type="list"] & {
        .image-wrapper{
            padding: 5px 0;
            display: flex;
            justify-content: center;
            height: 100%;
            >.opc-favorite-button {
                display: none;
            }
        }
        // @include mq($min-width: map-get($breakpoints, l)) {
        //     .image-wrapper{
        //         width: 18rem;
        //         >.opc-favorite-button {
        //             display: block;
        //         }
        //     }
        //     .article-list-item-actions{
        //         >.opc-favorite-button {
        //             display: none!important;
        //         }
        //     }
        // }
        // .article-list-item{
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            // height: 100%;
            // max-height: 19rem;
            @include mq($min-width: map-get($breakpoints, s)) {
                height: 19rem;
            }
            .article-list-item-details {
                width: 100%;
                .article-list-item-title {
                    font-size: 1.8rem;
                    font-weight: 400;
                    margin-bottom: 0.5rem;
                    height: auto;
                    overflow: hidden;
                    a{
                        span.tile-view{
                            display: none;
                        }
                        span.list-view{
                            display: inline;
                        }
                    }
                }
            }
            .article-specs{
                display: block;
                flex-basis: 100%;
                margin-bottom: 3rem;
                @include mq($min-width: map-get($breakpoints, s)) {
                    // max-width: 40rem;
                    margin-bottom: 0;
                }
                font-size: 1.8rem;
                b{
                    font-weight: bold;
                }
            }
            .article-list-item-actions {
                overflow: visible;
                display: flex;
                flex-wrap: nowrap;
                justify-content: flex-end;
                color: #000;
                .details-button {
                    margin-left: auto;
                    margin-bottom: 0.5rem;
                }
                .opc-favorite-button { 
                    display: block;
                    width: 4rem;
                    position: relative;
                    height: 3rem;
                    margin-bottom: 0;
                    &:hover{
                        box-shadow: none!important;
                    }
                }
                .opc-more-button {
                    margin-left: 0;
                    margin-right: 0.5rem;
                    color: $brand-color-1;
                }
                .opc-compare-button { 
                    background-color: #fff;
                    margin-left: 0;
                    margin-right: 0.5rem;
                    &:hover,
                    &:focus {
                        box-shadow: 1px 3px 3px #ccc;
                    }
                }
                .opc-basket-button {
                    width: 4rem;
                    height: 3rem;
                    padding: 0;
                    justify-content: center;
                    margin-bottom: 0;
                    svg {
                        margin-right: 0;
                    }
                    span {
                        display: none;
                    }
                    @include mq($min-width: map-get($breakpoints, s)) {
                    }
                    &:hover,
                    &:focus {
                        box-shadow: none!important;
                    }
                }
                .details-button {
                    width: 100%;
                    @include mq($min-width: map-get($breakpoints, m)) { width: auto; }
                }
            }
            .article-list-item-flags{
                top: auto;
                .opc-flags{
                    flex-direction: column;
                }
                // .opc-flags{
                    //         flex-direction: row;
                    //     }
                    @include mq($min-width: map-get($breakpoints, s)) {
                        width: 18rem;
                        position: absolute;
                        bottom: 0;
                        top: auto;
                }
            }
            .article-list-item-variant{
                display: none;
            }
            .article-list-item-image{
                height: auto;
                width: auto;
                padding-bottom: 20rem;
                margin-right: 0;
                display: block;
                width: 18rem;
                @include mq($min-width: map-get($breakpoints, s)) {
                    width: 10rem;
                    flex: 0 0 100%;
                    // width: auto!important;
                    height: 100%;
                    padding-bottom: 0;
                    width: 18rem;
                    display: flex;
                    img{
                        position: static;
                        // max-width: 100%;
                        transform: none;
                    }
                }
            }
            .article-list-item-details,
            .article-list-item-bottom{
                @include mq($min-width: map-get($breakpoints, s)) {
                    // flex: 0 0 50%;
                    width: calc(100% - 18rem);
                    padding-top: 2.4rem;
                    height: auto;
                    min-width: unset;
                    padding-left: 2rem;
                }
            }
            .article-list-item-bottom{
                align-items: flex-end;
                flex-wrap: wrap;
                margin-bottom: 0;
                @include mq($min-width: map-get($breakpoints, s)) {
                    flex-wrap: nowrap;
                }
                .stock-price-wrapper {
                    align-items: baseline;
                    .article-list-item-variant {
                        display: flex;
                        min-width: auto;
                        margin-left: auto;
                    }
                }
            }
        // }
    }
}

.article-list-paging,
.result-view-paging {
    margin-top: 7rem;
    .opc-segment-navigation{
        >button.opc-button{
            padding-left: 6rem;
            padding-right: 6rem;
            font-size: 2.4rem;
            background-color: $_opc-button-loud-bg;
            color: $_opc-button-loud-color;
            &:hover{
                background-color: $_opc-button-loud-bg-hover;
                color: $_opc-button-loud-color-hover;
            }
        }
    }
}



$_opc-article-list-padding : 1.5rem !default;

.article-list-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mq($min-width: map-get($breakpoints, m)) {
        margin-bottom: 6rem;
    }
    .article-list-count {
        flex-grow: 1;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        color: $brand-color-1;
        font-size: 1.6rem;
        @include mq($min-width: map-get($breakpoints, s)) {
            width: auto;
        }
    }
    .article-list-sort {
        margin-right: 0;
        @include mq($min-width: map-get($breakpoints, s)) {
            width: auto;
            margin-right: 2rem;
        }
    }
    .article-list-layout {
        display: none;
        @include mq($min-width: map-get($breakpoints, s)) {
            display: inline;
        }
        .opc-button {
            background-color: transparent;
            opacity: 1;
            border: 1px solid transparent;
            padding: 0!important;
            margin-right: 1rem;
            svg{
                display: none;
            }
            width: 2.4rem;
            height: 2.4rem;
            background-image: inline-svg($views-list, $gray);
            background-position: center;
            background-size: 90%;
            background-repeat: no-repeat;

            &[data-op-layout="tile"]{
                background-image: inline-svg($views-tile, $gray);
            }
            &.is-active {
                border-color: $brand-color-1;
                background-image: inline-svg($views-list, $brand-color-1);
                &[data-op-layout="tile"]{
                    background-image: inline-svg($views-tile, $brand-color-1);
                }
            }
        }
    }
}

.opc-article-list{
    &[data-op-tile-col="4"][data-op-article-list-type="tile"] {
        >li {
            @include mq($min-width: map-get($breakpoints, xs)) {
                flex-basis: span(6);
                max-width: span(6);
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                flex-basis: span(4);
                max-width: span(4);
            }
            @include mq($min-width: map-get($breakpoints, mplus)) {
                flex-basis: span(3);
                max-width: span(3);
            }
            @include mq($min-width: map-get($breakpoints, l)) {
            }
            @include mq($min-width: map-get($breakpoints, xl)) {
                
            }
        }
    }
}

.opc-cms-content-wrapper {
  &.hero {
    @include mq($min-width: map-get($breakpoints, l)) {
        margin-bottom:1rem;
        margin-top:1rem;
    }
  }
}