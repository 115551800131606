/*###############################################

@Title: Content
@Description:


###############################################*/
html, body {
	margin: 0;   
	padding: 0;
	max-width: 100%;
}
html {
	font-size: 10px; 
  max-width: 100%;
  overflow-x: hidden;
  &.offcanvas-menu-open,
  &.search-open {
    overflow: hidden;
  }
  &.has-critical {
    @supports not (-ms-high-contrast: none) {
      /* Non-IE styles here */
      .content, #content {
      opacity: 0;
      visibility: hidden;
      }
   }
    .opc-pageloader {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: #fff;
    z-index: 999;
    top: 0;
    &::before {
    content: '';
    width: 3rem;
    height: 3rem;
    background: transparent;
    display: block;
    border-radius: 50%;
    position: absolute;
    left: calc(50vw - 3rem);
    top: calc(50vh - 3rem);
    z-index: 9;
    pointer-events: none;
    border: 2px solid transparent;
    border-top: 2px solid $brand-color-1;
    animation-name: rotater;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    opacity: 1;
    transition: opacity 0.4s ease-in-out;
    }
    @keyframes rotater {
    50% {
    transform: rotate(400deg);
    }
    }
    }
    }
}
body { 
	font-size: $_opc-base-font-size;
  font-family: $_opc-base-font-family;
  font-weight: $_opc-base-font-weight;
  line-height: $_opc-base-line-height;
	background: $white;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  @include mq($min-width: map-get($breakpoints, l)) {
    padding: 1.2rem;
    padding-top: 0;
  }
}  

.viewport {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  max-width: map-get($breakpoints, xxl);
  margin: auto;
  overflow-x: hidden;
}

.content {
	//@extend %mw;
  // padding: 0 20px;
  @include mq($min-width: map-get($breakpoints, l)) {
    padding: 0;
  }
  @include clearfix();
  >section{
    padding-left: 2rem;
    padding-right: 2rem;
    @include mq($min-width: map-get($breakpoints, m)) {
      padding-left: 4rem;
      padding-right: 4rem;
    }
    @include mq($min-width: map-get($breakpoints, l)) {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }
  background: #fff;
  flex-grow: 1;

	.opc-content-loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: $black;
    opacity: 0.2;
  }

  h1, h2, h3, h4, h5, h6{
    font-weight: $_opc-base-font-weight;
  }

  &.brands{
    .opc-extended-head{
      // margin-bottom: 0;
      padding: 0;
      @include mq($min-width: map-get($breakpoints, m)) {
        padding: 4rem 0;
      }
      h1{
        font-weight: normal;
        margin-bottom: 0!important;
        font-size: 2.4rem;
        @include mq($min-width: map-get($breakpoints, m)) {
          font-size: 4.6rem;
        }
      }
    }
    .anchor-nav{
      >a{
        color: $text-color;
        font-size: 2.4rem;
        margin-right: 1rem;
        &:link,&:visited,&:active {
          color: $text-color;
        }
        &:hover{
          color: $brand-color-1;
          text-decoration: underline;
          &::before{
            color: $text-color;
          }
        }
        &::before{
          content: "|";
          margin-right: 1rem;
        }
        &:first-of-type{
          &::before{
            display: none;
          }
        }
      }
    }
    .opc-category-navigation{
      margin-top: 3rem;
      .navigation-section-item{
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid $text-color;
        padding: 3rem 0 1rem;
        h3{
          flex-basis: 100%;
          @include mq($min-width: map-get($breakpoints, l)) {
            flex-basis: 25%;
          }
          color: $gray;
          font-size: 2.4rem;
        }
        ul{
          flex-basis: 100%;
          flex-grow: 1;
          @include mq($min-width: map-get($breakpoints, l)) {
            flex-basis: 75%;
          }
          display: flex;
          flex-wrap: wrap;
          li{
            margin: 0;
            padding-bottom: 2rem;
            flex-basis: 100%;
            width: auto;
            max-width: none;
            @include mq($min-width: map-get($breakpoints, m)) {
              flex-basis: calc(100% / 3);
            }
            a{
              color: $text-color;
              font-size: 2.4rem;
              &:link,&:visited,&:active {
                color: $text-color;
              }
              &:hover{
                color: $brand-color-1;
                text-decoration: underline;
                // font-weight: bold;
                &::before{
                  color: $text-color;
                }
              }
            }
          }
        }
      }
    }
  }

  &.search-result{
    .search-result-header{
      margin-top: 6rem;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 4rem;
      >*{
        flex-basis: 100%;
        @include mq($min-width: map-get($breakpoints, m)) {
          flex-basis: 75%;
        }
        line-height: 1.1;
      }
      >h4{
        flex-basis: 100%;
        @include mq($min-width: map-get($breakpoints, m)) {
          flex-basis: 25%;
        }
        font-size: 1.8rem;
        font-weight: 900;
      }
      h1,h2,h3,h4,h5,h6{
        line-height: 1.4;
        font-weight: 900;
        margin-bottom: .5rem;
      }
      h1{
        font-size: 2.4rem;
        text-align: center;
        flex-basis: 100%;
        margin-bottom: 2rem;
        .highlight{
          color: $brand-color-1;
        }
      }
      h2{
        font-size: 2.4rem;
      }
      ul{
          margin: 0 0 .5rem 0;
          padding: 0 0 0 .5rem;
      }
      li{
          line-height: 2.2rem;
          list-style-position: inside;
          list-style-type: none;
          display: inline;
          font-size: 1.8rem;
          &::before{
              content: "|";
              display: inline;
              margin: 0 .6rem;
          }
          &:first-child{
            &::before{
              display: none;
            }
          }
          &.artSpecGroup{
              &::before{
                  content: "";
                  display: none;
              }
          }
      }
    }
    .opc-filter-set{
      margin-bottom: 1rem;
    }
  }
  &__landingpages {
      background-color: $white;
      border-top: 1px solid $lightgray;
      .section-wrapper {
        padding: 3.5rem 4rem 0 4rem;
        @include mq($max-width: map-get($breakpoints, m)) {
            padding: 3.5rem 2rem 0 2rem;
           }
          // @include mq($max-width: map-get($breakpoints, s)) {
          //     padding: 28px 16px 16px;
          // }
          margin: 0 auto;
          .content-header {
              @include mq($min-width: map-get($breakpoints, l)) {
                  padding-left: 98px;
              }
              .opc-form {
                  padding-top: 10px;
                  max-width: 380px;
                  input {
                    font-size: 20px;
                  }
              }
              .alphabetic-navigation {
                  width: 100%;
                  overflow: hidden;
                  overflow-x: auto;
                  ul {
                      display: flex;
                      // flex-wrap: wrap;
                      list-style: none;
                      padding: 0;
                      margin: 0;
                      width: auto;
                      @include mq($max-width: map-get($breakpoints, m)) {
                        width: 50rem;
                    }
                      li {
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          width: 32px;
                          height: 32px;
                          font-size: $_opc-base-font-size;
                          > a {
                              font-size: 20px;
                              color:$brand-color-2;
                          }
                          &[disabled] {
                              pointer-events: none;
                              > a {
                                  color: $brand-color-1;
                              }
                          }
                      }
                  }
              }
              &__pl-0 {
                  padding-left: 0;
              }
          }
          .section-inner {
              max-width: none;
              padding-top: 48px;
              @include mq($max-width: map-get($breakpoints, m)) {
                  padding-top: 36px;
              }
              @include mq($max-width: map-get($breakpoints, s)) {
                  padding-top: 24px;
              }
              .alphabetic-list {
                  display: flex;
                  flex-direction: column;
                  list-style: none;
                  margin: 0;
                  padding: 0;
                  row-gap: 48px;
                  li.section {
                      display: flex;
                      column-gap: $mw-padding;
                      width: 100%;
                      scroll-padding-top: 20px;
                      @include mq($max-width: map-get($breakpoints, l)) {
                          flex-direction: column;
                      }
                      .list {
                          &__character {
                            h4 {
                                font-weight: bold;
                                color:$brand-color-2;
                              }
                              @include mq($min-width: map-get($breakpoints, l)) {
                                  flex: 82px;
                                  text-align: right;
                              }
                          }
                          &__items {
                              display: flex;
                              flex-wrap: wrap;
                              list-style: none;
                              margin: 0;
                              padding: 0;
                              width: 100%;
                              > li {
                                  flex-basis: calc(100% / 5);
                                  @include mq($max-width: map-get($breakpoints, l)) {
                                      flex-basis: calc(100% / 4);
                                  }
                                  @include mq($max-width: map-get($breakpoints, s)) {
                                      flex-basis: calc(100% / 2);
                                  }
                                  box-shadow: $lightgray;
                                  a {
                                    color: $brand-color-2;
                                    &:hover {
                                      color:$brand-color-1;
                                    }
                                  }
                              }
                          }
                      }
                      &[hidden] {
                        display: none;
                      }
                  }
              }
              .intro {
                 
                  p {
                      margin-bottom: 24px;
                  }
                  .intro-image {
                      width: 100%;
                      height: 245px;
                      object-fit: cover;
                  }
              }
              .contact-form {
                  padding-top: 48px;
                  max-width: 541px;
                  @include mq($max-width: map-get($breakpoints, m)) {
                      max-width: 100%;
                      padding-top: 36px;
                  }
                  @include mq($max-width: map-get($breakpoints, s)) {
                      padding-top: 24px;
                  }
                  .opc-contact-card {
                      margin-bottom: 48px;
                      @include mq($max-width: map-get($breakpoints, m)) {
                          margin-bottom: 36px;
                      }
                      @include mq($max-width: map-get($breakpoints, s)) {
                          margin-bottom: 24px;
                      }
                  }
                  .opc-row {
                      margin: 0;
                      .opc-col {
                          padding: 0;
                          margin-bottom: 8px;
                          &[data-op-col="6"]:nth-child(2n+1) {
                              padding-right: 16px;
                          }
                          &[data-op-col="6"]:nth-child(2n) {
                              padding-left: 16px;
                          }
                          &__form-btn {
                              margin-top: 24px;
                              margin-bottom: 0;
                              display: flex;
                              @include mq($max-width: map-get($breakpoints, m)) {
                                  margin-top: 12px;
                              }
                              @include mq($max-width: map-get($breakpoints, s)) {
                                  margin: 0 0 8px;
                                  button {
                                      width: 100%;
                                  }
                              }
                              &.left {
                                  justify-content: flex-start;
                              }
                              &.right {
                                  justify-content: flex-end;
                              }
                          }
                      }
                  }
              }
          }
      }
  }
} 


section.breadcrumb{
  padding-left: 2rem;
  padding-right: 2rem;
  @include mq($min-width: map-get($breakpoints, m)) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  @include mq($min-width: map-get($breakpoints, l)) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}


.result-view-menu,
.article-list-menu {
	
}

.lead-badges-section {
  @include mq($min-width: map-get($breakpoints, l)) {
    display: none;
  }

  .lead-badges{
    display: flex;
    flex-wrap: wrap;
    margin-left: -.5rem;
    margin-right: -.5rem;
    padding: 0 2rem;
    @include mq($min-width: map-get($breakpoints, s)) {
      // padding: 0rem 5rem;
      margin-left:-6rem;
      margin-right:-6rem;
    }
    @include mq($min-width: map-get($breakpoints, m)) {
      margin-left:-8rem;
      margin-right:-8rem;
    }
    .lead-badge-wrapper {
      width: 100%;
      @include mq($min-width: map-get($breakpoints, s)) {
          flex-basis: calc(100% / 2);
          max-width: 50%;
          padding: 0 6rem;
          &:nth-last-child(-n+2){
            .text {
              border-bottom: 1px solid #8AAEC1;
            }
        }
    }
      .text {
        width: 100%;
        padding: 1.5rem 0;
        padding-left: 0.5rem;
        border-top: 1px solid #8AAEC1;
        margin:0;
        color: $brand-color-1;
        
      }
      &:last-child {
          .text {
              border-bottom: 1px solid #8AAEC1;
            }
        }
    }
    
}
}
.lead-piktos {
    display: flex;
    overflow-x: auto;
    display: flex;
    gap:1rem;
    @include mq($min-width: map-get($breakpoints, m)) {
        flex-wrap: nowrap;
        display: flex;
    }
    .lead-pikto-wrapper {
        min-width: span(3);
        @include mq($min-width: map-get($breakpoints, s)) {
            flex-basis: span(7);
            max-width: span(7);
        }
        @include mq($min-width: map-get($breakpoints, m)) {
            flex-basis: span(3);
            max-width: span(3);
        }
        @include mq($min-width: map-get($breakpoints, l)) {
            flex-basis: span(3);
            max-width: span(3);
            min-width: auto;
        }
        @include mq($min-width: map-get($breakpoints, xl)) {}
        >.img-background  {
            background-color: rgba(105,151,175,0.4);
            &:hover {
                background-color:  #6997af;
            }
        }
    }
}
.recommendation-badges-section {
  h2 {
    text-align: center;
    font-weight: bold;
    color: $brand-color-2;
    margin: 0 0 2.2rem;
  }
  .lead-badges{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @include mq($min-width: map-get($breakpoints, m)) {
      grid-template-columns: repeat(4, 1fr);
      }
    gap: 1.2rem;
    .lead-badge-wrapper {
      width: 100%;
      background: rgba($brand-color-1,.4);
      text-align: center;
      display: flex;
      align-items: center;
      .text {
        width: 100%;
        padding: 1.2rem 1rem;
        margin:0;
         > a {
           color: $brand-color-2;
           font-size: 18px;
          }
          
        }
        &:hover {
          background: $brand-color-1;
          .text {
            font-weight: bold;
            > a {
            font-size: 18px;
            color: $white;
          }
        }
      }
    }
    
  }
}

.search-results{
  .no-results{
    color: $text-color;
    font-weight: 300;
    font-size: 2.8rem;
    margin-bottom: 4rem;
  }
}


$_opc-message-bg : #f8f8f8 !default;
$_opc-message-padding : 2rem !default;
.opc-message {
    background: $_opc-message-bg;
    padding: $_opc-message-padding;
    margin-bottom: 3rem;
    p {
        &:last-of-type {
            margin: 0;
        }
    }
    svg {
        font-size: 1.2em;
        margin-right: 1rem;
    }
    &[data-op-type="info"] {
        margin-top: 4rem;
        background:$white;
        color: $brand-color-1;
        border: 1px solid  $error-color;
        // display: inline-block;
        text-align: center;
        a{
          margin-left: 2rem;
        }
    }
    &[data-op-type="warning"] {
        background: $warning-color;
    }
    &[data-op-type="success"] {
        background: $success-color;
        color: #fff;
    }
    &[data-op-type="error"] {
        background: $error-color;
        color: #fff;
    }
}