/*###############################################

@Title: Project Theme Config
@Description:
This file contains the config settings for the theme. It will overwrite the core vars.

###############################################*/

/*
 
 Basics
 ---------------------------------------------------- */

// Max Width and Max Width Paddings
// $mw: 1896px;
$mw: 1200px;
$mw-padding: 2rem;

// Grid
$_opc-grid-columns: 12;
$_opc-grid-gutter-width: 6px;
$_opc-grid-column-width: 120px;
 
/*
 
 Colors
 ---------------------------------------------------- */

$brand-color-1: #6997af;
$brand-color-2: #1F3855;
$brand-color-3: #2C2C2C;
$text-color: $brand-color-3;
$white: #fff;
$lightgray: #F0F0F0;
$gray: #626161;
// $darkgray: #707070;
$black: #000;
$red: #a00;
$success-color: #70c391;
$error-color : #fa7979;
$info-color: #b0ddfa;
$warning-color: #fff8bb;

// button colors
$primary-button-color : $brand-color-2;
$secondary-button-color : #ccc;
$loud-button-color : $brand-color-1;

// state colors
$success-color : #70c391;
$error-color : #fa7979;
$info-color : #78b3da;
$warning-color : #fff8bb;
$progress-color : #ccc;

// stock colors
$stock-on-stock : #789600;
$stock-out-stock : #BE3A24;
$stock-call-stock : $info-color;
$stock-less-stock : $brand-color-1;
$stock-na-stock : $error-color;

// external component colors
$selected-date-color: $brand-color-1;
$autocomplete-highlight-color: $brand-color-1;
$fileupload-error-color: $error-color;
$_opc-boolean-active-color: $brand-color-1;

// breakpoints
$breakpoints: ( 
    'tiny': 480px,
    'small': 640px,
    'medium': 800px,
    'normal': 1020px,
    'large': 1400px,
    'xs': 480px,
    's': 640px,
    'm': 800px,
    'mplus': 1024px,
    'l': 1186px,
    'lplus': 1200px,
    'xl': 1400px,
    'xxl': 1920px
);


// sizes 
$padding: ( 
    'tiny': 0.5rem, 
    'small': 0.75rem, 
    'medium': 1.25rem, 
    'normal': 1.25rem, 
    'large': 3.5rem, 
    'huge': 2rem

    );
/* !!!! DEPRECATED, JUST FOR COMPATIBLITY. USE PADDING INSTEAD !!!!! */
$SIZES: ( 'tiny': 0.5rem, 'small': 0.75rem, 'medium': 1.25rem, 'normal': 1.25rem, 'large': 1.75rem, 'huge': 2rem);

// heights
$heights: ( 
    'tiny': 2.6rem, 
    'small': 3.2rem, 
    'medium': 4.4rem, 
    'normal': 4.4rem, 
    'large': 5rem, 
    'huge': 6rem
);

$spacing: ( 
    'small': 2rem, 
    'medium': 3rem, 
    'large': 4rem
);


/*
 
 typography
 ---------------------------------------------------- */

// font-size
$_opc-base-font-size : 1.6rem;
// fluid typography 
$_opc-fluid-typo : "false";
$_opc-font-size-multiplyer : 1.2;
$_opc-font-min-width : 320px;
$_opc-font-max-width : $mw;
// font family and styles
$_opc-base-font-family : "Geogrotesque";
$_opc-base-font-weight : 400;
$_opc-base-line-height : 1.4;
$_opc-headline-font-family : "Geogrotesque";
$_opc-headline-font-weight : bold;
$_opc-headline-font-weight-content : 900;
$_opc-headline-line-height : 1.25;
$_opc-lead-font-family : "Geogrotesque";
$_opc-lead-font-weight : 400;
$_opc-lead-line-height : 1.25;
// headlines
$_opc-font-size-h1 : 46px;
$_opc-font-size-h2 : 24px;
$_opc-font-size-h3 : 20px;
$_opc-font-size-h4 : 18px;
$_opc-font-size-h5 : 16px;
$_opc-font-size-h6 : 14px;
$_opc-font-size-h1-max : $_opc-font-size-h1 * $_opc-font-size-multiplyer;
$_opc-font-size-h2-max : $_opc-font-size-h2 * $_opc-font-size-multiplyer;
$_opc-font-size-h3-max : $_opc-font-size-h3 * $_opc-font-size-multiplyer;
$_opc-font-size-h4-max : $_opc-font-size-h4 * $_opc-font-size-multiplyer;
$_opc-font-size-h5-max : $_opc-font-size-h5 * $_opc-font-size-multiplyer;
$_opc-font-size-h6-max : $_opc-font-size-h6 * $_opc-font-size-multiplyer;
// lead and copy text
$_opc-font-size-lead : $_opc-base-font-size * 1.4;
$_opc-font-size-lead-max : $_opc-font-size-lead * $_opc-font-size-multiplyer;
$_opc-font-size-small : 1.2rem;
$_opc-font-size-small-max : $_opc-font-size-small * $_opc-font-size-multiplyer;
$_opc-font-size-copy : $_opc-base-font-size;
$_opc-font-size-copy-max : $_opc-font-size-copy * $_opc-font-size-multiplyer;
$_opc-font-size-s : 1.2rem;
$_opc-font-size-m : 1.6rem;
$_opc-font-size-l : 1.6rem;
$_opc-font-size-xl : 1.8rem;
/*
 
 SVG Icons
 ---------------------------------------------------- */

// make svg icons available in sass as background image
$op-icon-cross-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path style=" " d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z "/></svg>';
$op-icon-check-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path style=" " d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z "/></svg>';
$op-icon-angle-right-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path style=" " d="M 12.96875 4.28125 L 11.53125 5.71875 L 21.8125 16 L 11.53125 26.28125 L 12.96875 27.71875 L 23.96875 16.71875 L 24.65625 16 L 23.96875 15.28125 Z "/></svg>';
$op-icon-angle-left-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path d="M19.031 4.281l-11 11-0.688 0.719 0.688 0.719 11 11 1.438-1.438-10.281-10.281 10.281-10.281z"></path></svg>';
$op-icon-angle-down-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path style=" " d="M 4.21875 10.78125 L 2.78125 12.21875 L 15.28125 24.71875 L 16 25.40625 L 16.71875 24.71875 L 29.21875 12.21875 L 27.78125 10.78125 L 16 22.5625 Z "/></svg>';
$op-icon-stock-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><circle cx="50" cy="50" r="40" /></svg>';

$slider-arrow-left: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path d="M20.8,29.6c0.1,0.2,0.4,0.2,0.6,0.1c0.2-0.1,0.2-0.4,0.1-0.6l-9.9-12.2l9.9-13.9c0.1-0.2,0.1-0.4-0.1-0.6 	c-0.1-0.1-0.2-0.1-0.2-0.1c-0.1,0-0.3,0.1-0.3,0.2L10.6,16.9L20.8,29.6z"/> </svg> ';
$slider-arrow-right: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path d="M11.2,2.4c-0.1-0.2-0.4-0.2-0.6-0.1c-0.2,0.1-0.2,0.4-0.1,0.6l9.9,12.2l-9.9,13.9c-0.1,0.2-0.1,0.4,0.1,0.6 	c0.1,0.1,0.2,0.1,0.2,0.1c0.1,0,0.3-0.1,0.3-0.2l10.2-14.5L11.2,2.4z"/> </svg> ';

$op-icon-angle-left-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path d="M19.031 4.281l-11 11-0.688 0.719 0.688 0.719 11 11 1.438-1.438-10.281-10.281 10.281-10.281z"></path></svg>';
$op-icon-angle-down-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path style=" " d="M 4.21875 10.78125 L 2.78125 12.21875 L 15.28125 24.71875 L 16 25.40625 L 16.71875 24.71875 L 29.21875 12.21875 L 27.78125 10.78125 L 16 22.5625 Z "/></svg>';
$op-icon-stock-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><circle cx="50" cy="50" r="40" /></svg>';
$op-icon-fav-active-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path id="Pfad_14" d="M22.9,2.3c-2.4,0-4.7,1-6.4,2.7L16,5.5L15.5,5C12,1.4,6.3,1.4,2.8,4.9L2.7,5c-3.5,3.6-3.5,9.4,0,13l12.6,12.7 c0.4,0.4,1.1,0.4,1.5,0l0,0L29.4,18c3.5-3.6,3.5-9.4,0-13C27.6,3.3,25.3,2.3,22.9,2.3z"/></svg>';
$op-icon-playbutton-svg: '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#e8eaed"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M10 8.64L15.27 12 10 15.36V8.64M8 5v14l11-7L8 5z"/></svg>';

$user-checked-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><polygon points="15.3,25.9 8.2,18.8 10.3,16.7 15.3,21.6 29.7,7.2 31.8,9.3 "/></svg>';

$psp-icon-slider-navigation-left-svg: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.5 10C19.5 15.25 15.25 19.5 10 19.5C4.75 19.5 0.5 15.25 0.5 10C0.5 4.75 4.75 0.5 10 0.5C15.25 0.5 19.5 4.75 19.5 10Z" fill="#E26B6B" stroke="#D65C5C" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M13.46 6.29004L9.73999 10L13.46 13.71" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.38999 6.29004L5.67999 10L9.38999 13.71" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>';
$psp-icon-slider-navigation-right-svg: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 10C0.5 15.25 4.75 19.5 10 19.5C15.25 19.5 19.5 15.25 19.5 10C19.5 4.75 15.25 0.5 10 0.5C4.75 0.5 0.5 4.75 0.5 10Z" fill="#E26B6B" stroke="#D65C5C" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M6.54001 6.29004L10.26 10L6.54001 13.71" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.61 6.29004L14.32 10L10.61 13.71" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>';

$catstruct-icon-20: '<svg viewBox="0 0 108 108" version="1.1" width="108" height="108" xmlns="http://www.w3.org/2000/svg"><path d="M82.7,21.9c-3.4-3.4-7.2-6.2-10.6-7.7c-3.5-1.6-6.3-1.6-7.7-0.2c-1,1-1.2,2.6-0.9,4.6c0,0,0,0,0,0l-0.1,5.1 c-0.1,7.1-2.9,13.8-8,18.8L42.3,55.8l-1.6-1.6c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l1.6,1.6l-7.1,7.1L32.9,62 c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l1.6,1.6l-7.1,7.1l-1.6-1.6c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l1.6,1.6l-9.4,9.4 c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1l9.4-9.4l5.2,5.2l-0.6,0.6c0,0,0,0,0,0c0,0,0,0,0,0l-8.8,8.8 c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1l5.7-5.7c-1.3,3.6-0.5,7.7,2.4,10.6c2,2,4.6,3,7.2,3 c2.6,0,5.2-1,7.2-3l0.6-0.6l1.6,1.6c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7l-1.6-1.6l7.1-7.1l1.6,1.6 c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7l-1.6-1.6l7.1-7.1l1.6,1.6c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1 c0.2-0.2,0.2-0.5,0-0.7L62.6,76l0.6-0.6c0,0,0,0,0,0c4-4,4-10.4,0-14.4c-2.9-2.9-7-3.6-10.6-2.4l9.6-9.6c5-5,11.6-7.8,18.7-7.9 l4.8-0.1c0.7,0.2,1.4,0.3,2,0.3c1.2,0,2.2-0.4,3-1.1C93.5,37.3,90.1,29.2,82.7,21.9z M42.3,57.2l5.2,5.2l-7.1,7.1l-5.2-5.2 L42.3,57.2z M27.4,72.1l7.1-7.1l5.2,5.2l-0.9,0.9l-6.2,6.2L27.4,72.1z M44.9,92.3c-3.6,3.6-9.4,3.6-13,0c-1.7-1.7-2.7-4-2.7-6.5 c0-2.4,0.9-4.7,2.7-6.5l0.6-0.6l13,13L44.9,92.3z M46.2,91l-13-13l7.1-7.1l13,13L46.2,91z M54,83.1l-13-13l7.1-7.1l13,13L54,83.1z  M62.5,61.7c1.7,1.7,2.7,4,2.7,6.5s-1,4.8-2.7,6.5c0,0,0,0,0,0l-0.6,0.6l-13-13l0.6-0.6C53.1,58.1,58.9,58.1,62.5,61.7z M80.8,40.1 c-7.3,0.1-14.2,3.1-19.3,8.2L48.8,61c0,0,0,0,0,0l-0.6,0.6L43,56.5l13.2-13.2c5.2-5.2,8.1-12.1,8.2-19.5l0-2.1 c1.4,3.3,4.1,7.1,7.7,10.7c3.4,3.4,7.2,6.2,10.6,7.7c0,0,0,0,0,0L80.8,40.1z M89.9,39.5c-1.1,1.1-3.5,1-6.6-0.4 C80,37.7,76.3,35,73,31.7c-4.5-4.5-7.6-9.5-8.4-13.1c0,0,0-0.1,0-0.1c-0.3-1.6-0.2-2.9,0.6-3.7c0.5-0.5,1.3-0.8,2.2-0.8 c1.2,0,2.7,0.4,4.4,1.1c3.3,1.5,6.9,4.1,10.3,7.5C88.7,29.3,92.3,37,89.9,39.5z"/></svg>';
$catstruct-icon-30: '<svg viewBox="0 0 108 108" version="1.1" width="108" height="108" xmlns="http://www.w3.org/2000/svg"><path d="M82.7,21.9c-3.4-3.4-7.2-6.2-10.6-7.7c-3.5-1.6-6.3-1.6-7.7-0.2c-1,1-1.2,2.6-0.9,4.6c0,0,0,0,0,0l-0.1,5.1 c-0.1,7.1-2.9,13.8-8,18.8L42.3,55.8l-1.6-1.6c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l1.6,1.6l-7.1,7.1L32.9,62 c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l1.6,1.6l-7.1,7.1l-1.6-1.6c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l1.6,1.6l-9.4,9.4 c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1l9.4-9.4l5.2,5.2l-0.6,0.6c0,0,0,0,0,0c0,0,0,0,0,0l-8.8,8.8 c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1l5.7-5.7c-1.3,3.6-0.5,7.7,2.4,10.6c2,2,4.6,3,7.2,3 c2.6,0,5.2-1,7.2-3l0.6-0.6l1.6,1.6c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7l-1.6-1.6l7.1-7.1l1.6,1.6 c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7l-1.6-1.6l7.1-7.1l1.6,1.6c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1 c0.2-0.2,0.2-0.5,0-0.7L62.6,76l0.6-0.6c0,0,0,0,0,0c4-4,4-10.4,0-14.4c-2.9-2.9-7-3.6-10.6-2.4l9.6-9.6c5-5,11.6-7.8,18.7-7.9 l4.8-0.1c0.7,0.2,1.4,0.3,2,0.3c1.2,0,2.2-0.4,3-1.1C93.5,37.3,90.1,29.2,82.7,21.9z M42.3,57.2l5.2,5.2l-7.1,7.1l-5.2-5.2 L42.3,57.2z M27.4,72.1l7.1-7.1l5.2,5.2l-0.9,0.9l-6.2,6.2L27.4,72.1z M44.9,92.3c-3.6,3.6-9.4,3.6-13,0c-1.7-1.7-2.7-4-2.7-6.5 c0-2.4,0.9-4.7,2.7-6.5l0.6-0.6l13,13L44.9,92.3z M46.2,91l-13-13l7.1-7.1l13,13L46.2,91z M54,83.1l-13-13l7.1-7.1l13,13L54,83.1z  M62.5,61.7c1.7,1.7,2.7,4,2.7,6.5s-1,4.8-2.7,6.5c0,0,0,0,0,0l-0.6,0.6l-13-13l0.6-0.6C53.1,58.1,58.9,58.1,62.5,61.7z M80.8,40.1 c-7.3,0.1-14.2,3.1-19.3,8.2L48.8,61c0,0,0,0,0,0l-0.6,0.6L43,56.5l13.2-13.2c5.2-5.2,8.1-12.1,8.2-19.5l0-2.1 c1.4,3.3,4.1,7.1,7.7,10.7c3.4,3.4,7.2,6.2,10.6,7.7c0,0,0,0,0,0L80.8,40.1z M89.9,39.5c-1.1,1.1-3.5,1-6.6-0.4 C80,37.7,76.3,35,73,31.7c-4.5-4.5-7.6-9.5-8.4-13.1c0,0,0-0.1,0-0.1c-0.3-1.6-0.2-2.9,0.6-3.7c0.5-0.5,1.3-0.8,2.2-0.8 c1.2,0,2.7,0.4,4.4,1.1c3.3,1.5,6.9,4.1,10.3,7.5C88.7,29.3,92.3,37,89.9,39.5z"/></svg>';
$catstruct-icon-50: '<svg viewBox="0 0 108 108" version="1.1" width="108" height="108" xmlns="http://www.w3.org/2000/svg"><path d="M82.7,21.9c-3.4-3.4-7.2-6.2-10.6-7.7c-3.5-1.6-6.3-1.6-7.7-0.2c-1,1-1.2,2.6-0.9,4.6c0,0,0,0,0,0l-0.1,5.1 c-0.1,7.1-2.9,13.8-8,18.8L42.3,55.8l-1.6-1.6c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l1.6,1.6l-7.1,7.1L32.9,62 c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l1.6,1.6l-7.1,7.1l-1.6-1.6c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l1.6,1.6l-9.4,9.4 c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1l9.4-9.4l5.2,5.2l-0.6,0.6c0,0,0,0,0,0c0,0,0,0,0,0l-8.8,8.8 c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1l5.7-5.7c-1.3,3.6-0.5,7.7,2.4,10.6c2,2,4.6,3,7.2,3 c2.6,0,5.2-1,7.2-3l0.6-0.6l1.6,1.6c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7l-1.6-1.6l7.1-7.1l1.6,1.6 c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7l-1.6-1.6l7.1-7.1l1.6,1.6c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1 c0.2-0.2,0.2-0.5,0-0.7L62.6,76l0.6-0.6c0,0,0,0,0,0c4-4,4-10.4,0-14.4c-2.9-2.9-7-3.6-10.6-2.4l9.6-9.6c5-5,11.6-7.8,18.7-7.9 l4.8-0.1c0.7,0.2,1.4,0.3,2,0.3c1.2,0,2.2-0.4,3-1.1C93.5,37.3,90.1,29.2,82.7,21.9z M42.3,57.2l5.2,5.2l-7.1,7.1l-5.2-5.2 L42.3,57.2z M27.4,72.1l7.1-7.1l5.2,5.2l-0.9,0.9l-6.2,6.2L27.4,72.1z M44.9,92.3c-3.6,3.6-9.4,3.6-13,0c-1.7-1.7-2.7-4-2.7-6.5 c0-2.4,0.9-4.7,2.7-6.5l0.6-0.6l13,13L44.9,92.3z M46.2,91l-13-13l7.1-7.1l13,13L46.2,91z M54,83.1l-13-13l7.1-7.1l13,13L54,83.1z  M62.5,61.7c1.7,1.7,2.7,4,2.7,6.5s-1,4.8-2.7,6.5c0,0,0,0,0,0l-0.6,0.6l-13-13l0.6-0.6C53.1,58.1,58.9,58.1,62.5,61.7z M80.8,40.1 c-7.3,0.1-14.2,3.1-19.3,8.2L48.8,61c0,0,0,0,0,0l-0.6,0.6L43,56.5l13.2-13.2c5.2-5.2,8.1-12.1,8.2-19.5l0-2.1 c1.4,3.3,4.1,7.1,7.7,10.7c3.4,3.4,7.2,6.2,10.6,7.7c0,0,0,0,0,0L80.8,40.1z M89.9,39.5c-1.1,1.1-3.5,1-6.6-0.4 C80,37.7,76.3,35,73,31.7c-4.5-4.5-7.6-9.5-8.4-13.1c0,0,0-0.1,0-0.1c-0.3-1.6-0.2-2.9,0.6-3.7c0.5-0.5,1.3-0.8,2.2-0.8 c1.2,0,2.7,0.4,4.4,1.1c3.3,1.5,6.9,4.1,10.3,7.5C88.7,29.3,92.3,37,89.9,39.5z"/></svg>';
$catstruct-icon-70: '<svg viewBox="0 0 108 108" version="1.1" width="108" height="108" xmlns="http://www.w3.org/2000/svg"><path d="M82.7,21.9c-3.4-3.4-7.2-6.2-10.6-7.7c-3.5-1.6-6.3-1.6-7.7-0.2c-1,1-1.2,2.6-0.9,4.6c0,0,0,0,0,0l-0.1,5.1 c-0.1,7.1-2.9,13.8-8,18.8L42.3,55.8l-1.6-1.6c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l1.6,1.6l-7.1,7.1L32.9,62 c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l1.6,1.6l-7.1,7.1l-1.6-1.6c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l1.6,1.6l-9.4,9.4 c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1l9.4-9.4l5.2,5.2l-0.6,0.6c0,0,0,0,0,0c0,0,0,0,0,0l-8.8,8.8 c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1l5.7-5.7c-1.3,3.6-0.5,7.7,2.4,10.6c2,2,4.6,3,7.2,3 c2.6,0,5.2-1,7.2-3l0.6-0.6l1.6,1.6c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7l-1.6-1.6l7.1-7.1l1.6,1.6 c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7l-1.6-1.6l7.1-7.1l1.6,1.6c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1 c0.2-0.2,0.2-0.5,0-0.7L62.6,76l0.6-0.6c0,0,0,0,0,0c4-4,4-10.4,0-14.4c-2.9-2.9-7-3.6-10.6-2.4l9.6-9.6c5-5,11.6-7.8,18.7-7.9 l4.8-0.1c0.7,0.2,1.4,0.3,2,0.3c1.2,0,2.2-0.4,3-1.1C93.5,37.3,90.1,29.2,82.7,21.9z M42.3,57.2l5.2,5.2l-7.1,7.1l-5.2-5.2 L42.3,57.2z M27.4,72.1l7.1-7.1l5.2,5.2l-0.9,0.9l-6.2,6.2L27.4,72.1z M44.9,92.3c-3.6,3.6-9.4,3.6-13,0c-1.7-1.7-2.7-4-2.7-6.5 c0-2.4,0.9-4.7,2.7-6.5l0.6-0.6l13,13L44.9,92.3z M46.2,91l-13-13l7.1-7.1l13,13L46.2,91z M54,83.1l-13-13l7.1-7.1l13,13L54,83.1z  M62.5,61.7c1.7,1.7,2.7,4,2.7,6.5s-1,4.8-2.7,6.5c0,0,0,0,0,0l-0.6,0.6l-13-13l0.6-0.6C53.1,58.1,58.9,58.1,62.5,61.7z M80.8,40.1 c-7.3,0.1-14.2,3.1-19.3,8.2L48.8,61c0,0,0,0,0,0l-0.6,0.6L43,56.5l13.2-13.2c5.2-5.2,8.1-12.1,8.2-19.5l0-2.1 c1.4,3.3,4.1,7.1,7.7,10.7c3.4,3.4,7.2,6.2,10.6,7.7c0,0,0,0,0,0L80.8,40.1z M89.9,39.5c-1.1,1.1-3.5,1-6.6-0.4 C80,37.7,76.3,35,73,31.7c-4.5-4.5-7.6-9.5-8.4-13.1c0,0,0-0.1,0-0.1c-0.3-1.6-0.2-2.9,0.6-3.7c0.5-0.5,1.3-0.8,2.2-0.8 c1.2,0,2.7,0.4,4.4,1.1c3.3,1.5,6.9,4.1,10.3,7.5C88.7,29.3,92.3,37,89.9,39.5z"/></svg>';
$catstruct-icon-80: '<svg viewBox="0 0 108 108" version="1.1" width="108" height="108" xmlns="http://www.w3.org/2000/svg"><path d="M82.7,21.9c-3.4-3.4-7.2-6.2-10.6-7.7c-3.5-1.6-6.3-1.6-7.7-0.2c-1,1-1.2,2.6-0.9,4.6c0,0,0,0,0,0l-0.1,5.1 c-0.1,7.1-2.9,13.8-8,18.8L42.3,55.8l-1.6-1.6c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l1.6,1.6l-7.1,7.1L32.9,62 c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l1.6,1.6l-7.1,7.1l-1.6-1.6c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l1.6,1.6l-9.4,9.4 c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1l9.4-9.4l5.2,5.2l-0.6,0.6c0,0,0,0,0,0c0,0,0,0,0,0l-8.8,8.8 c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1l5.7-5.7c-1.3,3.6-0.5,7.7,2.4,10.6c2,2,4.6,3,7.2,3 c2.6,0,5.2-1,7.2-3l0.6-0.6l1.6,1.6c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7l-1.6-1.6l7.1-7.1l1.6,1.6 c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7l-1.6-1.6l7.1-7.1l1.6,1.6c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1 c0.2-0.2,0.2-0.5,0-0.7L62.6,76l0.6-0.6c0,0,0,0,0,0c4-4,4-10.4,0-14.4c-2.9-2.9-7-3.6-10.6-2.4l9.6-9.6c5-5,11.6-7.8,18.7-7.9 l4.8-0.1c0.7,0.2,1.4,0.3,2,0.3c1.2,0,2.2-0.4,3-1.1C93.5,37.3,90.1,29.2,82.7,21.9z M42.3,57.2l5.2,5.2l-7.1,7.1l-5.2-5.2 L42.3,57.2z M27.4,72.1l7.1-7.1l5.2,5.2l-0.9,0.9l-6.2,6.2L27.4,72.1z M44.9,92.3c-3.6,3.6-9.4,3.6-13,0c-1.7-1.7-2.7-4-2.7-6.5 c0-2.4,0.9-4.7,2.7-6.5l0.6-0.6l13,13L44.9,92.3z M46.2,91l-13-13l7.1-7.1l13,13L46.2,91z M54,83.1l-13-13l7.1-7.1l13,13L54,83.1z  M62.5,61.7c1.7,1.7,2.7,4,2.7,6.5s-1,4.8-2.7,6.5c0,0,0,0,0,0l-0.6,0.6l-13-13l0.6-0.6C53.1,58.1,58.9,58.1,62.5,61.7z M80.8,40.1 c-7.3,0.1-14.2,3.1-19.3,8.2L48.8,61c0,0,0,0,0,0l-0.6,0.6L43,56.5l13.2-13.2c5.2-5.2,8.1-12.1,8.2-19.5l0-2.1 c1.4,3.3,4.1,7.1,7.7,10.7c3.4,3.4,7.2,6.2,10.6,7.7c0,0,0,0,0,0L80.8,40.1z M89.9,39.5c-1.1,1.1-3.5,1-6.6-0.4 C80,37.7,76.3,35,73,31.7c-4.5-4.5-7.6-9.5-8.4-13.1c0,0,0-0.1,0-0.1c-0.3-1.6-0.2-2.9,0.6-3.7c0.5-0.5,1.3-0.8,2.2-0.8 c1.2,0,2.7,0.4,4.4,1.1c3.3,1.5,6.9,4.1,10.3,7.5C88.7,29.3,92.3,37,89.9,39.5z"/></svg>';
$catstruct-icon-90: '<svg viewBox="0 0 108 108" version="1.1" width="108" height="108" xmlns="http://www.w3.org/2000/svg"><path d="M82.7,21.9c-3.4-3.4-7.2-6.2-10.6-7.7c-3.5-1.6-6.3-1.6-7.7-0.2c-1,1-1.2,2.6-0.9,4.6c0,0,0,0,0,0l-0.1,5.1 c-0.1,7.1-2.9,13.8-8,18.8L42.3,55.8l-1.6-1.6c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l1.6,1.6l-7.1,7.1L32.9,62 c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l1.6,1.6l-7.1,7.1l-1.6-1.6c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l1.6,1.6l-9.4,9.4 c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1l9.4-9.4l5.2,5.2l-0.6,0.6c0,0,0,0,0,0c0,0,0,0,0,0l-8.8,8.8 c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1l5.7-5.7c-1.3,3.6-0.5,7.7,2.4,10.6c2,2,4.6,3,7.2,3 c2.6,0,5.2-1,7.2-3l0.6-0.6l1.6,1.6c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7l-1.6-1.6l7.1-7.1l1.6,1.6 c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7l-1.6-1.6l7.1-7.1l1.6,1.6c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1 c0.2-0.2,0.2-0.5,0-0.7L62.6,76l0.6-0.6c0,0,0,0,0,0c4-4,4-10.4,0-14.4c-2.9-2.9-7-3.6-10.6-2.4l9.6-9.6c5-5,11.6-7.8,18.7-7.9 l4.8-0.1c0.7,0.2,1.4,0.3,2,0.3c1.2,0,2.2-0.4,3-1.1C93.5,37.3,90.1,29.2,82.7,21.9z M42.3,57.2l5.2,5.2l-7.1,7.1l-5.2-5.2 L42.3,57.2z M27.4,72.1l7.1-7.1l5.2,5.2l-0.9,0.9l-6.2,6.2L27.4,72.1z M44.9,92.3c-3.6,3.6-9.4,3.6-13,0c-1.7-1.7-2.7-4-2.7-6.5 c0-2.4,0.9-4.7,2.7-6.5l0.6-0.6l13,13L44.9,92.3z M46.2,91l-13-13l7.1-7.1l13,13L46.2,91z M54,83.1l-13-13l7.1-7.1l13,13L54,83.1z  M62.5,61.7c1.7,1.7,2.7,4,2.7,6.5s-1,4.8-2.7,6.5c0,0,0,0,0,0l-0.6,0.6l-13-13l0.6-0.6C53.1,58.1,58.9,58.1,62.5,61.7z M80.8,40.1 c-7.3,0.1-14.2,3.1-19.3,8.2L48.8,61c0,0,0,0,0,0l-0.6,0.6L43,56.5l13.2-13.2c5.2-5.2,8.1-12.1,8.2-19.5l0-2.1 c1.4,3.3,4.1,7.1,7.7,10.7c3.4,3.4,7.2,6.2,10.6,7.7c0,0,0,0,0,0L80.8,40.1z M89.9,39.5c-1.1,1.1-3.5,1-6.6-0.4 C80,37.7,76.3,35,73,31.7c-4.5-4.5-7.6-9.5-8.4-13.1c0,0,0-0.1,0-0.1c-0.3-1.6-0.2-2.9,0.6-3.7c0.5-0.5,1.3-0.8,2.2-0.8 c1.2,0,2.7,0.4,4.4,1.1c3.3,1.5,6.9,4.1,10.3,7.5C88.7,29.3,92.3,37,89.9,39.5z"/></svg>';

// $views-list: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" 	 height="23.8px" viewBox="0 0 30 23.8"><g> 	<path style="fill:#626161" d="M7.3,0H1.2C0.5,0,0,0.5,0,1.2s0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2S7.9,0,7.3,0z"/> 	<path style="fill:#626161" d="M12.7,2.3h16.1c0.6,0,1.2-0.5,1.2-1.2S29.5,0,28.8,0H12.7c-0.6,0-1.2,0.5-1.2,1.2S12,2.3,12.7,2.3z"/> 	<path style="fill:#626161" d="M7.3,10.7H1.2c-0.6,0-1.2,0.5-1.2,1.2S0.5,13,1.2,13h6.1c0.6,0,1.2-0.5,1.2-1.2S7.9,10.7,7.3,10.7z"/> 	<path style="fill:#626161" d="M28.8,10.7H12.7c-0.6,0-1.2,0.5-1.2,1.2S12,13,12.7,13h16.1c0.6,0,1.2-0.5,1.2-1.2S29.5,10.7,28.8,10.7z"/> 	<path style="fill:#626161" d="M7.3,21.5H1.2C0.5,21.5,0,22,0,22.6c0,0.6,0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2C8.5,22,7.9,21.5,7.3,21.5z"/> 	<path style="fill:#626161" d="M28.8,21.5H12.7c-0.6,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2h16.1c0.6,0,1.2-0.5,1.2-1.2C30,22,29.5,21.5,28.8,21.5z"/> </g> </svg>';
// $views-list-active: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" 	 height="23.8px" viewBox="0 0 30 23.8"><g> 	<path style="fill:#93184a" d="M7.3,0H1.2C0.5,0,0,0.5,0,1.2s0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2S7.9,0,7.3,0z"/> 	<path style="fill:#93184a" d="M12.7,2.3h16.1c0.6,0,1.2-0.5,1.2-1.2S29.5,0,28.8,0H12.7c-0.6,0-1.2,0.5-1.2,1.2S12,2.3,12.7,2.3z"/> 	<path style="fill:#93184a" d="M7.3,10.7H1.2c-0.6,0-1.2,0.5-1.2,1.2S0.5,13,1.2,13h6.1c0.6,0,1.2-0.5,1.2-1.2S7.9,10.7,7.3,10.7z"/> 	<path style="fill:#93184a" d="M28.8,10.7H12.7c-0.6,0-1.2,0.5-1.2,1.2S12,13,12.7,13h16.1c0.6,0,1.2-0.5,1.2-1.2S29.5,10.7,28.8,10.7z"/> 	<path style="fill:#93184a" d="M7.3,21.5H1.2C0.5,21.5,0,22,0,22.6c0,0.6,0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2C8.5,22,7.9,21.5,7.3,21.5z"/> 	<path style="fill:#93184a" d="M28.8,21.5H12.7c-0.6,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2h16.1c0.6,0,1.2-0.5,1.2-1.2C30,22,29.5,21.5,28.8,21.5z"/> </g> </svg>';
// $views-tile: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" 	 height="23.8px" viewBox="0 0 30 23.8"><g> 	<path style="fill:#626161" d="M7.3,0H1.2C0.5,0,0,0.5,0,1.2s0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2S7.9,0,7.3,0z"/> 	<path style="fill:#626161" d="M18.1,0h-6.1c-0.6,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2S18.7,0,18.1,0z"/> 	<path style="fill:#626161" d="M22.7,2.3h6.1c0.6,0,1.2-0.5,1.2-1.2S29.5,0,28.8,0h-6.1c-0.6,0-1.2,0.5-1.2,1.2S22,2.3,22.7,2.3z"/> 	<path style="fill:#626161" d="M7.3,10.7H1.2c-0.6,0-1.2,0.5-1.2,1.2S0.5,13,1.2,13h6.1c0.6,0,1.2-0.5,1.2-1.2S7.9,10.7,7.3,10.7z"/> 	<path style="fill:#626161" d="M18.1,10.7h-6.1c-0.6,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2S18.7,10.7,18.1,10.7z"/> 	<path style="fill:#626161" d="M28.8,10.7h-6.1c-0.6,0-1.2,0.5-1.2,1.2S22,13,22.7,13h6.1c0.6,0,1.2-0.5,1.2-1.2S29.5,10.7,28.8,10.7z"/> 	<path style="fill:#626161" d="M7.3,21.5H1.2C0.5,21.5,0,22,0,22.6c0,0.6,0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2C8.5,22,7.9,21.5,7.3,21.5z"/> 	<path style="fill:#626161" d="M18.1,21.5h-6.1c-0.6,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2C19.2,22,18.7,21.5,18.1,21.5z"/> 	<path style="fill:#626161" d="M28.8,21.5h-6.1c-0.6,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2C30,22,29.5,21.5,28.8,21.5z"/> </g> </svg>';
// $views-tile-active: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" 	 height="23.8px" viewBox="0 0 30 23.8"><g> 	<path style="fill:#93184a" d="M7.3,0H1.2C0.5,0,0,0.5,0,1.2s0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2S7.9,0,7.3,0z"/> 	<path style="fill:#93184a" d="M18.1,0h-6.1c-0.6,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2S18.7,0,18.1,0z"/> 	<path style="fill:#93184a" d="M22.7,2.3h6.1c0.6,0,1.2-0.5,1.2-1.2S29.5,0,28.8,0h-6.1c-0.6,0-1.2,0.5-1.2,1.2S22,2.3,22.7,2.3z"/> 	<path style="fill:#93184a" d="M7.3,10.7H1.2c-0.6,0-1.2,0.5-1.2,1.2S0.5,13,1.2,13h6.1c0.6,0,1.2-0.5,1.2-1.2S7.9,10.7,7.3,10.7z"/> 	<path style="fill:#93184a" d="M18.1,10.7h-6.1c-0.6,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2S18.7,10.7,18.1,10.7z"/> 	<path style="fill:#93184a" d="M28.8,10.7h-6.1c-0.6,0-1.2,0.5-1.2,1.2S22,13,22.7,13h6.1c0.6,0,1.2-0.5,1.2-1.2S29.5,10.7,28.8,10.7z"/> 	<path style="fill:#93184a" d="M7.3,21.5H1.2C0.5,21.5,0,22,0,22.6c0,0.6,0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2C8.5,22,7.9,21.5,7.3,21.5z"/> 	<path style="fill:#93184a" d="M18.1,21.5h-6.1c-0.6,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2C19.2,22,18.7,21.5,18.1,21.5z"/> 	<path style="fill:#93184a" d="M28.8,21.5h-6.1c-0.6,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2C30,22,29.5,21.5,28.8,21.5z"/> </g> </svg>';

$views-list: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><g id="list">
<path d="M8.3,4H2.2C1.5,4,1,4.5,1,5.2s0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2S8.9,4,8.3,4z"/>
<path d="M13.7,6.3h16.1c0.6,0,1.2-0.5,1.2-1.2S30.5,4,29.8,4H13.7c-0.6,0-1.2,0.5-1.2,1.2S13,6.3,13.7,6.3z"/>
<path d="M8.3,14.7H2.2c-0.6,0-1.2,0.5-1.2,1.2C1,16.6,1.5,17,2.2,17h6.1c0.6,0,1.2-0.5,1.2-1.2S8.9,14.7,8.3,14.7z"/>
<path d="M29.8,14.7H13.7c-0.6,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.1,1.2,1.1h16.1c0.6,0,1.2-0.5,1.2-1.2S30.5,14.7,29.8,14.7z"/>
<path d="M8.3,25.5H2.2C1.5,25.5,1,26,1,26.6s0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2C9.5,26,8.9,25.5,8.3,25.5z"/>
<path d="M29.8,25.5H13.7c-0.6,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2h16.1c0.6,0,1.2-0.5,1.2-1.2S30.5,25.5,29.8,25.5z"/>
</g></svg>';
$views-tile: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><g id="tile">
<path d="M8.3,4H2.2C1.5,4,1,4.5,1,5.2s0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2S8.9,4,8.3,4z"/>
<path d="M19.1,4H13c-0.6,0-1.2,0.5-1.2,1.2s0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2S19.7,4,19.1,4z"/>
<path d="M23.7,6.3h6.1c0.6,0,1.2-0.5,1.2-1.2S30.5,4,29.8,4h-6.1c-0.6,0-1.2,0.5-1.2,1.2S23,6.3,23.7,6.3z"/>
<path d="M8.3,14.7H2.2c-0.6,0-1.2,0.5-1.2,1.2C1,16.6,1.5,17,2.2,17h6.1c0.6,0,1.2-0.5,1.2-1.2S8.9,14.7,8.3,14.7z"/>
<path d="M19.1,14.7H13c-0.6,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2C20.3,15.2,19.7,14.7,19.1,14.7z"/>
<path d="M29.8,14.7h-6.1c-0.6,0-1.2,0.5-1.2,1.2c0,0.7,0.5,1.1,1.2,1.1h6.1c0.6,0,1.2-0.5,1.2-1.2S30.5,14.7,29.8,14.7z"/>
<path d="M8.3,25.5H2.2C1.5,25.5,1,26,1,26.6s0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2C9.5,26,8.9,25.5,8.3,25.5z"/>
<path d="M19.1,25.5H13c-0.6,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2C20.2,26,19.7,25.5,19.1,25.5z"/>
<path d="M29.8,25.5h-6.1c-0.6,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2h6.1c0.6,0,1.2-0.5,1.2-1.2S30.5,25.5,29.8,25.5z"/>
</g></svg>';

$op-icon-checkbox-inactive: '';
$op-icon-checkbox-active: '';

/*
 Buttons
 ---------------------------------------------------- */

$_opc-button-base-progress-scale : 0.8;
$_opc-button-base-border-radius : 0px;
$_opc-button-primary-link-color : $primary-button-color;
$_opc-button-primary-link-color-hover : darken($_opc-button-primary-link-color, 20%);
$_opc-button-secondary-link-color : $secondary-button-color;
$_opc-button-secondary-link-color-hover : darken($_opc-button-secondary-link-color, 20%);
$_opc-button-primary-border-radius : $_opc-button-base-border-radius;
$_opc-button-primary-color : rgba(#fff, 0.8);
$_opc-button-primary-color-hover : rgba(#fff, 1);
$_opc-button-primary-bg : $primary-button-color;
$_opc-button-primary-bg-hover : lighten($_opc-button-primary-bg, 10%);
$_opc-button-primary-progress-color : $progress-color;
$_opc-button-primary-success-color : $success-color;
$_opc-button-secondary-border-radius : $_opc-button-base-border-radius;
// $_opc-button-secondary-color : rgba(#000, 0.8);
// $_opc-button-secondary-color-hover : rgba(#000, 1);
// $_opc-button-secondary-bg : $secondary-button-color;
$_opc-button-secondary-color : $brand-color-1;
$_opc-button-secondary-color-hover : $brand-color-1;
$_opc-button-secondary-bg : $white;
$_opc-button-secondary-bg-hover : $white;
$_opc-button-secondary-progress-color : $progress-color;
$_opc-button-secondary-success-color : $success-color;
$_opc-button-loud-border-radius : $_opc-button-base-border-radius;
$_opc-button-loud-color : rgba(#fff, 0.8);
$_opc-button-loud-color-hover : rgba(#fff, 1);
$_opc-button-loud-bg : $loud-button-color;
$_opc-button-loud-bg-hover : lighten($loud-button-color, 10%);
$_opc-button-loud-progress-color : $progress-color;
$_opc-button-loud-success-color : $success-color;
$_opc-button-font-size-s : $_opc-font-size-s;
$_opc-button-font-size-m : $_opc-font-size-m;
$_opc-button-font-size-l : $_opc-font-size-l;
$_opc-button-font-size-xl : $_opc-font-size-xl;

/*
 
 Forms
 ---------------------------------------------------- */

$_opc-form-field-font-family : $_opc-base-font-family;
$_opc-form-field-font-size : 1.6rem;
$_opc-form-field-margin : 1rem;
$_opc-form-field-border : 1px solid $lightgray;
$_opc-form-field-border-hover : 1px solid $lightgray;
$_opc-form-field-border-focus : 1px solid $lightgray;
$_opc-form-field-border-invalid : 1px solid $error-color;
$_opc-form-field-border-valid : 1px solid $success-color;
$_opc-form-field-color : #000;
$_opc-form-field-color-invalid : $error-color;
$_opc-form-field-color-message-invalid: $error-color;
$_opc-form-field-color-valid : $success-color;
$_opc-form-field-color-message-valid : $success-color;
$_opc-form-field-placeholder-color : #ccc;
$_opc-form-field-bg : $lightgray;
$_opc-form-field-bg-hover : $lightgray; 
$_opc-form-field-bg-focus : $lightgray;
$_opc-form-field-label-color : #000;
$_opc-form-field-label-font-weight : 600;
$_opc-form-field-label-font-family : inherit;
$_opc-form-field-msg-font-size : 1.2rem;
$_opc-form-field-font-size-s : $_opc-font-size-s;
$_opc-form-field-font-size-m : $_opc-form-field-font-size;
$_opc-form-field-font-size-l : $_opc-form-field-font-size;
$_opc-form-field-font-size-xl : $_opc-form-field-font-size;

$_opc-radio-width : 2rem;
$_opc-radio-height : 2rem;
$_opc-radio-border : 1px solid #ccc;
$_opc-radio-border-hover : 1px solid #bbb;
$_opc-radio-border-focus : 1px solid #aaa;
$_opc-radio-border-checked : 1px solid #ccc;
$_opc-radio-border-radius : 2rem;
$_opc-radio-color : #000;
$_opc-radio-bg : #fff;
$_opc-radio-bg-hover : #fff;
$_opc-radio-bg-focus : #fff;
$_opc-radio-bg-checked : #fff;
$_opc-radio-icon : inline-svg($op-icon-check-svg, '#333');
$_opc-radio-icon-checked : inline-svg($op-icon-check-svg, '#333');
$_opc-radio-icon-size : 1.6rem;
$_opc-radio-label-font-size : 1.4rem;

$_opc-checkbox-width : 2rem;
$_opc-checkbox-height : 2rem;
$_opc-checkbox-border : 1px solid #ccc;
$_opc-checkbox-border-hover : 1px solid #bbb;
$_opc-checkbox-border-focus : 1px solid #aaa;
$_opc-checkbox-border-checked : 1px solid #ccc;
$_opc-checkbox-border-radius : 0.5rem;
$_opc-checkbox-color : #000;
$_opc-checkbox-bg : #fff;
$_opc-checkbox-bg-hover : #fff;
$_opc-checkbox-bg-focus : #fff;
$_opc-checkbox-bg-checked : #fff;
$_opc-checkbox-icon : inline-svg($op-icon-check-svg, '#333');
$_opc-checkbox-icon-checked : inline-svg($op-icon-check-svg, '#333');
$_opc-checkbox-icon-size : 1.6rem;
$_opc-checkbox-label-font-size : 1.4rem;

$_opc-select-icon: inline-svg($op-icon-angle-down-svg, '#000');

/*
 
 Links
 ---------------------------------------------------- */

$_opc-link-color: $brand-color-1;
$_opc-link-color-visited: $_opc-link-color;
$_opc-link-color-hover: lighten($_opc-link-color, 30%);
$_opc-link-color-active: lighten($_opc-link-color, 10%);
$_opc-link-underline-animation-duration: 0.2s;


button,
input,
optgroup,
select,
textarea {
  font-family: $_opc-base-font-family;
}

.opc-form-field .form-field-label{
    display: none!important;
}

// $_opc-price-currency-position : "before";

$_opc-extended-head-bg : transparent;
$_opc-extended-head-padding : 4rem 0;


:root {
    --clr-primary: #{$brand-color-1};
}
