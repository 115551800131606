

.opc-search {
	position: static;
    width: 100%;
    position: fixed;
	top: 0; 
	left: 0; 
	right: 0;
	bottom: 0;   
	z-index: 20000;
	visibility: hidden;
	opacity: 0;
    z-index: -100;
    .opc-mini-label{
        text-transform: none;
    }
    &.is-open {
		visibility: visible;
		opacity: 1;
        z-index: 20000;
	}
	.search-toggle {
		position: absolute;
		right: 0;
		top: 0;
		z-index: 3;
    	display: none;
    }
    .opc-search-bg {
		background: rgba(#fff, 0.98);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1; 
		display: block;
	}
	.opc-search-content {
		position: static;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		padding: 2rem;
	}

    @include mq($min-width: map-get($breakpoints, m)) {
		position: static;
		width: 100%;
		top: auto;
		left: auto;
		right: auto;
		bottom: auto;
		background: none;
		visibility: visible;
		opacity: 1;
		z-index: 20000;
		.opc-search-bg,
		.opc-search-toggle {
			display: none;
		}
		.opc-search-content {
			position: static;
			padding: 0;
		}
	}


	.opc-search-content {
        .opc-button[data-op-action="close"] {
            @include mq($min-width: map-get($breakpoints, m)) {
                display: none;
            }
        }
		.opc-search-query {
            border: 1px solid #ccc;
            display: flex;
            padding: 5px 0;
            .search-query {
                border: none;
                height: 3rem;
                padding-left: 1rem;
                padding-right: 1rem;
                &:focus {
                    outline:none;
                }
            }
            .search-filter {
                border: none;
                // border-left: 1px solid #ccc;
                // border-right: 1px solid #ccc;
                padding: 0 1rem;
                height: 3rem;
                &:focus {
                    outline:none;
                }
            }
            .search-btn {
                background: none;
                border: none;
                height: 3rem;
                width: 4rem; 
                flex-shrink: 0;
                color: $brand-color-1;
            }
		}
		.opc-search-results {
			display: none;
            padding: 0;
            position: relative;
            top: 0;
            border: none;
            background-color: $brand-color-2;
            min-height: 20rem;
            padding-left: 2.5rem;
            padding-right: 2.5rem;
            &::before {
                content:'';
                top: 10rem;
            }
            @include mq($min-width: 1025px) {
                padding-left: 0;
                padding-right: 0;
            }
            .opc-directsearch-suggest{
                background-color: $brand-color-2;
                color: $white;
                .directsearch-suggest-row{
                    .directsearch-suggest-column{
                        padding-left: 0;
                        width: 100%;
                        .directsearch-suggest-column-section{
                            .column-section-title{
                                color: $white;
                                font-size: 2.4rem;
                                font-weight: bold;
                                opacity: 1;
                                @include mq($min-width: 1025px) {
                                    // border-left: 1px solid $white;
                                }
                                margin-bottom: 0;
                                padding-bottom: 1rem;
                                // padding-left: 1rem;
                            }
                            &.no1{
                                margin-top: 3rem;
                            }
                            &.no2{
                                margin-top: 3.5rem;
                                margin-bottom: 3.5rem;
                                ul.column-section-list[data-op-type="simple"]{
                                    li{
                                        a{
                                            padding: .6rem 0;
                                            font-weight: 400;
                                            color: $white;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            @include mq($min-width: map-get($breakpoints, m)) {
               position: absolute;
               top: calc(100% + 2px);
               left: -60px;
               right: 0;
               width: calc(100% + 60px);
            }
            @include mq($min-width: map-get($breakpoints, l)) {
                top: 100%;
                left: 0;
                width: 100%;
            }
            &.is-open{
                &::after {
                    content: " ";
                    background-color: $brand-color-2;
                    position: absolute;
                    display: block;
                    top: 0;
                    bottom: 0;
                    right: -10000px;
                    width: 20000px;
                    z-index: -1;
                }
            }
            .opc-directsearch-message {
                height: 4rem;
                position: relative;
                z-index: 2;
                background: #fff;
                margin: 0.5rem;
                display: flex; 
                align-items: center;
                justify-content: center;
            }
		}
	}
	input {
		width: 100%;
    }
    
    &[data-op-type="fullscreen"]{
        background-color: $brand-color-2;
        color: $white;
        border: 1.2rem solid $white;
        .opc-button{
            &[data-op-action="close"]{
                .op-icon-cross{
                    color: $white;
                }
            }
        }
        .search-head{
            padding: 0 4rem;
            box-shadow: none;
            // background: url(/webportal/config/layout/themes/project/assets/img/Musik-Hug-white.svg) no-repeat 40px 26px;
            background-size: 160px;
        }
        .search-body{
            padding: 0 0 0 4rem;
        }
        .opc-search-results{
            &.is-open{
                &::before{
                    content: '';
                    width: 3rem;
                    height: 3rem;
                    background: transparent;
                    display: block;
                    border-radius: 50%;
                    margin: 0 auto;
                    position: absolute;
                    left: 50%;
                    top: 1rem;
                    margin-left: -1.5rem;
                    border: 2px solid transparent;
                    border-top: 2px solid #93184a;
                    -webkit-animation-name: rotater;
                    animation-name: rotater;
                    -webkit-animation-duration: 2s;
                    animation-duration: 2s;
                    -webkit-animation-iteration-count: infinite;
                    animation-iteration-count: infinite;
                }
                .opc-directsearch-suggest{
                    background-color: $brand-color-2;
                }
            }
        }
        .opc-search-query{
            background-color: $brand-color-2;
            border: none;
            border-bottom: 1px solid $white;
            .search-query{
                background-color: $brand-color-2;
                color: $white;
                font-weight: bold;
                font-size: 1.8rem;
            }
        }
        a,
        .opc-mini-label{
            color: $white!important;
            text-transform: none;
        }
        .opc-directsearch-suggest{
            background-color: $brand-color-2;
            color: $white;
            .directsearch-suggest-row{
                .directsearch-suggest-column{
                    padding-left: 0;
                    padding-right: 0;
                    .directsearch-suggest-column-section{
                        .column-section-title{
                            color: $white;
                            font-size: 2.4rem;
                            font-weight: bold;
                            opacity: 1;
                            border-left: none;
                            margin-bottom: 0;
                            padding-bottom: 1rem;
                            padding-left: 1rem;
                        }
                        &.no1{
                            margin-top: 3rem;
                        }
                        &.no2{
                            margin-top: 3.5rem;
                            margin-bottom: 3.5rem;
                            ul.column-section-list[data-op-type="simple"]{
                                li{
                                    a{
                                        padding: .6rem 0;
                                        font-weight: 400;
                                        color: $white;
                                    }
                                }
                            }
                        }
                        //
                        ul.column-section-list {
                            list-style: none;
                            margin: 0; 
                            padding: 0;
                            padding-left: 1rem;
                            border-left: none;
                            display: flex;
                            flex-wrap: wrap;
                            li {
                                flex-basis: 100%;
                                width: 100%;
                            }
                            &[data-op-type="article-image"] {
                                li {
                                    display: none;
                                    &:nth-child(-n+3) {
                                        display:flex;
                                    }
                                }
                                .list-item-name{
                                    font-size: 1.2rem;
                                    font-weight: bold;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                }
                                .list-item-image {
                                    width: 3.5rem;
                                    height: 3.5rem;
                                    margin-right: 1.2rem;
                                    background-color: $white;
                                    display: flex;
                                    align-items: center;
                                }
                                .list-item-art-nr{
                                    opacity: 1;
                                    font-size: 1.2rem;
                                }
                                .list-item-info {
                                    width: calc(100% - 9rem);
                                    color: $white;
                                }
                                .list-item-description {
                                    display: none;
                                    max-width: 100%;
                                }
                                a {
                                    display: flex;
                                    width: 100%;
                                    padding: .6rem 0;
                                    line-height: 1.2;
                                    align-items: center;
                                    .image {
                                        //background: #ccc;
                                        margin-right: 1rem;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center; 
                                    }
                                    .info {
                                        .name {
                                            display: block;
                                            font-weight: bold; 
                                            margin-bottom: 0.2em;  
                                        }
                                        .additional-name {
                                            display: block;
                                            font-size: 1.2rem; 
                                            opacity: 0.8;
                                            margin-bottom: 0.2em;
                                        }
                                        .description {
                                            display: block;
                                        }
                                        .price {
                                        }
                                    }
                                }
                            }
                            &[data-op-type="simple"] {
                                a {
                                    position: relative;
                                }
                                .CatStruct-Name {
                                }
                                .CatStruct-Name ~ .Amount {display:none;}
                                .CatStruct-Number {display:none;}
                            }
                            .moreItems {
                                flex-basis: 100%;
                                display: flex;
                                // justify-content: flex-end;
                                a {
                                    padding: 0;
                                    font-weight: bold;
                                    color: $white;
                                    display: inline;
                                    width: auto;
                                    span {
                                        margin: 0;
                                        font-size: 1.6rem;
                                    }
                                }
                            }
                        } 
                        //
                    }
                }
            }
        }
    }


}

ul.column-section-list {
    list-style: none;
    margin: 0; 
    padding: 0;
    // padding-left: 1rem;
    @include mq($min-width: 1025px) {
        // border-left: 1px solid $white;
    }
    display: flex;
    flex-wrap: wrap;
    li {
        @include mq($min-width: map-get($breakpoints, m)) {
            flex-basis: calc(100% / 3);
            width: calc(100% / 3);
            padding-right: 2rem;
            max-width:33.3333%;
        }
        a {
            display: block;
            padding: 1.5rem 0;
            &:hover {
                // background: #eee;
            }
            > span {
                display: block;
                &.Amount {
                    display: none;
                }
            }
        }
    }

    &[data-op-type="article-image"] {
        .list-item-name{
            font-size: 1.2rem;
            @include mq($min-width: 1025px) {
                font-size: 1.6rem;
            }
            font-weight: bold;
            text-overflow: ellipsis;
            @include mq($max-width: map-get($breakpoints, l)) {
                overflow: hidden;
                white-space: nowrap;
                display: block;
            }
        }
        .list-item-image {
            width: 3.5rem;
            height: 3.5rem;
            margin-right: 1.2rem;
            @include mq($min-width: 1025px) {
                width: 8rem;
                height: 8rem;
                margin-right: 2.5rem;
            }
            background-color: $white;
            display: flex;
            align-items: center;
        }
        .list-item-art-nr{
            opacity: 1;
            font-size: 1.2rem;
            @include mq($min-width: 1025px) {
                font-size: 1.6rem;
            }
        }
        .list-item-info {
            width: calc(100% - 9rem);
            color: $white;
        }
        .list-item-description {
            display: none;
            max-width: 100%;
        }


        a {
            display: flex;
            width: 100%;
            padding: 1rem 0;
            align-items: center;
            .image {
                //background: #ccc;
                margin-right: 1rem;
                display: flex;
                align-items: center;
                justify-content: center; 
            }
            .info {
                
                .name {
                    display: block;
                    font-weight: bold; 
                    margin-bottom: 0.2em;  
                }
                .additional-name {
                    display: block;
                    font-size: 1.2rem; 
                    opacity: 0.8;
                    margin-bottom: 0.2em;
                } 
                .description {
                    display: block;
                }
                .price {

                }



            }


        }
        



    }

    &[data-op-type="simple"] {
        a {
            position: relative;
        }
        .CatStruct-Name {
             
        }
        .CatStruct-Name ~ .Amount {display:none;}
        .CatStruct-Number {display:none;}
    }

    .moreItems {
        flex-basis: 100%;
        display: flex;
        // justify-content: flex-end;
        a {
            padding: 0;
            font-weight: bold;
            color: $white;
            display: inline;
            width: auto;
            span {
                margin: 0;
                font-size: 1.6rem;
            }
        }
    }
} 


.opc-directsearch-suggest .directsearch-suggest-column .CatStruct-Name {
    font-weight: normal;
}



@-webkit-keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
    }
  }
  @keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
    }
  }