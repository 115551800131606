/*###############################################

@Title: Sections
@Description:


###############################################*/


// namespace vars
$section-padding: 3.5rem;
// section layout styles




.section-wrapper {
    // @extend %mw;
    max-width: 100%;
    overflow: hidden;
    padding-top: $section-padding;
    padding-bottom: $section-padding;
	// padding-top: $section-padding / 3;
    // padding-bottom: $section-padding / 3;
    
    // @include mq($min-width: map-get($breakpoints, s)) {
    //     padding-top: $section-padding / 2;
    //     padding-bottom: $section-padding / 2;
    // }

    // @include mq($min-width: map-get($breakpoints, m)) {
    //     padding-top: $section-padding / 1.5;
    //     padding-bottom: $section-padding / 1.5;
    // }

    // @include mq($min-width: map-get($breakpoints, l)) {
    //     padding-top: $section-padding;
    //     padding-bottom: $section-padding;
    // }
    
    &.no-padding {
        padding-top: 0;
        padding-bottom: 0;
    }
    
	.section-inner {
		@extend %mw-inner;
		@include clearfix();
		
        
        // used for aside with content block
		.section-inner-row {
            
			@include mq($min-width: map-get($breakpoints, m)) {
				@include break;
				@include susy-clearfix;
				margin-left: -$_opc-grid-gutter-width;
				margin-right: -$_opc-grid-gutter-width;
			}
        
            .section-inner-aside {
                width: 100%;
                margin-bottom: 2rem;
                @include mq($min-width: map-get($breakpoints, m)) {
                    margin-bottom: 0;
                    @include span(3);
                }
            }
            .section-inner-content {
                width: 100%;
                @include mq($min-width: map-get($breakpoints, m)) {
                    @include span(9);
                }   
            }
		}
		
		.section-inner-content {
			
		}
    }
    
    &.full-width{
        .section-inner{
            max-width: map-get($breakpoints, xxl);
        }
        .section-inner-narrow {
            margin-left: auto;
            margin-right: auto;
            max-width: 1200px;
            // padding-left: 3rem;
            // padding-right: 3rem;
            @include mq($min-width: map-get($breakpoints, m)) {
                padding-left: 4rem;
                padding-right: 4rem;
              }
            @include mq($min-width: map-get($breakpoints, l)) {
                padding-left: 0rem;
                padding-right: 0rem;
              }
            #newsletter-form {
                padding:0 2rem;
                width: 100% !important;
                display: grid;
                gap: 1.2rem;
                @include mq($min-width: map-get($breakpoints, m)) {
                    padding:0;
                    width: 100% !important;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 150px;
                    gap: 1.2rem;
                }
            }
        }
    }

    &.section-hero{
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        padding-left: 0;
        padding-right: 0;
        // @include mq($min-width: map-get($breakpoints, l)) {
        //     margin-top: 1rem;
        // }
    }
    &.section-noten{
        padding-bottom: 0;
    }
    &.section-hero-slider{
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 80px;
        padding-left: 0;
        padding-right: 0;
        @include mq($min-width: map-get($breakpoints, l)) {
            margin-top: 1.2rem;
        }
    }
    &.section-recommendations-slider {
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        padding-left: 0;
        padding-right: 0;
        @include mq($min-width: map-get($breakpoints, l)) {
            margin-top: 1rem;
        }
        h2 {
            text-align: center;
            font-weight: bold;
            color: $brand-color-2;
            margin: 1rem 0 2.2rem;
        }
    }
    
    // &.lead-badges-section{
        //     padding-left: 0;
        //     padding-right: 0;
        // }
        &.section-teaser{
            margin-bottom: -5px;
            
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
        @include mq($min-width: map-get($breakpoints, l)) {
            margin-top: 1rem;
        }
    }

    &.sortiment-head{
        h1{
            margin-top: 6rem;
            font-weight: 400;
        }
    }

    &.cms-navigation{
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    &.section-article-list{
        .opc-cms-content-wrapper{
            margin: auto;
            @include mq($min-width: map-get($breakpoints, m)) {
                max-width: calc(100% / 12 * 8);
            }
            h1{
                font-size: 4.6rem;
                font-weight: normal;
                text-align: center;
            }
            p{
                font-size: 1.8rem;
            }
        }
    }
}

.page-{
    &struct,
    &article{
        section{
            >.section-inner{
                padding: 0;
                @include mq($min-width: map-get($breakpoints, m)) {
                    padding: 0;
                }
            }
        }
    }
}