$cms-topbar-color: #333 !default;
$cms-topbar-bg-color: #fbfbfb !default;
$cms-topbar-border: 1px solid #ccc !default;
$cms-search-border-color: #CCC !default;
$cms-search-bg-color: #FFF !default;
$cms-search-color: #000 !default;
$cms-topbar-button-bg-color: #333 !default;
$cms-text-color: #444747;

#opc-cms-fileupload{
    display: none;
}

[data-op-asyn] {
    height: 150px;
    background-color: #EFEFEF;
    animation-name: loadingColor;
    animation-duration: 2s;
    animation-iteration-count: infinite;

    &.finished {
        animation: none;
        background-color: transparent;
        height: auto;
    }

    > span {
        display: none;
    }
}

@keyframes loadingColor {
    0% {
        background-color: #EFEFEF;
    }
    50% {
        background-color: darken(#EFEFEF, 10%);
    }
    100% {
        background-color: #EFEFEF;
    }
}

[data-opc-cms="toggle"] {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 1rem;

    > span {
        margin-left: 1rem;
    }
}



.mh{
    &-hero-button {
        margin-left: 0!important;
        margin-right: 0!important;
        .column{
            position: relative;
            height: 60rem;
            float:none !important;
            padding-left: 0!important;
            padding-right: 0!important;
            img{
                max-height: 100%;
                // max-width: none;
                // position: absolute;
                // top: 0;
                // right: -30rem;
                display: block;
                &.desktop {
                    display: none;
                }
                &.mobile {
                    display:block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            @include mq($min-width: map-get($breakpoints,s)) {
                 height: 51rem;
                img{
                    // max-width: 100%;
                    // position: static;
                    &.desktop {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: 100% 0;
                    }
                    &.mobile {
                        display:none;
                    }
                 }
            }
            .text{
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                max-width: 130rem;
                margin: auto;
                top: 0;
                padding: 3rem;
                @include mq($min-width: map-get($breakpoints, m)) {
                    top: 8%;
                    padding: 4rem;
                }

                h1{
                    color: $brand-color-2;
                    margin-bottom: 13px;
                    font-weight: 400;
                }
                p{
                    color: $cms-text-color;
                }
                a.opc-button {
                    color: #fff;
                    font-size: 1.6rem;
                    margin-top: 35rem;
                    @include mq($min-width: map-get($breakpoints, s)) {
                        font-size: 2.4rem;
                        padding: 2.5rem 6rem;
                        color: #fff;
                        margin-top: 32rem;
                   }
                    @include mq($min-width: map-get($breakpoints, m)) {
                        font-size: 2.4rem;
                        padding: 2.5rem 6rem;
                        color: #fff;
                        margin-top: 18rem;
                   }
                }
            }
            &[contenteditable="true"]{
                .text{
                    margin-right: 3rem;
                }
            }
        }
    }
    &-bild-trio {
        margin-left:auto !important;
        margin-right: auto !important;
        max-width: 1200px;
        .column{
            position: relative;
            float:none !important;
            height: 60rem;
            padding-left: 0!important;
            padding-right: 0!important;
            padding-bottom: 1rem;
            img{
                max-height: 100%;
                // max-width: none;
                // position: absolute;
                // top: 0;
                // right: -30rem;
                display: block;
                &.desktop {
                    display: none;
                }
                &.mobile {
                    display:block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            @include mq($min-width: map-get($breakpoints,s)) {
                //  height: 51rem;
                img{
                    // max-width: 100%;
                    // position: static;
                    &.desktop {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: 100% 0;
                    }
                    &.mobile {
                        display:none;
                    }
                 }
            }
        }
        .image-duo-wrapper {
            display: flex;
            flex-wrap: wrap;
            margin-right: -1rem;
            margin-left: -1rem;
            .image-duo {
                width: 100%;
                &:first-child {
                    padding-bottom: 1rem;
                }
                @include mq($min-width: map-get($breakpoints,s)) {
                    width: calc(100% / 2);
                    padding: 1rem;
                    // padding-top: 2rem;
                }
                img{
                    // max-width: 100%;
                    // position: static;
                    &.desktop {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: 100% 0;
                    }
                    &.mobile {
                        display:none;
                    }
                }
            }
        }
    }
    &-bild-duo {
        margin-left: 0!important;
        margin-right: 0!important;
        .image-duo-wrapper {
            display: flex;
            flex-wrap: wrap;
            margin-left: -2rem;
            margin-right: -2rem;
            @include mq($min-width: map-get($breakpoints,s)) {
                height: 50rem;
            }
            .image-duo {
                width: 100%;
                position: relative;
                height: 100%;
                &:first-child {
                    padding-bottom: 1rem;
                }
                @include mq($min-width: map-get($breakpoints,s)) {
                    width: calc(100% / 2);
                    padding: 1rem;
                    // padding-top: 2rem;
                }
                img{
                    // max-width: 100%;
                    // position: static;
                    &.desktop {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        @include mq($min-width: map-get($breakpoints,l)) {
                            object-position: 100% 100%;
                        }
                    }
                    &.mobile {
                        display:none;
                    }
                 }
                 .text{
                     position: absolute;
                     right: 0;
                     bottom: 0;
                     left: 0;
                     max-width: 130rem;
                     margin: auto;
                     top: 0;
                     padding: 3rem;
                     @include mq($min-width: map-get($breakpoints, m)) {
                         top: 8%;
                         padding: 4rem;
                     }
     
                     h3{
                         color: $brand-color-2;
                     }
                     a{
                         color: $brand-color-1;
                         font-size: 1.6rem;
                         font-weight: bold;
                         @include mq($min-width: map-get($breakpoints, s)) {
                             font-size: 2.4rem;
                             color: $brand-color-1;
                        }
                         @include mq($min-width: map-get($breakpoints, m)) {
                             font-size: 2.4rem;
                             color: $brand-color-1;
                        }
                     }
                    }
                    @include mq($min-width: map-get($breakpoints, s)) {
                        &:first-child {
                            .text {
                                right: 0;
                                left: auto;
                            }
                        }
                    }
                   
            }
            &[contenteditable="true"]{
                .text{
                    margin-right: 3rem;
                }
            }
        }
    }
    &-text-1 {
        max-width: 1200px;
        margin-left: auto !important;
        margin-right: auto !important;
        .column{
            // padding: 0 3rem !important;
            
            // @include mq($min-width: map-get($breakpoints,s)) {
            //     padding: 0 4rem !important;
            // }

            @include mq($min-width: map-get($breakpoints,xl)) {
                padding-left: 0!important;
                padding-right: 0!important;
            }
            .text-p{
                h2{
                    color: $brand-color-2;
                    margin-bottom: 2.4rem;
                }
                p{
                    color: $cms-text-color;
                }
            }
        }
    }
    &-quote {
        max-width: 560px;
        margin-left: auto !important;
        margin-right: auto !important;
        .column{
            padding: 0 3rem !important;
            
            @include mq($min-width: map-get($breakpoints,s)) {
                padding: 0 4rem !important;
            }

            @include mq($min-width: map-get($breakpoints,xl)) {
                padding-left: 0!important;
                padding-right: 0!important;
            }
            
            p{
                color: $brand-color-1;
                margin-top:3.5rem;
                font-family: Playfair, "Times New Roman";
                text-align: center;
            }
        }
    }
    &-nl {
        h2{
            text-align: center;
            font-weight: bold;
            color: $brand-color-2;
            margin: 0 0 2.2rem;
        }
        input, button {
            font-size: 18px !important;
        }
    }
    &-text-2 {
        max-width: 1200px;
        margin-left: auto !important;
        margin-right: auto !important;
        .column{
            padding: 0 3rem !important;
            
            @include mq($min-width: map-get($breakpoints,s)) {
                padding: 0 4rem !important;
            }
            
            @include mq($min-width: map-get($breakpoints,xl)) {
                padding-left: 0!important;
                padding-right: 0!important;
            }
            h2{
                color: $brand-color-2;
                margin-bottom: 2.4rem;
                flex-basis: 100%;
            }
            .text-p-2{
                display: flex;
                flex-wrap: wrap;
                @include mq($min-width: map-get($breakpoints,l)) {
                    flex-wrap: nowrap;
                    margin-left: -1rem;
                    margin-right: -1rem;
                }
                p{
                    color: $cms-text-color;
                    @include mq($min-width: map-get($breakpoints,l)) {
                        width: calc(100% / 2);
                        padding: 0 1rem !important;
                    }
                }
            }
        }
    }
    &-hero-link {
        margin-left: 0!important;
        margin-right: 0!important;
        .column{
            position: relative;
            height: 37.5rem;
            float:none !important;
            padding-left: 0!important;
            padding-right: 0!important;
            img{
                max-height: 100%;
                // max-width: none;
                // position: absolute;
                // top: 0;
                // right: -30rem;
                display: block;
                &.desktop {
                    display: none;
                }
                &.mobile {
                    display:block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            @include mq($min-width: map-get($breakpoints,s)) {
                 height: 51rem;
                img{
                    // max-width: 100%;
                    // position: static;
                    &.desktop {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: 100% 0;
                    }
                    &.mobile {
                        display:none;
                    }
                 }
            }
            .text{
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                max-width: 130rem;
                margin: auto;
                top: 0;
                padding: 3rem;
                @include mq($min-width: map-get($breakpoints, m)) {
                    top: 8%;
                    padding: 4rem;
                }

                h1{
                    color: $brand-color-2;
                    margin-bottom: 13px;
                    font-weight: 400;
                }
                p{
                    color: $cms-text-color;
                }
                a{
                    color:  $brand-color-1;
                    font-size: 1.6rem;
                    position: absolute;
                    bottom: 20%;
                    &:hover {
                        color: $_opc-link-color-hover;
                    }
                    @include mq($min-width: map-get($breakpoints, s)) {
                        font-size: 2.4rem;
                        color:  $brand-color-1;
                   }
                    @include mq($min-width: map-get($breakpoints, m)) {
                        font-size: 2.4rem;
                        color:  $brand-color-1;
                   }
                }
            }
            &[contenteditable="true"]{
                .text{
                    margin-right: 3rem;
                }
            }
        }
    }
    &-three-teaser{
        margin-left: 0;
        margin-right: 0;
        .column{
            &.third {
                padding: .3rem 0;
                width: 100%!important;
                @include mq($min-width: map-get($breakpoints, m)) {
                    padding: 0 .6rem;
                    width: calc(100% / 3)!important;
                }
            }
            position: relative;
            img{
                width: 100%;
                @include mq($min-width: map-get($breakpoints, m)) {
                    width: auto;
                }
            }
            .text{
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                padding: 3rem;
                @include mq($min-width: map-get($breakpoints, m)) {
                    padding: 4rem;
                }
                h3{
                    color: $brand-color-1;
                    margin-bottom: 13px;
                    font-weight: 400;
                    font-size: 2.8rem!important;
                    @include mq($min-width: map-get($breakpoints, s)) {
                     font-size: 2.8rem!important;
                    }
                    @include mq($min-width: map-get($breakpoints, m)) {
                     font-size: 2.8rem!important;
                    }
                    @include mq($min-width: map-get($breakpoints, xl)) {
                     font-size: 4.6rem!important;
                    }
                }
                p{
                    font-size: 1.8rem!important;
                    @include mq($min-width: map-get($breakpoints, s)) {
                     font-size: 1.8rem!important;
                    }
                    @include mq($min-width: map-get($breakpoints, m)) {
                     font-size: 1.8rem!important;
                    }
                    @include mq($min-width: map-get($breakpoints, xl)) {
                        font-size: 2.4rem!important;
                    }
                    a{
                        color: $gray;
                        text-decoration: underline;
                        font-weight: bold;
                    }
                }
            }
            &[contenteditable="true"]{
                .text{
                    position: static;
                }
            }
        }
    }
    &-four-ma{
        margin-left: -1rem !important;
        margin-right: -1rem !important;
        display: flex;
        flex-wrap: wrap;
        .column{
            &.four {
                width: calc(100%/2) !important;
                padding:1rem;
                @include mq($min-width: map-get($breakpoints, m)) {
                    width: calc(100%/3) !important;
                }
                @include mq($min-width: map-get($breakpoints, l)) {
                    width: calc(100%/4) !important;
                }
            }
            .text{
                color:$cms-text-color;
                h4 {
                    span {
                        display: block;
                        font-weight: 400;
                    }
                }
                p {
                    span {
                        display: block;
                        color:$brand-color-2;
                    }
                    a {
                        color:$brand-color-2;
                    }
                }
            }
            &[contenteditable="true"]{
                .text{
                    position: static;
                }
            }
        }
    }
    &-stao {
        .column {
            h3 {
                font-size: 2.4rem;
                color:$brand-color-2;
            }
            p {
                padding-bottom: 1rem;
                color:$brand-color-2;
                span {
                    display: block;
                    &:first-child {
                        font-weight: bold;
                    }
                }
            }
            .o-days {
                display: flex;
                flex-wrap: wrap;
                > p {
                    flex-basis: 50%;
                }
            }
        }
        .toggle-area{
            margin-bottom: 0;
            >h4{
                display: block;
                position: relative;
                border-top: 1px solid $brand-color-1;
                border-bottom: 1px solid $brand-color-1;
                padding: 10px 0;
                color: $brand-color-2;
                cursor: pointer;
                &::after{
                    font-size: 2.4rem;
                    content: "+";
                    position: absolute;
                    right: 0;
                    color: $brand-color-1;
                    line-height: 20px;
                    margin-right: 15px;
                }
            }
            .content-toggled {
                display: none;
            } 
            &.is-open{
                >h4{
                    display: block;
                    border-bottom: none;
                    &::after{
                        content: "-";
                    }
                }
                .content-toggled {
                    display: block;
                }
            }
        }
    }
    &-lead-badges {
        display: flex;
        flex-wrap: wrap;
        @include mq($min-width: map-get($breakpoints, s)) { 
            margin-left: -1rem !important;
            margin-right: -1rem !important;
        }
        .lead-badge-wrapper {
            width: 100%;
            @include mq($min-width: map-get($breakpoints, s)) {
                flex-basis: calc(100% / 2);
                max-width: 50%;
                padding: 0 1rem;
                &:nth-last-child(-n+2){
                  .text {
                    border-bottom: 1px solid #000;
                  }
                }
              }
            @include mq($min-width: map-get($breakpoints, m)) {
                flex-basis: calc(100% / 3);
                max-width: 50%;
                padding: 0 1rem;
                &:nth-last-child(-n+3){
                  .text {
                    border-bottom: 1px solid #000;
                  }
                }
              }
            @include mq($min-width: map-get($breakpoints, l)) {
                flex-basis: calc(100% / 4);
                max-width: 50%;
                padding: 0 1rem;
                &:nth-last-child(-n+4){
                  .text {
                    border-bottom: 1px solid #000;
                  }
                }
              }
            .text {
              width: 100%;
              padding: 1.5rem 0;
              padding-left: 0.5rem;
              border-top: 1px solid #000;
              margin:0;
              color: $brand-color-2;
              > a {
                  color: $brand-color-2;
              }
              
            }
            &:last-child {
              .text {
                border-bottom: 1px solid #000;
              }
            }
          }
    }
}

.opc-cms-content{
    overflow: hidden;
    .row{
        margin-left: 0;
        margin-right: 0;
    }
    &.wide {
        overflow: visible;
    }
    .cms-snippet[data-op-cms-snippet="article-slider"] {
        > .slider-source {
            display: none;
        }
    }
    &[data-editmode="true"] {
        .cms-snippet[data-op-cms-snippet="article-slider"] {
            > .slider-source {
                display: block;
            }
        }
    }
    .cms-slider {
        .opc-article-list {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            @include mq($min-width: map-get($breakpoints, l)) {
                display: block;
                flex-wrap: wrap;
                overflow-x: none;
            }
            &[data-op-tile-col="4"] {
                >li {
                    flex-basis:45%;
                    max-width: 45%;
                    flex-shrink: 0;
                    @include mq($min-width: map-get($breakpoints, s)) {
                        flex-basis: span(7);
                        max-width: span(7);
                    }
                    @include mq($min-width: map-get($breakpoints, m)) {
                        flex-basis: span(4);
                        max-width: span(4);
                    }
                    @include mq($min-width: map-get($breakpoints, l)) {
                        flex-basis: span(3);
                        max-width: span(3);
                    }
                    @include mq($min-width: map-get($breakpoints, xl)) {}
                }
            }
        }
    }

}

.opc-cms-content-wrapper {
    &.middle {
        .column {
            padding:0 !important;
        }
        .lead-badge-wrapper {
            &.column{
                @include mq($min-width: map-get($breakpoints, s)) {
                    padding-left: 1rem !important;
                    padding-right: 1rem !important;
                }
            }
        }
    }
    &.wide {
        overflow: visible;
        margin-left: -2rem;
        margin-right: -2rem;
        @include mq($min-width: map-get($breakpoints, m)) {
            margin-left: -4rem;
            margin-right: -4rem;
        }
    }
    .spacer {
        margin:0;
    }
}