/*###############################################

@Title: Flags
@Description:
This file contains the article flag styles

###############################################*/

$_opc-flags-new-bg: #5C0202;
$_opc-flags-new-color: $white;
$_opc-flags-sale-bg: #5C0202;
$_opc-flags-sale-color: $white;
$_opc-flags-bestseller-bg: #5C0202;
$_opc-flags-bestseller-color: $white;
$_opc-flags-size: auto !default;
$_opc-flags-font-size: 1.6rem !default;
$_opc-flags-occ-bg: #3C7460;
$_opc-flags-occ-bg-z: #6997AF;
$_opc-flags-occ-premium-bg:#A88958;
$_opc-flags-occ-color: $white;
$_opc-flags-color-perc: #BE3A24;

.opc-flags {
    display: flex;
    flex-direction: column;
    .flag-news,
    .flag-free1,
    .flag-free2,
    .flag-occ {
        // aus cdn:
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 6rem;
        text-align: center;
        // font-weight: bold;
        font-weight: normal;
        text-transform: uppercase;
        font-size: 1.4rem;
        white-space: pre-line;
        margin-right: 0;
        // ende aus cdn
        transform: none;
        width: auto;
        height: auto;
        border-radius: 0;
        background-color: #5C0202;
        color: $white;
        padding-left: 1rem;
        margin-bottom: 0;

        span{
            line-height: 1;
            height: auto;
            position: relative;
            padding: .6rem 1.5rem;
            text-transform: none;
            font-size: 1.6rem;
        }

        [data-op-article-list-type="list"] & {
            transform: none;
            width: auto;
            height: auto;
            border-radius: 0;
            color: $white;
            padding-left: 1rem;
            margin-bottom: .6rem;
        }
        span{
            line-height: 1;
            height: auto;
            position: relative;
            padding: .6rem 1.5rem;
            text-transform: none;
            font-size: 1.6rem;
        }
        svg {
            font-size: 4rem;
            margin: 0;
            flex-shrink: 0;
        }
    }
    .flag-news {
        background: $_opc-flags-new-bg;
        color: $_opc-flags-new-color;
    }
    .flag-free1 {
        background: $_opc-flags-sale-bg;
        color: $_opc-flags-sale-color;
    }
    .flag-free2 {
        background: $_opc-flags-bestseller-bg;
        color: $_opc-flags-bestseller-color;
    }
    .flag-occ {
        background: $_opc-flags-occ-bg;
        color: $_opc-flags-occ-color;
        &.M {
            background:$_opc-flags-occ-premium-bg;
        }
        &.Z {
            background:$_opc-flags-occ-bg-z;
        }
        &.premium {
            background: $_opc-flags-occ-premium-bg;
        }
    }
    .flag-variant {
        background: none;
        margin-top: 1.4rem;
        > a {
            color: $gray;
        }
    }
    &[data-op-layout="icon"] {
        svg {
            font-size: $_opc-flags-size;
            [data-op-article-list-type="list"] & {
                font-size: $_opc-flags-size / 1.5;
            }
        }
        .flag-news {
			background: transparent !important;
            color: $_opc-flags-new-bg;
        }
        .flag-free1 {
			background: transparent !important;
            color: $_opc-flags-sale-bg;
        }
        .flag-free2 {
			background: transparent !important;
            color: $_opc-flags-bestseller-bg;
        }
        .flag-occ {
			background: transparent !important;
            color: $_opc-flags-occ-bg;
        }
    }
    &[data-op-layout="text"] {
        svg {
            display: none;
        }
    }
}

.flagperc {
    position: absolute;
    left: 0;
    top: 0;
    background: $_opc-flags-color-perc;
    padding: 0.5rem 1rem;
    color:#fff;
    z-index: 9;
    &.right {
        right: 0;
        left: auto;
    }
}
.flagfortissimo {
    position: absolute;
    left: 0;
    top: 0;
    background-color: $white;
    padding: 0.5rem 1rem;
    color:#fff;
    z-index: 9;
    > img {
        height: 3rem !important;
        width: auto;
    }
}