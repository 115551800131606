.opc-search {
    .is-mobile & {
        display: none;

        .search-query {
            height: 6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            input {
                border: 1px solid #ccc;
                width: 100%;
                padding: 0 1rem;
            }
        }
    }

    .search-open &[data-op-type="search-back"]  {
        visibility: visible;
        z-index: 1000;
        opacity: 1;
        pointer-events: all;
        left: 0;
        top: 0;
        padding: 2rem;
        position: fixed;
        background: rgba(#fff, 0.98);
        width: 100%;
        height: 100%;
        display: block;
    }

    position: relative;
    width: 100%;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    background: none;
    visibility: visible;
    opacity: 1;
    z-index: 20000;
    &.is-open {
        visibility: visible;
        opacity: 1;
        z-index: 20000;
    }

    .search-toggle {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 3;
        display: none;
    }

    .opc-search-bg {
        background: rgba(#fff, 0.98);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        display: none;
    }

    @include mq($min-width: map-get($breakpoints, m)) {
        .opc-search-bg,
        .opc-search-toggle {
            display: none;
        }

        .opc-search-content {
            position: relative;
            padding: 0;
        }
    }

    .opc-search-content {
        position: relative;
        padding: 0;

        .opc-button[data-op-action="close"] {
            display: none;
            @include mq($min-width: map-get($breakpoints, m)) {
                display: none;
            }
        }

        .opc-search-query {
            border: 1px solid #ccc;

            display: flex;
            padding: 0.5rem 0;

            .search-query {
                border: none;
                height: 3rem;
                padding-left: 1rem;
                padding-right: 1rem;
                &:focus {
                    outline:none;
                }
            }
            .search-filter {
                border: none;
                border-left: 1px solid #ccc;
                border-right: 1px solid #ccc;
                padding: 0 1rem;
                height: 3rem;

                &:focus {
                    outline:none;
                }
            }

            .search-btn, .reset-btn, .back-btn {
                background: none;
                border: none;
                height: 3rem;
                width: 4rem;
                flex-shrink: 0;
                color: $brand-color-1;
            }

            .back-btn {
                display: none;
                .is-mobile & {
                    display: block;
                }
            }

        }

        .opc-search-results {
            display: none;
            padding: 0;
            position: absolute;
            top: 100%;
            width: 100%;

            .opc-directsearch-message {
                height: 4rem;
                position: relative;
                z-index: 2;
                background: #eee;
                margin: 0.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    input {
        width: 100%;
    }

    // fullscreen search
    &[data-op-type="fullscreen"] {

        position: fixed;
        background: rgba(#fff, 0.98);
        width: 100%;
        height: 100%;
        visibility: hidden;
        opacity: 0;
        z-index: -10;
        pointer-events: none;

        .search-open & {
            visibility: visible;
            z-index: 1000;
            opacity: 1;
            pointer-events: all;
            display: block;
        }

        .search-head {
            height: 12rem;
            @extend %mw;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            box-shadow: 0 0 6px #ccc;
            z-index: 20000;
            position: relative;
            padding: 0 2rem;

            button {
                color: #000;
                margin-right: 0;
                margin-right: -1rem;
                cursor: pointer;
                width: 4rem;
                height: 4rem;
                padding-left: 0;
                padding-right: 0;
                background: transparent;

                svg {
                    font-size: 3.2rem;
                    margin: 0;
                    color: #000;
                }
            }
        }

        .search-query {
            height: 6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            input {
                height: 5rem;
                border: 1px solid #ccc;
                width: 100%;
                padding: 0 1rem;
            }
        }

        .search-body {
            position: absolute;
            top: 12rem;
            bottom: 0;
            left: 0;
            right: 0;
            overflow-y: auto;
            padding: 2rem;

            .opc-directsearch-suggest {
                background: none;

                .directsearch-suggest-row {
                    margin-left: -1rem;
                    margin-right: -1rem;
                }
                .directsearch-suggest-column {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
        }

        .opc-search-results {
            margin-bottom: 4rem;
        }


        .search-slider {
            display: flex;
            flex-wrap: nowrap;
            max-width: 100%;
            overflow-x: auto;
            margin-bottom: 2rem;

            .search-slider-item {
                margin-right: $_opc-grid-gutter-width;
                width: 12rem;
                flex-shrink: 0;
            }

            img {
                width: 100%;
            }
        }

        .search-list {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                border-bottom: 1px solid #eee;

                a {
                    display: block;
                    padding: 1rem 0;
                    color: #000;
                }

            }
        }
    }
}


.column-section-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        a {
            display: block;
            padding: 1.5rem 0;

            // &:hover {
            //     background: #eee;
            // }

            > span {
                display: block;
            }
        }
    }

    &[data-op-type="article-image"] {
        .list-item-image {
            width: 8rem;
            margin-right: 1rem;
        }
        .list-item-info {
            width: calc(100% - 9rem);
        }
        .list-item-description {
            max-width: 100%;
        }

        a {
            display: flex;
            width: 100%;
            padding: 1rem 0;

            .image {
                margin-right: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .info {
                .name {
                    display: block;
                    font-weight: 600;
                    margin-bottom: 0.2em;
                }
                .additional-name {
                    display: block;
                    font-size: 1.2rem;
                    opacity: 0.8;
                    margin-bottom: 0.2em;
                }
                .description {
                    display: block;
                }
            }
        }
    }

    &[data-op-type="simple"] {
        a {
            position: relative;
        }
        .CatStruct-Name ~ .Amount {display:none;}
        .CatStruct-Number {display:none;}
    }

    .moreItems {
        a {
            padding: 1rem;
            font-weight: bold;

            span {
                margin: 0;
                font-size: 1.2rem;
            }
        }
    }

}
