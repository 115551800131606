/*###############################################

@Title: Page Favorites
@Description:


###############################################*/

.page-favorites,
.page-multi-favorites {
    .favorites-head {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        position: relative;
        .favorites-selection {
            flex-grow: 1;
            .opc-form-field {
                margin-bottom: 0;
                .headline-select {
                    font-size: 2.4rem;
                    padding-left: 2rem !important;
                }
            }
        }
        .favorites-actions {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            #rename-form {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                display: flex;
                background: #fff;
                .opc-form-field,
                input {
                    width: 100%;
                    border: none;
                }
				.opc-form-field {
					input {
                        font-size: 2.4rem;
						border-bottom: 2px solid #ccc !important;
						padding: 2rem;

					}
					.form-field-note {
                        margin: 1rem 1.5rem 0rem;
						.ff-error {
							display: block;
						}
					}
					.form-field-error {
                        margin: 0rem 1.5rem 0.75rem;
						display: block;
					}
				}
            }
        }
        .opc-form-field {
            margin-bottom: 0;
            .headline-select {
                width: 100%;
                border: none !important;
                ;
                background: transparent;
                border-bottom: 2px solid #ccc !important;
                padding: 1rem 0 !important;
                margin-bottom: 0;
                appearance: none;
                height: auto !important;
                line-height: 1.2 !important;
                background-image: inline-svg($op-icon-angle-down-svg, '#000');
                background-position: calc(100% - 1rem) center;
                background-size: 2rem;
                background-repeat: no-repeat;
                @include fluid-type($_opc-font-min-width, $_opc-font-max-width, $_opc-font-size-h1, $_opc-font-size-h1-max);
                &::-ms-expand {
                    display: none;
                }
            }
        }
        h4 {
            font-size: 1.4rem;
            margin-bottom: 1rem;
            padding: 0;
        }
    }
    .favorites-desc {
        margin-bottom: 4rem;
        background: #f8f8f8;
        padding: 2rem;
        h4 {
            margin-bottom: 0.5em;
        }
    }
    .article-specs {
        display: none !important;
        @include mq($min-width: map-get($breakpoints, m)) {
                display: block !important;
        }
    }

    .opc-more-button {
        .more-button-content {
            right: 0;
            left: auto;
            .opc-favorite-button {
                &::after {
                    content:none;
                }
            }
        }
    }
    .opc-button-wrapper {
        justify-content: center;
        button {
            margin-left: 0 !important;
        }
        @include mq($min-width: map-get($breakpoints, m)) {
            justify-content: flex-end;
        }
        span {
            white-space: pre-wrap;
        }
    }

}