/*###############################################

@Title: Filter
@Description:
This file contains the filter styles

###############################################*/

$_opc-filter-tag-bg: $brand-color-1;
$_opc-filter-tag-color: $white;
$_opc_filter-toggle-bg: $brand-color-1;
$_opc_filter-toggle-bg-hover: $brand-color-1;
$noUi-css-prefix: opc-rangeslider !default;

.opc-filter-set, .opc-item-list {
	.item-marker {
		width: 1.2em;
		height: 1.2em;
		display: none;
		margin-right: 1rem;
	}
}

// filter set wrapper
.opc-filter-set {
    position: relative;
    @include mq($min-width: map-get($breakpoints, m)) {
        // margin-top: 6rem;
    }
    margin-bottom: 0;
    .filter-toggle {
        background: $_opc_filter-toggle-bg;
        padding: 1rem 2.5rem;
        text-align: center;
        margin-bottom: 2rem;
        color: $white;
        cursor: pointer;
        display: block;
	    position: relative;
        // left: 50%;
        // transform: translateX(-50%);
		// bottom: 0;
		z-index: 3;
        white-space: nowrap;
        align-items: center;
        svg{
            margin-right: 13px;
            font-size: 28px;
            &.op-icon-filter{

            }
            &.op-icon-cross{
                display: none;
            }
        }
        @include mq($min-width: map-get($breakpoints, l)) {
            display: inline-flex;
            position: relative;
            left: 0;
            transform: none;
            background-color: $white!important;
            color: $brand-color-2;
            padding-left: 0;
            svg{
                color: $brand-color-2;
            }
            &::after{
                display: none;
            }
        }
        &:hover {
            background: $_opc_filter-toggle-bg-hover;
        }
        i {
            margin-right: 1rem;
        }
    } 
    &.is-open{
        .filter-toggle{
            position: relative;
            background-color: $white;
            color: $brand-color-1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include mq($min-width: map-get($breakpoints, l)) {
                justify-content: flex-start;
                font-weight: 400;
            }
            padding: 0;
            font-weight: 700;
            &::after{
                content: "";
                display: inline-block;
                width: 30px;
            }
            svg{
                color: $brand-color-1;
                &.op-icon-filter{
                    display: none;
                }
                &.op-icon-cross{
                    display: inline-block;
                    font-size: 3.2rem;
                }
            }
        }
    }
    .filter-set-selection {
        margin-top: .5rem;
    }
    // to reset the filters
    .filter-reset {
        display: inline-block;
        padding: 0;
        opacity: 0.6;
        color: $text-color;
        &:hover {
            opacity: 1;
        }
    } // filter set list to define gutter and padding of the filters
    .filter-set-list {
        flex-direction: column;
        display: none;
        @include mq($min-width: map-get($breakpoints, m)) {
            display: none !important;
        }
        @include mq($min-width: map-get($breakpoints, l)) {
            // display: flex !important;
        }
        &.is-open {
            display: flex;
        }
        .filter-set-list-item {
            padding: 0;
            width: 100%;
            max-width: 100%;
        }

		.opc-catsort{
			display: flex;
			align-items: center;
            padding: 7px 0 7px 0;
            border-top: 1px solid $brand-color-1;
            border-bottom: 1px solid $brand-color-1;
			justify-content: space-between;
			font-size: 1.6rem;
			label{
				font-weight: 400;
				margin-bottom: 0;
                display: none;
			}
			select{
				flex-grow: 1;
				font-family: Geogrotesque;
				font-weight: 600;
				border: none;
				appearance: none;
				background-image: inline-svg($op-icon-angle-down-svg,$gray);
				background-size: 1.6rem;
				background-position: calc(100% - 1rem) center;
				background-repeat: no-repeat;
				background-color: #fff;
				padding: .5rem;
				&:focus{
				 outline-color: transparent!important;
				 outline: none!important;
				 box-shadow: none!important;
				}
			}
			// @include mq($min-width: map-get($breakpoints, m)) {
			// 	display: none;
			// }
		}
    } // horizontal filter set
    &[data-op-layout="horizontal"] {
        .filter-set-list {
            justify-content: flex-start;
            flex-wrap: wrap;
            flex-direction: row;
            margin-left: -$_opc-grid-gutter-width;
            margin-right: -$_opc-grid-gutter-width;
            display: none;
            .article-list-sort{
                padding: 0 0 20px 0;
                flex-basis: span(12);
                width: span(12);
                max-width: span(12);
                h3{
                    flex-basis: 100%;
                    margin-left: 6px;
                    font-size: 1.6rem;
                    font-weight: 400;
                    color: $brand-color-1;
                }
                .filter-set-list-item {
                    flex-basis: span(12);
                    width: span(12);
                    max-width: span(12);
                }
                @include mq($min-width: map-get($breakpoints, s)) {
                    flex-basis: span(6);
                    width: span(6);
                    max-width: span(6);
                }
                @include mq($min-width: map-get($breakpoints, l)) {
                    padding: 0 0 20px 0;
                    flex-basis: span(4);
                    width: span(4);
                    max-width: span(4);
                }
            }
            .filter-list{
                padding: 0 0 20px 0;
                flex-basis: span(12);
                width: span(12);
                max-width: span(12);
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                flex-direction: row;
                margin-top: 20px;
                @include mq($min-width: map-get($breakpoints, s)) {
                    margin-top: 0;
                }
                h3{
                    flex-basis: 100%;
                    margin-left: 6px;
                    font-size: 1.6rem;
                    font-weight: 400;
                    color: $brand-color-1;
                }
                @include mq($min-width: map-get($breakpoints, s)) {
                    // flex-basis: span(6);
                    // width: span(6);
                    // max-width: span(6);
                }
                @include mq($min-width: map-get($breakpoints, l)) {
                    padding: 0 0 20px 0;
                    flex-basis: calc(100% / 1.5);
                    width: calc(100% / 1.5);
                    max-width: calc(100% / 1.5);
                }
            }
            &.is-open {
                display: flex!important;
                // @include mq($min-width: map-get($breakpoints, l)) {
                //     position: absolute;
                //     left: 0;
                //     right: 0;
                //     top: 0;
                //     background: #fff;
                //     z-index: 9;
                // }
            }
            .filter-set-list-item {
                padding: 0 $_opc-grid-gutter-width;
                @include mq($min-width: map-get($breakpoints, s)) {
                    flex-basis: span(6);
                    width: span(6);
                    max-width: span(6);
                }
                @include mq($min-width: map-get($breakpoints, l)) {
                    // flex-basis: span(4);
                    // width: span(4);
                    // max-width: span(4);
                }
                &:nth-of-type(1){
                    [data-op-style="dropdown"] {
                        .filter-head {
                            border-top: 1px solid $brand-color-1!important;
                        }
                    }
                }
                @include mq($min-width: map-get($breakpoints, s)) {
                    &:nth-of-type(2){
                        [data-op-style="dropdown"] {
                            .filter-head {
                                border-top: 1px solid $brand-color-1!important;
                            }
                        }
                    }
                }
            }
        }
    }
    .custom-mobile-filter-set {
        // display: none;
	    // position: fixed;
        // right: 0;
        // left: 0;
		// bottom: 0;
		// z-index: 99999;
        margin-top:2rem;
		background: transparent;
		.buttonwrapper {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            padding-bottom: 10px;
            > a {
                font-size: 1.4rem;
                padding: 1rem 2rem;
                flex-grow: 1;
                text-align: center;
            }
            > button.jump-to-list {
                margin-bottom: 2rem;
                text-align: center;
                font-size: 1.6rem;
                font-weight: 400;
                padding: 1rem 2rem;
                background: $brand-color-1;
                height: auto;
                line-height: normal;
                color: $white;
                transform: none;
                height: 48px;
                &::after{
                    display: none;
                }
            }
        }
        @include mq($min-width: map-get($breakpoints, m)) {
            display: none;
        }

	}
}

// filter itself
.opc-filter {
    margin-bottom: 0;
    width: 100%;
    position: relative;
    z-index: 10; // filter head
    .filter-head {
        padding: 10px 0;
        svg {
            font-size: 1.4em;
        }
    } // filter body
    .filter-body {
        margin-top: -1px;
        background: #fff;
        border: 1px solid #ccc;
        width: 100%; // filter body search
        .filter-body-search {
            padding: 0;
            input {
                border: none;
                background: rgba(#ccc, 0.1);
                padding: 1rem 2rem;
                width: 100%;
            }
        } // filter body inner (contains the filter ocntent with a max height)
        .filter-body-inner {
            border-top: 1px solid #ccc;
            overflow: hidden;
            overflow-y: auto;
            max-height: 200px;
        } // filter reset
        .filter-body-reset {
            border-top: 1px solid #ccc;
            padding: 0.5rem 2rem;
            a {
                color: $text-color;
                opacity: 0.6;
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
    /*
    Types
  */
    // range filter
            &[data-op-type="range"]{
                .filter-head{}
                .filter-body{
                    .filter-body-inner{
                        .slider-values{
                            >div{
                                .combined-from,
                                .combined-to{
                                    display: none;
                                }
                                .range-separator{
                                    padding: 0 1rem;
                                }
                                order: 2;
                            }
                            background: transparent;
                            display: flex;
                            // bottom: 8rem;
                            input{
                                &[type="text"]{
                                    width: 9rem;
                                    padding: .5rem;
                                    border: none;
                                    border-bottom: 1px solid #dedede;
                                    text-align: center;
                                    &:nth-of-type(1) {
                                        order: 1;
                                    }
                                    &:nth-of-type(2) {
                                        order: 3;
                                    }
                                }
                            }
                        }
                        button.opc-button.filter-button{
                            float: right;
                            margin-top: 1rem;
                            padding: 1.5rem;
                        }
                    }
                }
            }
    // search filter
    &[data-op-type="search"] {}
    // classic filter with search and a list
    &[data-op-type="select"] {}
    /*
    Styles
  */
    // filter in dropdown
    &[data-op-style="dropdown"] {
        background: #fff;
        .filter-head {
            padding: 10px 0;
            border: none;
            border-bottom: 1px solid $brand-color-1;
            position: relative;
            svg {
                position: absolute;
                right: 1rem;
                top: 50%;
                transform: translateY(-50%);
                font-size: 1.6rem;
            }
        }
        .filter-body {
            margin-top: -1px;
            background: #fff;
            border: none;
            border-bottom: 1px solid $brand-color-1;
            position: relative;
            @include mq($min-width: map-get($breakpoints, s)) {
                position: absolute;
            }
            top: 100%;
            left: 0;
            width: 100%;
            display: none;
            .filter-body-search {
                padding: 0;
                input {
                    border: none;
                    background: rgba(#ccc, 0.1);
                }
            }
        }
    } // filter classic
    &[data-op-style="classic"] {
        .filter-head {
            font-size: 2.2rem;
            svg {
                display: none;
            }
        }
    }
    &.is-open {
        z-index: 12;
        .filter-head {
            &::after {
                transform: translateY(-50%) rotate(-180deg);
            }
            .filter-head-title{
                color: $brand-color-2;
            }
            border-color: transparent;
            border-bottom-color: transparent;
        }
        .filter-body {
            display: block;
            border-color: transparent;
            border-top-color: transparent;
            padding: 0 10px;
            left: -10px;
            right: -10px;
            width: auto;
            box-shadow: 0 3rem 3rem rgba(0, 0, 0, .2);
            .filter-body-search{
                border-top: 1px solid transparent;
            }
            .filter-body-inner{
                border-top-color: transparent;
            }
        }
    }
}

// filter tags
.filter-tag-list {
    margin-bottom: 1rem;
    .tag {
        padding: 0 1rem 0 3.5rem;
        display: inline-block;
        margin-right: 0.5rem;
        margin-bottom: 1rem;
        background: $_opc-filter-tag-bg;
        color: $_opc-filter-tag-color;
        border-radius: 0;
        font-size: 1.6rem;
        position: relative;
        .tag-type {
            opacity: 1;
            margin-right: 0;
            font-weight: 400;
            padding: 10px 0;
        }
        .tag-value {
            font-weight: 400;
			display: inline-block;
			align-items: baseline;
            padding: 10px 0;
        }
        .tag-remove {
            margin-left: 1rem;
            opacity: 1;
            font-weight: bold;
            padding: 0 1rem 0 1rem;
            height: 100%;
            position: absolute;
            top: 0;
            right: auto;
            left: 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            text-indent: -9999px;
            overflow: hidden;
            background-image: inline-svg($op-icon-cross-svg, '#fff');
            background-size: 14px;
            background-repeat: no-repeat;
            background-position: calc(100% - 6px) center;
            &:hover {
            }
            &::before {
                content: 'x';
            }
        }
    }
}

// select list für filter
.select-list {
    list-style: none;
    margin: 0;
    margin-top: 1rem;
    padding: 0;
    li {
        input {
            position: absolute;
            opacity: 0;
            visibility: hidden;
        }
        label {
            display: block;
            padding: 5px 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            &:hover {
                color: $brand-color-1;
                background-color: transparent;
            }
            &::before {
                content: "";
                display: inline-block;
                width: 12px;
                height: 12px;
                background: $white;
                border: 1px solid $gray;
                // background-image: inline-svg($op-icon-checkbox-inactive, $brand-color-1);
                // background-size: cover;
                // background-position: center;
                margin-right: 10px;
                margin-bottom: 2px;
                opacity: 1;
                transition: all 0.2s ease-in-out;
            }
        }
        input:checked+label {
            &::before {
                opacity: 1;
                background-color: $brand-color-1;
                // color: #1da775;
                background-image: none;
            }
        }
        &:only-child {
            label {
                //pointer-events: none;
            }
        }
        &.hideme {
            display: none;
        }
    }
}

.range-slider {
    margin: 0;
    &[data-op-orientation="horizontal"] {
        margin: 39px 0 5px 0;
    }

}
