.list-info{
	
	background: #eee;
	padding: 1rem;
	margin-bottom: 2rem;
    
    columns: 1;
    //column-fill: balance;
    break-inside: avoid;
    
    @include mq($min-width: map-get($breakpoints, m)) {
        columns: 1;
    }
    @include mq($min-width: map-get($breakpoints, xl)) {
        columns: 1;
    }
    
    .list-info-item {
        display: inline-block;
        width: 100%;
        page-break-inside: avoid;
        break-inside: avoid;
        margin-bottom: 0.5rem;
        
        .list-info-label {
            margin-right: 1rem;
            font-weight: bold;
            display: inline-block;
            width: 45%;
        }
        .list-info-value {
            display: inline-block;
        } 
    }
}