$premium-color: #A88958;

.content.premium,
.page-details.premium{

    // Anzahl Ergebniss
    .article-list-menu .article-list-count {
        color: $premium-color;
    }

    // Layout Switch
    .article-list-menu {
        .article-list-layout {
            .opc-button{
                &.is-active {
                    border-color: $premium-color!important;
                    background-image: inline-svg($views-list, $premium-color)!important;
                    &[data-op-layout="tile"]{
                        background-image: inline-svg($views-tile, $premium-color)!important;
                    }
                }
            }
        }
    }

    // Breadcrumb
    .opc-breadcrumb .breadcrumb-navigation li.is-active {
        color: $premium-color;
    }
    .opc-breadcrumb .breadcrumb-navigation li a:hover {
        color: $premium-color;
    }

    // Filter Toggle
    .opc-filter-set {
        &:not(.is-open) {
            .filter-toggle {
                background: $premium-color;
            }
        }
        &.is-open{
            .filter-toggle {
                color: $premium-color;
                svg{
                    color: $premium-color;
                }
            }
        }
    }

    
    // Filter Ergebnisse anzeigen
    .opc-filter-set .custom-mobile-filter-set .buttonwrapper>button.jump-to-list {
        background: $premium-color;
    }
    
    // Filter Highlight Color
    .opc-filter-set[data-op-layout="horizontal"] .filter-set-list .filter-list h3 ,
    .opc-filter-set[data-op-layout="horizontal"] .filter-set-list .article-list-sort h3 {
        color: $premium-color;
    }

    // Filter
    .opc-filter-set .filter-set-list .opc-catsort {
        border-top: 1px solid $premium-color;
        border-bottom: 1px solid $premium-color;
    }
    .opc-filter[data-op-style="dropdown"] .filter-head {
        border-color: $premium-color!important;
    }
    .opc-filter-set{
        &[data-op-layout="horizontal"] {
            .filter-set-list {
                .filter-set-list-item {
                    &:nth-of-type(1){
                        [data-op-style="dropdown"] {
                            .filter-head {
                                border-top-color: $premium-color!important;
                            }
                        }
                    }
                    @include mq($min-width: map-get($breakpoints, s)) {
                        &:nth-of-type(2){
                            [data-op-style="dropdown"] {
                                .filter-head {
                                    border-top-color: $premium-color!important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .opc-filter[data-op-style="dropdown"] .filter-head svg {
        color: $premium-color;
    }
    .opc-filter-set .filter-set-list .opc-catsort select {
        background-image: inline-svg($op-icon-angle-down-svg,$premium-color);
    }
    .opc-rangeslider-connect {
        background: $premium-color;
    }
    .select-list li label:hover {
        color: $premium-color;
    }
    .select-list li input:checked+label::before {
        background-color: $premium-color;
    }
    .filter-tag-list .tag {
        background: $premium-color;
    }

    // Artikelliste
    .opc-article-list{
        &[data-op-article-list-type="tile"]{
            justify-content: space-between;
            >li {
                flex-basis: 100%;
                max-width: 100%;
                .article-list-item .article-list-item-details .article-list-item-title{
                    font-family: Playfair, "Times New Roman";
                    a span.tile-view {
                        font-size: 1.6rem;
                    }
                }
                .article-list-item .article-list-item-image {
                    background-color: transparent;
                }
            }
            @include mq($min-width: map-get($breakpoints, s)) {
                >li{
                    flex-basis: calc(100% / 2);
                }
            }
            @include mq($min-width: map-get($breakpoints, l)) {
                >li{
                    margin-bottom: 7rem;
                    flex-basis: calc(100% / 2);
                    max-width: 500px;
                    .article-list-item .article-list-item-details .article-list-item-title{
                        font-family: Playfair, "Times New Roman";
                        a span.tile-view {
                            font-size: 2.4rem;
                        }
                    }
                }
            }
            .article-list-item{
                display: flex;
                flex-direction: column;
                height: 100%;
                .article-list-item-bottom {
                    align-items: flex-end;
                    margin-top: 0;
                    .stock-price-group{
                        align-items: flex-end;
                        .opc-price{
                            &.is-service{
                                flex-wrap: wrap;
                                .price-basis,
                                .price-promotion{
                                    order: -1;
                                    position: static;
                                    flex-basis: 100%;
                                }
                            }
                        }
                        .stock{
                            transform: translateY(-11px);
                        }
                    }
                }
            }
            .opc-price {
                .price-basis,
                .price-service,
                .price-promotion {
                    font-weight: normal;
                    font-size: 1.6rem;
                    @include mq($min-width: map-get($breakpoints, l)) { 
                        font-size: 2.4rem;
                    }
                }
            }
            .stock-price-group{
                .set-occ-price {
                    .opc-price { 
                        &.is-basis {
                            .price-basis {
                                color: $brand-color-2;
                            }
                        }
                        &.is-promo:not(.is-service){
                            .price-basis {
                                color: #999;
                            }
                        }
                        .price-service{
                            color: $brand-color-2!important;
                        }
                        &.is-basis.is-promo {
                            .price-basis {
                                top: -2.2rem;
                                @include mq($min-width: map-get($breakpoints, l)) {
                                    top: -2.8rem;
                                }
                            }
                        }
                    }
                }
            }
            .article-list-item{
                .article-list-item-details {
                    margin-bottom: 0.6rem;
                    @include mq($min-width: map-get($breakpoints, l)) {
                        margin-bottom: 0.6rem;
                    }
                }
                .article-list-item-bottom {
                    .stock-price-group{
                        .stock{
                            transform: translateY(0px);
                            @include mq($min-width: map-get($breakpoints, l)) {
                                transform: translateY(-4px);
                            }
                        }
                    }
                }
            }
        }
    }
    
    // Preisanzeige Artikelliste
    [data-op-article-list-type="tile"]{
    }

    // Lager
    .stock {
        .icon {
            width: 6px;
            height: 6px;
            margin-right: 5px;
            // @include mq($min-width: map-get($breakpoints, m)) {
            //     width: 12px;
            //     height: 12px;
            //     margin-right: 10px;
            // }
        }
    }

    // Mehr laden Button / Paging
    .article-list-paging .opc-segment-navigation>button.opc-button{
        background-color: $premium-color;
        font-size: 2.4rem;
        width: 100%;
        max-width: 588px;
        height: auto;
        min-height: 4.8rem;
    }
    &.image-zoom {
        .image-zoom-header { 
            .opc-button{
                    background: $premium-color!important;
                }        
            }
            .customize-tools {
            background: $premium-color!important;
                li.tns-nav-active {
                    border-color: #000 !important;
                }
        }
    }


}

// Detailpage
.page-details.premium{
    .article-head {
        flex-wrap: wrap;
        .article-image{
            flex-basis: 100%!important;
            max-width: 100%!important;
            width: 100%!important;
            .customize-tools{
                #customize-thumbnails{
                    li{
                        &.op-mediaslider-nav-active,
                        &.tns-nav-active{
                            &::after {
                                content:'';
                                height: 6px;
                                content: '';
                                width: 100%;
                                position: relative;
                                display: block;
                                background:  $premium-color !important;
                            }
                        }
                        &::after {
                            content:'';
                            height: 6px;
                            content: '';
                            width: 100%;
                            position: relative;
                            display: block;
                            background-color: rgba($premium-color,.4)!important;
                        }
                        &:hover {
                            &::after {
                                content:'';
                                height: 6px;
                                content: '';
                                width: 100%;
                                position: relative;
                                display: block;
                                background:  $premium-color !important;
                            }
                        }
                    }
                }
            }
        }
        .article-infos{
            flex-basis: 100%!important;
            max-width: 780px!important;
            width: 100%!important;
            margin: 0 auto;
            h1, h2{
                font-family: Playfair, "Times New Roman";
                font-weight: normal;
                font-size: 3.2rem;
                margin-bottom: 2rem;
            }
            
            p{
                margin-bottom: 2rem;
            }
            .opc-price .price-basis, 
            .opc-price .price-service, 
            .opc-price .price-promotion{
                font-weight: 400;
                color: $brand-color-2;
            }
            .toggle-area{
                >h4{
                    border-bottom: 1px solid $premium-color;
                    color: $premium-color;
                    &::after{
                        color: $premium-color;
                    }
                }
                &.is-open{
                    border-bottom: 1px solid $premium-color;
                    >h4{
                        border-bottom: none;
                    }
                }
            }
            .pdf-create {
                .opc-button[data-op-style="secondary-lined"]{
                    svg{
                        color: $premium-color;
                    }
                    span{
                        color: $premium-color;
                    }
                }
            }
            .btn-occasion {
                background-color: $premium-color;
            }
            .article-actions {
                background-color: $premium-color;
                @include mq($min-width: map-get($breakpoints, l)) {
                    max-width: 390px!important;
                }
                .opc-button[data-op-style="basket"]{
                    background-color: $premium-color;
                }
                .opc-basket-button {
                    &[data-op-layout="touch"]{
                        width: auto;
                        .basket-button-dim-1 {
                            .cust-qtychange-btn{
                                background-color: transparent;
                                color: $white;
                                &[data-op-qtychange="dec"]{
                                }
                                &[data-op-qtychange="inc"]{
                                    border-right: 1px solid $white;
                                }
                            }
                            .opc-form-field{
                                border: 1px solid $white;
                                border-top: none;
                                border-bottom: none;
                                .form-field-label{
                                    color: $white;
                                }
                                .form-field-element{
                                    input.basket-button-quantity{
                                        color: $white;
                                        background-color: transparent;
                                    }
                                }
                            }
                        }
                        .opc-button{
                            @include mq($min-width: map-get($breakpoints, l)) {
                                width: auto;
                            }
                            @include mq($min-width: map-get($breakpoints, xl)) {
                                width: auto;
                            }
                        }
                    }
                }
                + .toggle-area{
                    >h4{
                        border-top: 1px solid $premium-color;
                    }
                }
            }
        }
        .pdf-create {
            > a:not(.social-link) {
                background-color: $premium-color!important;
            }
            .social-link {
                color:  $premium-color!important;
            }
        }
    }
    #tabsPart1{
        .opc-article-list,
        .opc-article-list[data-op-tile-col="4"]{
            justify-content: space-between;
            >li {
                flex-basis: 100%;
                max-width: 100%;
                list-style-type: none;
                .article-list-item .article-list-item-image {
                    background-color: transparent;
                }
            }
            @include mq($min-width: map-get($breakpoints, s)) {
                >li{
                    flex-basis: calc(100% / 2);
                }
            }
            @include mq($min-width: map-get($breakpoints, l)) {
                >li{
                    margin-bottom: 7rem;
                    flex-basis: calc(100% / 2);
                    max-width: 500px;
                    .article-list-item .article-list-item-details .article-list-item-title{
                        font-family: Playfair, "Times New Roman";
                        a span.tile-view {
                            font-size: 2.4rem;
                        }
                    }
                }
            }
        }
    }

}