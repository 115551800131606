/*###############################################

@Title: Mainnav
@Description:


###############################################*/

// namespace vars

$mainnav-bg: $brand-color-2;
$mainnav-padding: 1rem;


// mainnav layout styles

.mainnav-wrapper {
	@extend %mw;
	position: relative;
	z-index: 100;
	background: $mainnav-bg;
  display: none;
  @include mq($min-width: map-get($breakpoints, m)) {
		display: block;
		padding-left: 5rem;
		padding-right: 5rem;
	}
  @include mq($min-width: map-get($breakpoints, l)) {
		display: block;
		padding-left: 4rem;
		padding-right: 4rem;
	}
	@include mq($min-width: map-get($breakpoints, xl)) {

	}
	.mainnav-inner {
		@extend %mw-inner;
		display: flex;
		// justify-content: space-between;
		align-items: center;
		height: 6.5rem;
		@include mq($min-width: map-get($breakpoints, s)) {
			height: 6.5rem;
		}
		.header-search{
			width: 100%;
			max-width: 39.2rem;
			margin-left: auto;
			.opc-search{
				form{
					.opc-search-content{
						.opc-search-query{
							border: none;
							border-bottom: 1px solid $white;
							input.search-query{
								background-color: transparent;
								color: $white;
								font-size: 2.4rem;
								font-weight: 900;
								padding-left: 0;
								&::placeholder {
									font-size: 1.6rem;
									font-weight: normal;
									color: $white;
								}
								&:focus{
									&::placeholder {
										color: transparent;
									}
								}
							}
							button.search-btn{
								color: $white;
								padding-right: 0;
								display: flex;
    						justify-content: flex-end;
							}
						}
					}
				}
			}
			> .search-recommendations-custom{
				display: none;
			}
		}
	}

	// offcanvas menu
	
	.offcanvas-menu-trigger {
		margin-right: $_header-offcanvas-menu-margin;
		border: none;
		padding: 0;
		background: none;
		opacity: 1;
		cursor: pointer;
		&:hover {
			opacity: 1;
		}
		span {
			@include burger(30px, 2px, 4px, $_header-offcanvas-menu-trigger-color);
		}
		&.is-active {
			span { 
				@include burger-to-cross;
			}
		}
		display: none;
		@include mq($min-width: map-get($breakpoints, s)) {
			display: block;
		}
		@include mq($min-width: map-get($breakpoints, l)) {
			display: none;
		}
	}
	
	.opc-navigation {
		display: none;
		// @include mq($min-width: 1025px) {
		@include mq($min-width: map-get($breakpoints, l)) {
			display: block;
		}
		&:last-child{
			margin-right: 0;
		}
		li a {
			color: #fff;
		}
	}

	&.mainnav-wrapper-white{
		background: $white;
		z-index: 101;
		.mainnav-inner {
			@extend %mw-inner;
			display: flex;
			// justify-content: space-between;
			align-items: center;
			height: 0;
			@include mq($min-width: map-get($breakpoints, l)) {
				height: 6.5rem;
			}
			.opc-navigation {
				li a {
					margin-right: 30px;
				}
			}
			.header-search{
				width: 100%;
				max-width: 39.2rem;
				margin-left: auto;
				.opc-search{
					form{
						.opc-search-content{
							.opc-search-query{
								border: none;
								border-bottom: 1px solid $brand-color-2;
								input.search-query{
									background-color: transparent;
									color: $brand-color-2;
									font-size: 2.4rem;
									font-weight: 900;
									padding-left: 0;
									&::placeholder {
										font-size: 1.6rem;
										font-weight: normal;
										color: $brand-color-2;
									}
									&:focus{
										&::placeholder {
											color: transparent;
										}
									}
								}
								button.search-btn{
									color: $brand-color-2;
									padding-right: 0;
									display: flex;
									justify-content: flex-end;
								}
							}
						}
					}
				}
				> .search-recommendations-custom{
					display: none;
				}
			}
			
			@include mq($min-width: map-get($breakpoints, m)) {
				.header-search{
					width: 100%;
					max-width: calc(100% - 60px);
					margin-left: auto;
					display: flex;
					justify-content: flex-end;
					align-items: center;
					position: absolute;
					right: 0;
					bottom: -53px;
					.opc-search{
						width: 100%;
						max-width: 39.2rem;
						form{
							.opc-search-content{
								.opc-search-query{
									border-bottom: 1px solid $white;
									input.search-query{
										background-color: transparent;
										color: $white;
										&::placeholder {
											color: $white;
										}
									}
									button.search-btn{
										color: $white;
									}
								}
							}
						}
					}
				}
			}
			@include mq($min-width: map-get($breakpoints, l)) {
				.header-search{
					position: static;
					.opc-search{
						form{
							.opc-search-content{
								.opc-search-query{
									border-bottom: 1px solid $brand-color-2;
									input.search-query{
										background-color: transparent;
										color: $brand-color-2;
										&::placeholder {
											color: $brand-color-2;
										}
									}
									button.search-btn{
										color: $brand-color-2;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@supports(position: sticky) {	
	.eshop-toolbar[data-op-sticky="true"],
	.mainnav-wrapper[data-op-sticky="true"] {
		position: sticky;
		top: 0;
		&.toolbar-active{
			top: 4.6rem;
		}
		width: 100%;
		// box-shadow: inset 0 -5px 5px rgba(0, 0, 0, 0.1);
		&.sticky-inactive{
		}
	}
}




