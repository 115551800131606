/*###############################################

@Title: Content
@Description:


###############################################*/

// namespace vars
$_header-bg: $white; // header background color
$_header-offcanvas-menu-trigger-color: #fff; // color of the hamburger menu icon
$_header-padding: 2rem; // top and bottom padding of the header
$_header-offcanvas-menu-margin: 1rem; // space between hamburger menu and brand
$_header-spacing: 4rem; // Spacing between the header sections eg. brand, search, actions


// display config
$always-show-offcanvas-menu-trigger: 'false'; // define if you want to show the hamburger menu just on mobile or always    
$show-offcanvas-menu-trigger: map-get($breakpoints, m); // set breakpoint to define when the hamburger will shown



// header layout styles
.header-wrapper {
	@extend %mw;
	padding: 0 20px;
	background: $brand-color-2;
	box-shadow: none;
	position: sticky;
	top: 0;
	z-index: 200;
	// margin-top: .6rem;
	margin-bottom: 0rem;

	@include mq($min-width: map-get($breakpoints, m)) {
		background: $_header-bg;
		margin-bottom: .6rem;
		box-shadow: none;
		position: relative;
		padding-left: 5rem;
		padding-right: 5rem;
	}
	@include mq($min-width: map-get($breakpoints, l)) {
		padding-left: 4rem;
		padding-right: 4rem;
		padding-top:1.2rem;
	}

	@media print {
		background: $_header-bg;
		margin-bottom: .6rem;
		box-shadow: none;
		position: relative;
		padding-left: 4rem;
		padding-right: 4rem;
	}

	@include mq($min-width: map-get($breakpoints, xl)) {}

	.header-inner {
		@extend %mw-inner;
		//@include clearfix();
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		justify-content: space-between;
		height: 6.5rem;

		@include mq($min-width: map-get($breakpoints, m)) {
			&::before {
				content: " ";
				flex-basis: 33%;
				flex-shrink: 1;
				flex-grow:  1;
			}
		}

		@include mq($min-width: map-get($breakpoints, s)) {
			height: 6.5rem;
		}
	}


	// offcanvas menu

	.offcanvas-menu-trigger {
		margin-right: $_header-offcanvas-menu-margin;
		border: none;
		padding: 0.5rem;
		background: none;
		opacity: 1;
		cursor: pointer;

		&:hover {
			opacity: 1;
		}

		span {
			@include burger(30px, 2px, 4px, $_header-offcanvas-menu-trigger-color);
		}

		&.is-active {
			span {
				@include burger-to-cross;
			}
		}

		@if $always-show-offcanvas-menu-trigger !='true' {
			display: none;

			@include mq($max-width: #{$show-offcanvas-menu-trigger}) {
				display: block;
			}
		}

		order: 1;

	}


	// logo 
	.brand {
		margin-right: $_header-spacing / 4;
		order: 2;
		//flex-grow: 1;
		width: 100%;
		min-width: 10.5rem;
		max-width: 10.5rem;

		a {
			display: block;
		}

		img {
			display: block;
			width: 100%;
		}

		@include mq($min-width: map-get($breakpoints, s)) {
			max-width: 24rem;
			// padding: 0 0 0.5rem;
			margin-right: $_header-spacing / 2;
		}

		@include mq($min-width: map-get($breakpoints, m)) {
			margin-right: $_header-spacing / 1.5;
			flex-grow: 0;
		}

		@include mq($min-width: map-get($breakpoints, xl)) {
			margin-right: $_header-spacing;
		}

		#brand-logo{
			display: none;
			@include mq($min-width: map-get($breakpoints, m)) {
				display: block;
			}
		}
		#brand-logo-white{
			@include mq($min-width: map-get($breakpoints, m)) {
				display: none;
			}
		}


	}


	// header search 
	.header-search {
		flex: 1;
		display: flex;
		margin-right: $_header-spacing / 2;
		width: 100%;
		min-width: 100%;
		order: 5;
		position: absolute;

		//margin-top: $_header-spacing / 4;
		&::before {
			content: '';
			display: block;
		}

		@include mq($min-width: map-get($breakpoints, m)) {
			margin-right: $_header-spacing / 2;
			margin-top: 0;
			min-width: 0;
			order: 2;
			position: relative;
		}

		@include mq($min-width: map-get($breakpoints, xl)) {
			margin-right: $_header-spacing;
		}

		input {
			height: 3rem;
			width: 100%;
		}


	}

	// header actions
	.header-actions {
		@media print {
			display: none;
		}

		display: flex;
		order: 4;
		flex-grow: 1;
		justify-content: flex-end;
    align-items: center;

		@include mq($min-width: map-get($breakpoints, m)) {
			flex-grow: 0;
		}

		>.opc-favorite-info,
		>.opc-basket-info,
		>.search-toggle,
		>.account-link {
      padding: 0 10px;
      >.opc-button{
        padding: 0;
        margin: 0;
        height: auto;
				overflow: visible;
      }
		  @include mq($min-width: map-get($breakpoints, m)) {
        padding: 0 20px;
      }
    }
    
		>.account-link {
			display: none;
      >.opc-button{
        padding: 0;
        margin: 0;
        height: auto;
        .text{
          display: none;
        }
        svg{
          color: $white;
          font-size: 2.4rem;
          margin-right: 0;
        }
      }
			@include mq($min-width: map-get($breakpoints, m)) {
				display: block;
				>.opc-button{
					svg{
						color: $brand-color-2;
					}
				}
			}
		}
    
    >.opc-favorite-info {
			display: none;
      >.opc-button {
        .favorite-text {
          display: none;
        }

        svg{
          color: $white;
          font-size: 2.4rem;
          margin-right: 0;
        }
        .favorite-count {
					right: -8px;
					top: 0;
					width: 16px;
					height: 16px;
					line-height: 16px;
					background-color: $brand-color-2;
					transform: none;
				}
      }
			@include mq($min-width: map-get($breakpoints, m)) {
				display: block;
				>.opc-button{
					svg{
						color: $brand-color-2;
					}
				}
			}
    }
    >.opc-basket-info{
      @include mq($min-width: map-get($breakpoints, m)) {
        margin-right: -20px;
      }
      >.opc-button{
        .text{
          display: none;
        }
        svg{
          color: $white;
					font-size: 2rem;
          margin-right: 0;
        }
		    @include mq($min-width: map-get($breakpoints, m)) {
					svg{
						font-size: 2.4rem;
            color: $brand-color-2;
          }
        }
        .basket-info-count{
					right: -8px;
					top: 0;
					width: 16px;
					height: 16px;
					line-height: 16px;
					background-color: $white;
					transform: none;
					color: $brand-color-2;
					@include mq($min-width: map-get($breakpoints, m)) {
						background-color: $brand-color-2;
						color: $white;
					}
        }
      }
    }

		>.account-link {
			.account-link-login-indicator {
				// display: none;
				width: 1.2rem;
				height: 1.2rem;
				background: $success-color;
				border-radius: 50%;
				position: absolute;
				bottom: 0;
				right: 0;
			}
		}

		>.opc-favorite-info {

		}

		.opc-basket-info {

		}

		.search-toggle {
			cursor: pointer;
			padding-left: 0;
			padding-right: 0;
			background: transparent;

      >svg {
        font-size: 2rem;
      }
			&.mobile {
				color: $white;
			}

			@include mq($min-width: map-get($breakpoints, m)) {
				>svg {
					font-size: 2.4rem;
				}
				&.search-toggle {
					display: none;
				}
			}

		}

	}

	.brand,
	.header-actions,
	.offcanvas-menu-trigger{
		flex-basis: 33%;
		flex-shrink: 1;
		flex-grow:  1;
	}
}

.account-link{
	position: relative;
	>a{
		font-weight: 300;
	}
	.opc-navigation{
		margin-bottom: 1rem;
		a{
			font-weight: 300;
			line-height: 1.2;
			font-size: 1.6rem;
		}
	}
	.account-link-user-menu{
		opacity: 0;
		visibility: hidden;
		position: absolute;
		z-index: -100;
		top: 100%;
		transform: translateX(50%);
		min-width: 32rem;
		max-width: 52rem;
		min-height: 20rem;
		background: $brand-color-2;
		color: $white;
		// border-radius: .5rem;
		box-shadow: 0 0 10px rgba(#000, .3);
		transition: all .3s ease-in;
		padding: 3rem;
		width: auto;
		right: 100%;
		@include mq($min-width: map-get($breakpoints, m)) {
			width: 52rem;
			right: 50%;
			transform: translateX(35%);
		}
		@include mq($min-width: map-get($breakpoints, xl)) {
			transform: translateX(50%);
		}
		a{
			color: $white;
		}
		.opc-navigation{
			margin-bottom: 1rem;
			a{
				font-weight: 300;
				line-height: 1.2;
				font-size: 1.6rem;
			}
		}
		h3{
			a{
				display: inline;
				font-size: 2rem;
				white-space: nowrap;
			}
			margin-bottom: 2rem;
			margin-left: -1rem;
		}
		svg{
			color: $white;
			font-size: 4rem;
		}
		.logout-btn{
			padding-left: .5rem;
			@include mq($min-width: map-get($breakpoints, m)) {
				float: right;
				padding: 0;
			}
			a{
				padding-right: 4rem;
			}
			svg{
				margin-left: -4rem;
				z-index: 1;
			}
			&:hover{
				a{
					color: $brand-color-2;
					background-color: $white;
				}
				svg{
					color: $brand-color-2;
				}
			}

		}
		.navigation-level-1{
			display: flex;
			flex-wrap: wrap;
			>li{
				flex-basis: 100%;
				>a{
					font-size: 2.4rem;
					color: $white;
					&::before{
						content: "";
						display: none;
					}
					&:hover{
						color: $brand-color-2;
						background-color: $white;
					}
				}
				.navigation-level-2{
					padding-left: .5rem;
					>li{
						padding: 0.4rem 0;
						>a{
							font-weight: 300;
							padding-left: 0;
							padding: 0 .6rem;
							&:hover{
								color: $brand-color-2;
								background-color: $white;
							}
						}
					}
				}
			}
			@include mq($min-width: map-get($breakpoints, m)) {
				>li{
					flex-basis: calc(50% - 1.5rem);
				}
			}
		}
	}
	&:hover,
	&.is-open{
		.account-link-user-menu{
			opacity: 1;
			visibility: visible;
			z-index: 100001;
		}
	}
}

@supports(position: sticky) {

}

.usp-bar {
	background:$brand-color-2;
	display: flex;
	justify-content: center;
	> span {
		color: $white;
		font-size: 16px;
		padding: 1rem;
		> a {
			color: $white;
			text-decoration: underline;
		}
	}
	.desktop {
		display: none;
	}
	.mobile {
		display: inline;
	}
	@include mq($min-width: map-get($breakpoints, m)) {
		.desktop {
			display: inline;
		}
		.mobile {
			display: none;
		}
	}
}

