.content-box-wrapper {
    display: flex;
    margin-left: -$_opc-grid-gutter-width;
    margin-right: -$_opc-grid-gutter-width;
    align-items: flex-start;
    flex-wrap: wrap;
    
    .content-box {
        margin: 0 $_opc-grid-gutter-width;
        
        width: 50%;
        
        
        @include mq($max-width: map-get($breakpoints, 'medium')) {
            width: 100%;
            margin-bottom: 1rem;
        }        
    }
}

.content-box {
    border: 1px solid #ccc;
    margin-bottom: 1rem;
    
    &.fixed-width {
        max-width: 60rem;
        margin: 0 auto;
    }
    
    .content-box-head {
        padding: 2rem;
        position: relative;
        // title
        h2 {
            margin: 0;
            padding: 0;
            span {
                font-size: 3rem;
                margin-right: 2rem;
            }
        }
        // toggler
        .content-box-toggler {
            position: absolute;
            right: 2rem;
            top: 50%;
            transform: translateY(-50%);
            //width: 2rem;
            //height: 2rem;
            text-align: center;
            font-size: 2rem;
            line-height: 2rem;
            
        }
    }
    &.is-open {
        .content-box-head {
            .content-box-toggler {
                transform: translateY(-50%) rotate(-180deg);
            }
        }
    }
    .content-box-body {
        padding: 0 2rem 2rem;
        p {
            padding: 0;
            margin: 0;
            margin-bottom: 2rem;
        }
        .content-box-slide-content {
            display: none;
			//padding-bottom: 2rem;
        }
        .content-box-body-actions {
            text-align: right;
            .stepInfo {
                font-size: 1.2rem;
                padding: 0.5rem 0;
                color: #fa7979;
            }
        }
    }
}