/*###############################################

@Title: Footer
@Description:


###############################################*/

// namespace vars
$_footer-bg: 					$brand-color-2;	// background of footer
$_footer-inner-bottom-bg:		$brand-color-2;	// background of bottom part of the footer
$_footer-padding:				2.5rem;	// padding of the footer
$_footer-inner-bottom-padding: 	6rem;	// padding of the bottom part of the footer

$_footer-color:					$white;
$_footer-color-hover:			$brand-color-2;


// footer layout styles

footer {
	@media print{    
        display:none;
    }
}

.footer-wrapper {
	@extend %mw;
	// margin-top: auto;
	margin-top: 0.8rem;
	background: $_footer-bg;
	padding-top: $_footer-padding;
	overflow: hidden;
	
	@include mq($min-width: map-get($breakpoints, m)) {
		padding-left: 4rem;;
    padding-right: 4rem;;
  }
	.footer-inner {
		@extend %mw-inner;
		display: flex; 
		flex-wrap: wrap;
		padding-bottom: 40px;
		@include mq($min-width: map-get($breakpoints, l)) {
		}
		.opc-row {
			width: calc(100% + (#{$_opc-grid-gutter-width} * 2));
			&::after{
				display: none
			}
		}
	}

	.footer-inner-payment {
		@extend %mw-inner;
		display: flex; 
		flex-wrap: wrap;
		padding-top: $_footer-padding / 2;
		padding-bottom: $_footer-padding / 2;
		border-top: 1px solid rgba(#fff, 0.2);
		justify-content: center;
		width: 100%;

		@include mq($min-width: map-get($breakpoints, m)) {
			justify-content: flex-start;
		}

		img {
			margin-right: 1rem;
		}
	}

	.footer-col-categories {
		display: none;
		

		@include mq($min-width: map-get($breakpoints, m)) {
			display: block;
		}
	}
	
	.footer-inner-bottom {
		@extend %mw-inner;
		background: $_footer-inner-bottom-bg;
		position: relative;
		border-top: 1px solid rgba(#fff, 0.2);
		width: 100%;
		
		padding-top: $_footer-inner-bottom-padding / 2;
		padding-bottom: $_footer-inner-bottom-padding;
 


		@include mq($min-width: map-get($breakpoints, m)) {
			
		}
				
		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			width: 2000px;
			top: 0;
			bottom: 0;
			background: $_footer-inner-bottom-bg;
		}
		
		&::before {
			right: 100%;
		}
		&::after {
			left: 100%;
		}
	}
	
	
	.footer-col {
		width: span(12);
		max-width: span(12);
		padding-left: 3rem;
		margin-bottom: 30px;
		@include mq($min-width: map-get($breakpoints, m)) {
			padding-right: $_opc-grid-gutter-width;
			padding-left: $_opc-grid-gutter-width;
		}
		@include mq($min-width: map-get($breakpoints, l)) {
			margin-bottom: 0;
		}
		padding-bottom: $_opc-grid-gutter-width * 2;
		// text-align: center;
		color: $_footer-color;

		&.footer-col-services {
			//text-align: left;
			font-size: 1.6rem;
		}
		// &.footer-col-contact {
		// 	.navigation-level-1 {
		// 		> li {
		// 			> a {
		// 				display: none;
		// 			}
		// 		}
		// 	}
		// }
		&.footer-col-standorte {
			.navigation-level-2{
				display: flex;
				flex-wrap: wrap;
				>li{
					flex-basis: 35%;
				}
			}
		}
		&.footer-col-categories {
			font-size: 1.5rem;
		}
		&.footer-col-werkstatt {
			 ul {
				> li {
					>	a {
						font-size: 1.6rem;
						margin: 0 0 1rem;
						padding: 0;
						color: #fff;
						font-weight: 600;
					}
					&:hover {
						a {
							background-color: transparent !important;
							color: #fff !important;
						}
					}
					> ul {
						margin-left: -.5rem;
						li {
							a {
								padding:0.5rem;
								font-weight: normal;
								margin:0 !important;
							}
							&:hover {
								a {
									color:$brand-color-2 !important;
									background-color: #fff !important;
								}
							}
						}
					}
				}
			}
		}
				
		h2 {
			font-size: 1.6rem;
			margin: 0 0 1rem;
			padding: 0;
			color: $white;
			font-weight: 600;
		}
		
		@include mq($min-width: map-get($breakpoints, s)) {
			width: span(6);
			max-width: span(6);
			text-align: left;
		}
		@include mq($min-width: map-get($breakpoints, m)) {
			width: span(6);
			max-width: span(6);
			text-align: left;
		}
		@include mq($min-width: map-get($breakpoints, l)) {
			padding-bottom: 0;
			width: calc(100% / 4);
			max-width: calc(100% / 4);
		}
		// @include mq($min-width: map-get($breakpoints, xl)) {
		// 	padding-bottom: 0;
		// 	width: calc(100% / 6);
		// 	max-width: calc(100% / 6);
		// }

		.opc-static-content {
			@extend .text;
			table {
				tbody {
					tr {
						border:none;
						td {
							padding: 0;
						}
					}
				}
			}
			a {
				color:#fff;
				&:hover {
					color: darken(#fff, 20%);
				}
			}
		}
		
		p {
			color: $_footer-color;
		}
        label {
            color: $_footer-color;
		}
		
	}

	// newsletter in footer
	.opc-newsletter {
		max-width: 40rem;
		margin: 0 auto;
		label {
			text-align: left;
		}
		.opc-button {
			width: 100%;
			@include mq($min-width: map-get($breakpoints, m)) {
				width: auto;
			}
		}
	}

	
	// nav im footer
	.opc-navigation {

		&[data-op-navtype="simple"] {
			a {
				color: $_footer-color;
				padding: .3rem;
				white-space: pre-wrap !important;
				@include mq($min-width: map-get($breakpoints, xl)) {
					white-space:nowrap !important;
				}
				// &::before {
				// 	margin-right: 0.5rem;
				// 	content: '';
				// 	width: 1em;
				// 	height: 1em;
				// 	display: inline-block;
				// 	background-image: inline-svg($op-icon-angle-right-svg, '#fff');
				// 	background-position: center;
				// 	background-size: 100%;
				// 	background-repeat: no-repeat;
				// 	opacity: 0.6;
	
				// }
				
				&:hover {
					&::before {
						opacity: 1;
					}
				   
				}
			}
			// nav level 1
			.navigation-level-1 {
				>li {
					>a {
						font-size: 1.6rem;
						margin: 0 0 1rem;
						padding: 0;
						color: #fff;
						font-weight: 600;
					}
					&:hover {
						// style a tag of hovered element
						>a {}
						// show nav level 2 on hover if available
						>.navigation-level-2 {}
					}
				}
			}
			// nav level 2
			.navigation-level-2 {
				>li {
					&:hover{
						background-color: transparent;
					}
					>a {
						// white-space: nowrap;
					}
					&:hover {
						>a {
							color: $_footer-color-hover;
							background-color: $white;
						}
					}
				}
			}
		}
	}
	.footer-bottom-nav {
		float: left;
		width: 100%;
		text-align: center;
		margin-bottom: 2rem;

		@include mq($min-width: map-get($breakpoints, m)) {
			float: left;
			width: auto;
			text-align: left;
			margin-bottom: 0;
		}
		a {
			color: $_footer-color;
			margin-right: 1rem;
			&:hover {
				color: $_footer-color-hover;
			}
		}
	}
	.footer-copy {
		width: 100%;
		text-align: center;
		color: $_footer-color;
		@include mq($min-width: map-get($breakpoints, m)) {
			float: right;
			width: auto;
			text-align: right;
		}
	}

	&.bottom-bar{
		padding-top: 0;
		background-color: $_footer-inner-bottom-bg;
		margin-top: auto;
		.footer-inner{
			padding: 0;
			.opc-row{
				@include mq($min-width: map-get($breakpoints, m)) {
					min-height: 11.3rem;
					padding: 40px 0 35px;
					align-items: flex-start;
				}
				align-items: center;
			}
		}
		hr{
			margin: 0;
			height: 1px;
			border: none;
			background-color: $white;
			width: calc(100% - 46px);
			@include mq($min-width: map-get($breakpoints, m)) {
				width: 100%;
			}
			margin: 0 auto;
		}
		h2{
			color: $white;
		}
		.bottom-bar-col{
			flex-basis: 100%;
			padding:.5rem;
			padding-left: 3rem;
			padding-right: 3rem;
			margin-bottom: 30px;
			@include mq($min-width: map-get($breakpoints, m)) {
				margin-bottom: 0;
				flex-basis: calc(100% / 4);
				padding-left: 0;
				padding:.5rem;
			}
			a{
				display: inline-block;
				color: $white;
				font-weight: 600;
				font-size: 1.6rem;
				// padding: 1rem 0 1.5rem 3rem;
			}
			.opc-navigation{
				.navigation-level-1{
					>li{
						>a{

						}
						.navigation-level-2{
							>li{
								a{
									font-weight: 400;
								}
							}
						}
					}
				}
			}
			.opc-language-selection {
				display: flex;
				align-items: center;
				font-size: 1.6rem;
				flex-wrap: wrap;
				&.is-open{
					background-color: transparent;
					ul{
						background-color: transparent;
						li{
							background-color: transparent;
							a{
								background-color: transparent;
							}
						}
					}
				}
				.language-selection-label {
					padding: 0 .5rem .75rem 0;
					color: $white;
					display: flex;
					flex-basis: 100%;
					font-weight: 600;
					.current-selection {
						display: none;
					}
				}

				ul {
					position: static;
					display: flex;
					background-color: transparent;
					color: $white;
					margin-left: -4px;
					li {
						border: none !important;
						display: flex;
						a {
							padding: 4px;
							color: $white;
							font-weight: 400;
							&:hover{
								color: $gray;
								background-color: $white;
							}
						}
						&::before{
							content: "|";
							display: block;
							padding: 5px;
						}
						&:first-child{
							&::before{
								display: none;
							}
						}
						&.is-active {
							a {
								opacity: 1;
								color: $white;
								font-weight: 600;
								&:hover{
									color: $gray;
									background-color: $white;
								}
							}
						}
					}

				}
			}
			&.social{
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				// margin: 1rem 0 2rem 3rem;
				margin: 40px 0 40px;
				@include mq($min-width: map-get($breakpoints, m)) {
					margin: 0;
				}
				h2{
					font-size: 1.6rem;
					margin: 0 0 1rem;
					padding: 0;
					color: $white;
					font-weight: 600;
					flex-basis: 100%;
				}
				a{
					width: 3.6rem;
					height: 3.6rem;
					margin-right: 4rem;
					padding: 0;
					svg{
						width: 100%;
						height: 100%;
					}
				}
			}
			&.webzert {
	
					@include mq($min-width: map-get($breakpoints, m)) {
						max-width:10%;
					}
			}
		}
	}
	&.info-row {
		.footer-inner {
			.opc-row {
				align-items: normal;
				@include mq($min-width: map-get($breakpoints, xl)) {
					padding:0;
					padding-top:1rem;
				}
				.bottom-bar-col{ 
					@include mq($min-width: map-get($breakpoints, m)) {
						flex-basis: calc(100% / 3);
					}
					@include mq($min-width: map-get($breakpoints, l)) {
						flex-basis: calc(100% / 3);
					}
					@include mq($min-width: map-get($breakpoints, xl)) {
						flex-basis: calc(100% /6);
					}
					
					span {
						&:first-child {
							display: block;
							color: $gray;
							font-weight: 600;
							font-size: 1.6rem;
							margin-bottom:1rem;
						}
						a {
							font-weight: normal;
						}
					}
					&:nth-child(4) {
						flex-basis: calc(100% / 2);
						flex-grow: 1;
						@include mq($min-width: map-get($breakpoints, s)) {
							flex-basis: calc(100% / 3);
						}
						@include mq($min-width: map-get($breakpoints, xl)) {
							flex-basis: calc(90% /2);
						}
					}
				}
			}
		}
	}
	&.footer-copy{
		background-color: $white;
		margin-top: auto;
		.footer-inner{
			.opc-row{
				justify-content: center;
				a{
					margin-top:2.3rem;
					opacity: .2;
					transition: opacity .3s ease-in;
					img{
						max-width: 18rem;
					}
					&:hover{
						opacity: .5;
					}
					&.no-transition {
						opacity: 1 !important;
					}
				}
				.opc-col {
					padding-left: 3rem;
    				padding-right: 3rem;
					&:last-child {
						display: flex;
						justify-content: center;
						align-items: flex-end;
						@include mq($min-width: map-get($breakpoints, m)) {
							display: flex;
							justify-content: flex-end;
						}
					}
				}
			}
		}
		.payment {
			h5 {
				margin-top: 2.3rem;
			}
			ul {
				display: flex;
				padding-left: 0;
				list-style: none;
				margin-bottom: 4.8rem;
				flex-wrap: wrap;
				> li {
					height: 5.5rem;
					margin-right: 2.5rem;
					padding: 1rem 0;
					svg {
						width: 100%;
						height: 100%;
					}
					&:nth-child(1) {
						max-width: 5rem;
					}
					&:nth-child(2) {
						max-width: 8rem;
					}
					&:nth-child(3) {
						max-width: 7rem;
					}
					&:nth-child(4) {
						max-width: 8rem;
					}
					&:nth-child(5) {
						max-width: 8rem;
						margin-right: 4rem;
					}
					&:nth-child(6) {
						max-width: 8rem;
						margin-right: 4rem;
					}
					&:nth-child(7) {
						max-width: 8rem;
						margin-right: 4rem;
					}
				}
			}
		}
	}
}
#Swiss_online_garantie{
	max-width: 12rem;
	margin: 0 1rem 0rem -1.5rem;
}

.footer-to-top {
	cursor:pointer;
	padding: 10px 20px;
	background-color: $brand-color-2;
	color: #fff;
	border: 0;
	position: fixed;
	right: 20px;
	bottom: 70px;
	text-transform: uppercase;
	font-size: 12px;
	outline: none;
	opacity: 0;
	visibility: hidden;
	transition: opacity .2s ease-in-out;
	z-index: 101;
	display: flex;
	align-items: center;
	&.show {
		opacity: 1;
		visibility: visible;
	}
	> span {
		display: none;
		@include mq($min-width: map-get($breakpoints, m)) { 
			display: block;
			bottom: 20px;
		}
	}
}