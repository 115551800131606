.article-list-menu {
    flex-wrap: wrap;
    .article-list-count {
        margin-bottom: 1rem;
        //order: 1;
        width: 100%;
        @include mq($min-width: map-get($breakpoints, s)) {
            width: auto;
        }
    } 

    .article-list-sort {
        margin-bottom: 1rem;
        //order: 3;

        select{
            background-color: $white;
            border: 1px solid #ccc;
            &:hover,
            &:focus{
                background-color: $white;
                border: 1px solid #ccc;
            }
        }

        @include mq($min-width: map-get($breakpoints, s)) {
             
        }
 
        @include mq($min-width: map-get($breakpoints, m)) {
            //order: 2;
        }
        label {
            display: none;
            color: $brand-color-1;
            margin-right: 1rem;
            @include mq($min-width: map-get($breakpoints, m)) {
                display: inline-block;
            }
        }
        select { 
            @extend .opc-select;

            &::-ms-expand {
                display: none;
            }  
  
        }
    }
    .article-list-layout {
        //order: 2;
        margin-bottom: 1rem;
        @include mq($min-width: map-get($breakpoints, m)) {
           //order: 3;
        }  
    }    
}  