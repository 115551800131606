﻿$noUi-css-prefix: opc-rangeslider;

[class^="#{$noUi-css-prefix}"] {
    --was-black-rgb: var(--tk-black-rgb, 0, 0, 0);
    --was-grey93: var(--tk-grey93, #eeeeee);
    --was-bor-transp: var(--bor-transp, 1px solid var(--clr-transparent, transparent));
    --was-clr-primary: var(--clr-primary, #fc8200);
    --was-rad-002: var(--rad-002, 4px);
    --was-bor85: var(--tk-bor85, 1px solid var(--tk-grey85, #d3d5da));
    --was-clr-white: var(--clr-white, #ffffff);
    --was-grey73: var(--tk-grey73, #bbbbbb);
    --was-grey87: var(--tk-grey87, #dddddd);
    --was-grey80: var(--tk-grey80, #cccccc);
    --was-typ-size10: var(--tk-typ-size10, 1rem);
    --was-grey67: var(--tk-grey67, #aaaaaa);
    --was-typ-bold: var(--tk-typ-bold, 600);
}

.#{$noUi-css-prefix}-target,
.#{$noUi-css-prefix}-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(var(--was-black-rgb), 0);
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.#{$noUi-css-prefix}-target {
    position: relative;
}

.#{$noUi-css-prefix}-base,
.#{$noUi-css-prefix}-connects {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}

/* Wrapper for all connect elements.
*/
.#{$noUi-css-prefix}-connects {
    overflow: hidden;
    z-index: 0;
}

.#{$noUi-css-prefix}-connect,
.#{$noUi-css-prefix}-origin {
    will-change: transform;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    -webkit-transform-style: preserve-3d;
    transform-origin: 0 0;
    transform-style: flat;
}

/* Offset direction
*/
.#{$noUi-css-prefix}-txt-dir-rtl.#{$noUi-css-prefix}-horizontal .#{$noUi-css-prefix}-origin {
    left: 0;
    right: auto;
}

/* Give origins 0 height/width so they don't interfere with clicking the
* connect elements.
*/
.#{$noUi-css-prefix}-vertical .#{$noUi-css-prefix}-origin {
    top: -100%;
    width: 0;
}

.#{$noUi-css-prefix}-horizontal .#{$noUi-css-prefix}-origin {
    height: 0;
}

.#{$noUi-css-prefix}-handle {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
}

.#{$noUi-css-prefix}-touch-area {
    height: 100%;
    width: 100%;
}

.#{$noUi-css-prefix}-state-tap .#{$noUi-css-prefix}-connect,
.#{$noUi-css-prefix}-state-tap .#{$noUi-css-prefix}-origin {
    -webkit-transition: transform 0.3s;
    transition: transform 0.3s;
}

.#{$noUi-css-prefix}-state-drag * {
    cursor: inherit !important;
}

/* Slider size and handle placement;
*/
.#{$noUi-css-prefix}-horizontal {
    height: 10px;
}

.#{$noUi-css-prefix}-horizontal .#{$noUi-css-prefix}-handle {
    width: 20px;
    height: 20px;
    right: -10px;
    top: -6px;
}

.#{$noUi-css-prefix}-vertical {
    width: 18px;
}

.#{$noUi-css-prefix}-vertical .#{$noUi-css-prefix}-handle {
    width: 20px;
    height: 20px;
    right: -6px;
    bottom: -10px;
}

.#{$noUi-css-prefix}-txt-dir-rtl.#{$noUi-css-prefix}-horizontal .#{$noUi-css-prefix}-handle {
    left: -10px;
    right: auto;
}

/* Styling;
* Giving the connect element a border radius causes issues with using transform: scale
*/
.#{$noUi-css-prefix}-target {
    background: var(--was-grey93);
    border-radius: 50px;
    border: var(--was-bor-transp);
    box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
}

.#{$noUi-css-prefix}-connects {
    border-radius: 3px;
}

.#{$noUi-css-prefix}-connect {
    background: var(--was-clr-primary);
    border-radius: var(--was-rad-002);
    -webkit-transition: background 450ms;
    transition: background 450ms;
}

/* Handles and cursors;
*/
.#{$noUi-css-prefix}-draggable {
    cursor: ew-resize;
}

.#{$noUi-css-prefix}-vertical .#{$noUi-css-prefix}-draggable {
    cursor: ns-resize;
}

.#{$noUi-css-prefix}-handle {
    border: var(--was-tk-bor85);
    border-radius: 3px;
    background: var(--was-clr-white);
    cursor: default;
    box-shadow: inset 0 0 1px var(--was-clr-white), inset 0 1px 7px #ebebeb, 0 3px 6px -3px var(--was-grey73);
}

.#{$noUi-css-prefix}-active {
    box-shadow: inset 0 0 1px var(--was-clr-white), inset 0 1px 7px var(--was-grey87), 0 3px 6px -3px var(--was-grey73);
}

/* Handle stripes;
*/
.#{$noUi-css-prefix}-handle:before,
.#{$noUi-css-prefix}-handle:after {
    content: "";
    display: block;
    position: absolute;
    height: 14px;
    width: 1px;
    background: var(--was-grey93);
    left: 14px;
    top: 6px;
}

.#{$noUi-css-prefix}-handle:after {
    left: 17px;
}

.#{$noUi-css-prefix}-vertical .#{$noUi-css-prefix}-handle:before,
.#{$noUi-css-prefix}-vertical .#{$noUi-css-prefix}-handle:after {
    width: 14px;
    height: 1px;
    left: 6px;
    top: 14px;
}

.#{$noUi-css-prefix}-vertical .#{$noUi-css-prefix}-handle:after {
    top: 17px;
}

/* Disabled state;
*/
[disabled] .#{$noUi-css-prefix}-connect {
    background: var(--was-grey73);
}

[disabled].#{$noUi-css-prefix}-target,
[disabled].#{$noUi-css-prefix}-handle,
[disabled] .#{$noUi-css-prefix}-handle {
    cursor: not-allowed;
}

/* Base;
*
*/
.#{$noUi-css-prefix}-pips,
.#{$noUi-css-prefix}-pips * {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.#{$noUi-css-prefix}-pips {
    position: absolute;
    color: var(--was-grey80);
}

/* Values;
*
*/
.#{$noUi-css-prefix}-value {
    position: absolute;
    white-space: nowrap;
    text-align: center;
}

.#{$noUi-css-prefix}-value-sub {
    color: var(--was-grey80);
    font-size: var(--was-typ-size10);
}

/* Markings;
*
*/
.#{$noUi-css-prefix}-marker {
    position: absolute;
    background: var(--was-grey80);
}

.#{$noUi-css-prefix}-marker-sub {
    background: var(--was-grey67);
}

.#{$noUi-css-prefix}-marker-large {
    background: var(--was-grey67);
}

/* Horizontal layout;
*
*/
.#{$noUi-css-prefix}-pips-horizontal {
    padding: 10px 0;
    height: 80px;
    top: 100%;
    left: 0;
    width: 100%;
}

.#{$noUi-css-prefix}-value-horizontal {
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
}

.#{$noUi-css-prefix}-rtl .#{$noUi-css-prefix}-value-horizontal {
    -webkit-transform: translate(50%, 50%);
    transform: translate(50%, 50%);
}

.#{$noUi-css-prefix}-marker-horizontal.#{$noUi-css-prefix}-marker {
    margin-left: -1px;
    width: 2px;
    height: 5px;
}

.#{$noUi-css-prefix}-marker-horizontal.#{$noUi-css-prefix}-marker-sub {
    height: 10px;
}

.#{$noUi-css-prefix}-marker-horizontal.#{$noUi-css-prefix}-marker-large {
    height: 15px;
}

/* Vertical layout;
*
*/
.#{$noUi-css-prefix}-pips-vertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%;
}

.#{$noUi-css-prefix}-value-vertical {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding-left: 25px;
}

.#{$noUi-css-prefix}-rtl .#{$noUi-css-prefix}-value-vertical {
    -webkit-transform: translate(0, 50%);
    transform: translate(0, 50%);
}

.#{$noUi-css-prefix}-marker-vertical.#{$noUi-css-prefix}-marker {
    width: 5px;
    height: 2px;
    margin-top: -1px;
}

.#{$noUi-css-prefix}-marker-vertical.#{$noUi-css-prefix}-marker-sub {
    width: 10px;
}

.#{$noUi-css-prefix}-marker-vertical.#{$noUi-css-prefix}-marker-large {
    width: 15px;
}

.#{$noUi-css-prefix}-tooltip {
    display: block;
    position: absolute;
    border: 1px solid #D9D9D9;
    border-radius: 3px;
    background: var(--was-clr-white);
    color: var(--was-clr-black);
    padding: 5px;
    text-align: center;
    white-space: nowrap;
    border: none;
    font-weight: var(--was-typ-bold);
}

.#{$noUi-css-prefix}-horizontal .#{$noUi-css-prefix}-tooltip {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 120%;
}

.#{$noUi-css-prefix}-horizontal .#{$noUi-css-prefix}-handle-upper .#{$noUi-css-prefix}-tooltip {
    left: auto;
    right: 0;
    transform: none;
}

.#{$noUi-css-prefix}-horizontal .#{$noUi-css-prefix}-handle-lower .#{$noUi-css-prefix}-tooltip {
    left: 0;
    right: auto;
    transform: none;
}

.#{$noUi-css-prefix}-vertical .#{$noUi-css-prefix}-tooltip {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    right: 120%;
}

.#{$noUi-css-prefix}-horizontal .#{$noUi-css-prefix}-origin>.#{$noUi-css-prefix}-tooltip {
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0);
    left: auto;
    bottom: 10px;
}

.#{$noUi-css-prefix}-vertical .#{$noUi-css-prefix}-origin>.#{$noUi-css-prefix}-tooltip {
    -webkit-transform: translate(0, -18px);
    transform: translate(0, -18px);
    top: auto;
    right: 28px;
}
